var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderWarning" }, [
    _c(
      "div",
      {
        staticClass:
          "orderWarning__left scrollbar bcWhite box-shadow padding10",
      },
      _vm._l(_vm.cardData, function (card, innerIndex) {
        return _c(
          "div",
          {
            key: innerIndex,
            on: {
              click: function ($event) {
                return _vm.selectCard(card, innerIndex)
              },
            },
          },
          [
            _c(
              "el-card",
              {
                staticClass: "box-card marginBottom10",
                class: { "is-selected": _vm.selectIndex === innerIndex },
                attrs: { shadow: "never" },
              },
              [
                _c("div", { staticClass: "fs12 marginBottom5" }, [
                  _vm._v("美团闪购"),
                ]),
                _c("div", [_vm._v(_vm._s(card.shopName))]),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "orderWarning__right bcWhite box-shadow padding10" },
      [
        _vm._m(0),
        _c(
          "el-radio-group",
          {
            model: {
              value: _vm.radio,
              callback: function ($$v) {
                _vm.radio = $$v
              },
              expression: "radio",
            },
          },
          [
            _c("el-radio-button", { attrs: { label: "未上传" } }),
            _c("el-radio-button", { attrs: { label: "上传中" } }),
            _c("el-radio-button", { attrs: { label: "上传失败" } }),
            _c("el-radio-button", { attrs: { label: "上传成功" } }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "box-shadow marginTop10 scrollbar",
            staticStyle: { flex: "1" },
          },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData, height: "100%" },
              },
              [
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      resizable: false,
                      align: "center",
                      prop: "type",
                      label: "订单来源",
                      width: "100",
                    },
                  },
                  [_vm._v("美团闪购")]
                ),
                _c("el-table-column", {
                  attrs: {
                    resizable: false,
                    align: "center",
                    prop: "orderCode",
                    label: "流水号",
                    width: "100",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    resizable: false,
                    align: "center",
                    prop: "address",
                    label: "顾客信息",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row.customerName +
                                  " " +
                                  scope.row.customerPhonePrivate
                              )
                            ),
                          ]),
                          _c("div", [_vm._v(_vm._s(scope.row.shopName))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    resizable: false,
                    align: "center",
                    prop: "address",
                    label: "指标说明",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    resizable: false,
                    align: "center",
                    label: "操作",
                    width: "150",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.infoPage(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "box-shadow marginBottom10 padding10",
        staticStyle: { width: "35%" },
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
              width: "100%",
              "align-items": "center",
            },
          },
          [
            _c("span", { staticClass: "fs16 fWB" }, [
              _vm._v("自配送状态回传率"),
            ]),
            _c("span", { staticClass: "fs12" }, [_vm._v("2小时前 更新")]),
          ]
        ),
        _c("div", { staticClass: "marginTop10 marginBottom10 flex" }, [
          _c(
            "div",
            {
              staticClass: "wFP marginRight10 padding10",
              staticStyle: { "background-color": "rgb(255, 248, 244)" },
            },
            [
              _c("div", { staticClass: "fs16 fWB" }, [_vm._v("今日")]),
              _c("div", { staticClass: "selfConfiguredReturnRate" }, [
                _vm._v("0.0%"),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "wFP marginLeft10 padding10",
              staticStyle: { "background-color": "rgb(255, 248, 244)" },
            },
            [
              _c("div", { staticClass: "fs16 fWB" }, [_vm._v("昨日")]),
              _c("div", { staticClass: "selfConfiguredReturnRate" }, [
                _vm._v("0.0%"),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "fs12" }, [
          _vm._v(
            '美团外卖对"配送信息上传"行为进行规范,若自然周有效配送信息上传率 < 90%的商家将对门店"调整配送范围"'
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }