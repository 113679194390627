var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box-shadow orderList" },
    [
      _vm._l(_vm.orderData, function (order, index) {
        return _c(
          "div",
          {
            key: index,
            on: {
              click: function ($event) {
                return _vm.selectCard(order, index)
              },
            },
          },
          [
            _c(
              "el-card",
              {
                class: {
                  "is-selected": _vm.selectIndex === index,
                  " box-shadow": _vm.selectIndex === index,
                  marginBottom10: 1,
                },
                attrs: {
                  shadow: "hover",
                  "body-style": "background-color: white;",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "clearfix flex marginBottom10",
                    staticStyle: { "align-items": "flex-end" },
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    order.zflow === 5 && order.zrefund !== 0
                      ? _c(
                          "span",
                          {
                            staticClass: "iconfont grey",
                            class: { blue: _vm.selectIndex === index },
                            staticStyle: { "font-size": "35px" },
                          },
                          [_vm._v(_vm._s(""))]
                        )
                      : _c("span", {
                          staticStyle: { height: "35px", width: "10px" },
                        }),
                    _c("span", { style: _vm.setColor(order.zplatOrder) }, [
                      _vm._v(_vm._s(_vm._f("zPlatOrder")(order.zplatOrder))),
                    ]),
                    _c("span", { staticClass: "marginLeft5 blue fs19" }, [
                      _vm._v(" " + _vm._s(order.corderCode)),
                    ]),
                    order.zflow !== 3 &&
                    order.zflow !== 5 &&
                    order.zflow !== 8 &&
                    order.zflow !== 9 &&
                    order.zflow !== 0
                      ? _c("span", { staticClass: "marginLeft5 grey" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                order.bdeliveryDelay === 0
                                  ? "立即送达,"
                                  : "延迟配送,"
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "marginLeft5 red" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.calculateOrderPlacementTime(order.tcreate)
                                )
                            ),
                          ]),
                          _c("span", { staticClass: "marginLeft5 grey" }, [
                            _vm._v("下单"),
                          ]),
                        ])
                      : _vm._e(),
                    order.zflow === 0
                      ? _c("span", { staticClass: "marginLeft5" }, [
                          _c("span", { staticClass: "grey" }, [
                            _vm._v(
                              _vm._s(_vm.topsUtils.parseTime(order.tupdate)) +
                                " 已完成"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    order.zflow === 3
                      ? _c("span", { staticClass: "marginLeft5" }, [
                          _c(
                            "span",
                            {
                              staticClass: "fWB",
                              staticStyle: { color: "rgb(217, 0, 27)" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("compareDate")(order.tarriveReq))
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "fWB",
                              staticStyle: { color: "rgb(217, 0, 27)" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  order.tarriveReq === undefined
                                    ? ""
                                    : order.tarriveReq.slice(-8)
                                )
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "grey" }, [
                            _vm._v("前送达"),
                          ]),
                          _vm.compareDateTime(order.tarriveReq)
                            ? _c("span", { staticClass: "grey" }, [
                                _vm._v(", 已超时 "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "fWB",
                                    staticStyle: { color: "rgb(217, 0, 27)" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getTimeDifferenceInMinutes(
                                          order.tarriveReq
                                        )
                                      ) + "分钟"
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    order.zflow === 5
                      ? _c("span", { staticClass: "marginLeft5" }, [
                          order.zrefund === 1
                            ? _c("span", { staticClass: "grey" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.topsUtils.parseTime(order.tupdate)
                                  ) + " 已完成"
                                ),
                              ])
                            : _vm._e(),
                          order.zrefund === 0 || order.zrefund === 2
                            ? _c("span", [
                                _c("span", { staticClass: "grey" }, [
                                  _vm._v("已取消，"),
                                ]),
                                _c("span", { staticClass: "fWB red" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.calculateOrderPlacementTime(
                                        order.tcreate
                                      )
                                    )
                                  ),
                                ]),
                                _c("span", { staticClass: "grey" }, [
                                  _vm._v("下单1"),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    order.zflow === 8
                      ? _c("span", { staticClass: "marginLeft5" }, [
                          _c(
                            "span",
                            {
                              staticClass: "fWB",
                              staticStyle: { color: "rgb(217, 0, 27)" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.calculateOrderPlacementTime(
                                    order.torderGrabbing
                                  )
                                )
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "grey" }, [_vm._v("抢单")]),
                        ])
                      : _vm._e(),
                    order.zflow === 9
                      ? _c("span", { staticClass: "marginLeft5" }, [
                          _c(
                            "span",
                            {
                              staticClass: "fWB",
                              staticStyle: { color: "rgb(217, 0, 27)" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("compareDate")(order.tarriveReq))
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "fWB",
                              staticStyle: { color: "rgb(217, 0, 27)" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    order.tarriveReq === undefined
                                      ? ""
                                      : order.tarriveReq.slice(-8)
                                  )
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "grey" }, [
                            _vm._v("前送达，"),
                          ]),
                          _vm.compareDateTime(order.tarriveReq)
                            ? _c("span", { staticClass: "grey" }, [
                                _vm._v(
                                  " 剩余 " +
                                    _vm._s(
                                      _vm.getTimeDifferenceInMinutes(
                                        order.tarriveReq
                                      )
                                    ) +
                                    "分钟 "
                                ),
                              ])
                            : _c("span", { staticClass: "grey" }, [
                                _vm._v(" 已超时 "),
                                _c(
                                  "span",
                                  { staticStyle: { color: "rgb(217, 0, 27)" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getTimeDifferenceInMinutes(
                                          order.tarriveReq
                                        )
                                      ) + "分钟"
                                    ),
                                  ]
                                ),
                              ]),
                        ])
                      : _vm._e(),
                    _c(
                      "span",
                      { staticClass: "marginLeftAuto marginRight20" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "订单日志",
                              placement: "top",
                              effect: "light",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document marginRight10",
                            }),
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "复制订单",
                              placement: "top",
                              effect: "light",
                            },
                          },
                          [
                            order.zplatOrder === 6
                              ? _c("i", {
                                  staticClass:
                                    "el-icon-document-copy marginRight10",
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "撤回订单",
                              placement: "top",
                              effect: "light",
                            },
                          },
                          [
                            order.zplatOrder === 6
                              ? _c("i", {
                                  staticClass: "el-icon-back marginRight10",
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "删除订单",
                              placement: "top",
                              effect: "light",
                            },
                          },
                          [
                            order.zplatOrder === 6
                              ? _c("i", { staticClass: "el-icon-delete" })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", [
                  _c("span", { staticClass: "marginLeftAuto" }, [
                    _c("a", { staticClass: "blue" }, [
                      _vm._v(_vm._s(order.sshop)),
                    ]),
                  ]),
                  _c("span", { staticClass: "C909399" }, [
                    _vm._v("#" + _vm._s(order.nplatIndex)),
                  ]),
                ]),
                _c("div", { staticClass: "marginTop5" }, [
                  _vm._v(" " + _vm._s(order.ccustomerName) + " "),
                  _c(
                    "a",
                    {
                      staticClass: "blue",
                      on: {
                        click: function ($event) {
                          return _vm.callPhone(order.ccustomerPhone)
                        },
                      },
                    },
                    [_vm._v(_vm._s(order.ccustomerPhone))]
                  ),
                ]),
                _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "blue",
                      on: {
                        click: function ($event) {
                          return _vm.checkMap(order)
                        },
                      },
                    },
                    [_vm._v(_vm._s(order.ccustomerAddr))]
                  ),
                  order.ntripDist
                    ? _c("span", { staticClass: "grey" }, [
                        _vm._v(" · " + _vm._s(order.ntripDist) + "公里 "),
                      ])
                    : _vm._e(),
                ]),
                order.cremark !== "" &&
                order.cremark !== undefined &&
                order.cremark !== null
                  ? _c("div", { staticClass: "grey" }, [
                      _vm._v(" 备注："),
                      _c("span", [_vm._v(_vm._s(order.cremark))]),
                    ])
                  : _vm._e(),
                order.zflow === 8 || order.zflow === 9
                  ? _c(
                      "div",
                      { staticClass: "grey" },
                      [
                        order.zflow === 8 && !order.tarriveStore
                          ? _c(
                              "div",
                              {
                                staticClass: "black marginTop10 marginBottom10",
                              },
                              [_vm._v("接单成功")]
                            )
                          : _vm._e(),
                        order.zflow === 8 && order.tarriveStore
                          ? _c(
                              "div",
                              {
                                staticClass: "black marginTop10 marginBottom10",
                              },
                              [_vm._v("骑手到店")]
                            )
                          : _vm._e(),
                        order.zflow === 9
                          ? _c(
                              "div",
                              {
                                staticClass: "black marginTop10 marginBottom10",
                              },
                              [_vm._v("配送中")]
                            )
                          : _vm._e(),
                        _vm._v(
                          " 配送员: " + _vm._s(order.cdeliveryName) + ", "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "blue marginLeft5",
                            on: {
                              click: function ($event) {
                                return _vm.callPhone(order.cdeliveryPhone)
                              },
                            },
                          },
                          [_vm._v(_vm._s(order.cdeliveryPhone))]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item marginLeft5 pointer",
                            attrs: {
                              effect: "dark",
                              content: "点击向骑手发起短信",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "iconfont",
                                on: {
                                  click: function ($event) {
                                    return _vm.openMessageDialog(
                                      order.cdeliveryPhone
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(""))]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                order.zflow === 5 && order.zrefund === 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "marginTop5",
                        attrs: { size: "mini", type: "primary" },
                      },
                      [_vm._v(" 待处理 ")]
                    )
                  : _vm._e(),
                order.zflow === 3
                  ? _c(
                      "div",
                      [
                        _c("el-divider"),
                        _c("el-button", { attrs: { size: "mini" } }, [
                          _vm._v(" 未回复 "),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "查看位置",
            visible: _vm.addrDialogVisible,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addrDialogVisible = $event
            },
          },
        },
        [
          _vm.addrDialogVisible
            ? _c("getDeliveryRoutes", { attrs: { ord: _vm.orderInfo } })
            : _vm._e(),
        ],
        1
      ),
      _vm.messageDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "videoDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "发送短信",
                visible: _vm.messageDialogVisible,
                width: "350px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.messageDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex alignItemsCenter marginBottom10" },
                [
                  _c("div", { staticClass: "marginRight10" }, [
                    _vm._v("接收号码 :"),
                  ]),
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入内容" },
                    model: {
                      value: _vm.receivePhone,
                      callback: function ($$v) {
                        _vm.receivePhone = $$v
                      },
                      expression: "receivePhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "marginBottom10" },
                [
                  _c("div", { staticClass: "marginRight10" }, [
                    _vm._v("短信内容:"),
                  ]),
                  _c("el-input", {
                    staticClass: "marginTop5",
                    attrs: {
                      placeholder: "请输入短信内容，最多不超过70个汉字。",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.messageContent,
                      callback: function ($$v) {
                        _vm.messageContent = $$v
                      },
                      expression: "messageContent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "end" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.onCencer },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.sendSMS } },
                    [_vm._v("确 认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }