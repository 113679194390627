<template>
  <!--  查看路线-->
  <div class="getStoreLocation">
    <div id="container" class="hHP wHP" />
  </div>
</template>

<script>
export default {
  name: "GetStoreLocation",
  props: {
    storeId: {
      type: [String, Object, Array],
      default: "",
    },
  },
  data() {
    return {
      storeInfo: {},
    };
  },
  mounted() {
    this.getStoreInfo(this.storeId);
  },
  methods: {
    getStoreInfo(id) {
      let url = `${this.baseUrls.store}/get/` + id;
      this.topsAjax.get(url).then((res) => {
        this.storeInfo = res;
        this.getStoreLocation();
      });
    },
    getStoreLocation() {
      var map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: 15,
        center: [this.storeInfo.clongitude, this.storeInfo.clatitude],
      });
      var marker = new AMap.Marker({
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
        position: [this.storeInfo.clongitude, this.storeInfo.clatitude],
        anchor: "bottom-center",
      });
      var position = [this.storeInfo.clongitude, this.storeInfo.clatitude];

      //构建自定义信息窗体
      var infoWindow = new AMap.InfoWindow({
        anchor: "top-left",
        content: `${this.storeInfo.cname}`,
      });
      infoWindow.open(map, position);
      new AMap.Marker({ position: position, map });
      map.add(marker);
      // map.setFitView();
    },
  },
};
</script>

<style lang="scss">
.getStoreLocation {
  height: 400px;
}
</style>
