import { value } from "lodash/seq";

const deliveryPlatform = {
  fengka: "蜂鸟KA",
  bingex: "闪送",
  dada: "达达",
  shunfeng: "顺丰",
  uupt: "UU跑腿",
  mtps: "美团配送",
  caocao: "曹操",
  ipaotui: "爱跑腿",
  fuwu: "快服务",
  caosong: "曹操送",
  sanliuwu: "365跑腿",
  bangla: "帮啦",
  fengnew: "蜂鸟配送",
  keloop: "快跑者",
  guoxiaodi: "裹小递",
  haoji: "好急",
  heiqi: "黑骑配送",
  xiakesong: "同达快送",
  dadac: "达达急送",
  huolala: "货拉拉",
  huollpt: "货拉拉跑腿",
};
export default deliveryPlatform;
