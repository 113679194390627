var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsLocation" },
    [
      _c(
        "el-descriptions",
        [
          _c("el-descriptions-item", { attrs: { label: "出库单号" } }, [
            _vm._v(
              _vm._s(
                _vm.inventoryOrderOut === undefined
                  ? ""
                  : _vm.inventoryOrderOut.ccode
              )
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "订单编号" } }, [
            _vm._v(_vm._s(_vm.order.ccode)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "下单时间" } }, [
            _vm._v(_vm._s(_vm._f("time")(_vm.order.tcreate))),
          ]),
          _c("el-descriptions-item", { attrs: { label: "要求送达" } }, [
            _vm._v(_vm._s(_vm.order.tarriveReq)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "预订人" } }, [
            _vm._v(_vm._s(_vm.order.ccustomerName)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "预定电话" } }, [
            _vm._v(_vm._s(_vm.order.ccustomerPhone)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "隐私号码" } }, [
            _vm._v(_vm._s(_vm.order.cun)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "收货地址" } }, [
            _vm._v(_vm._s(_vm.order.ccustomerAddr)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "配送门店" } }, [
            _vm._v(_vm._s(_vm.order.sshop)),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "marginBottom10" }, [
        _vm._v(" 共计 " + _vm._s(_vm.goodsList.length) + " 件货品 合计："),
        _c("span", { staticClass: "fs16" }, [
          _vm._v(_vm._s(_vm._f("parsePrice")(_vm.order.noriginPrice))),
        ]),
        _vm._v(" 元 "),
      ]),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              ref: "product",
              attrs: { border: "", data: _vm.goodsList, "max-height": "400" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  type: "index",
                  label: "序号",
                  width: "70",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "ordGoodsSku",
                  label: "货品SKU",
                  width: "170",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "图片", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.c_master_url != null
                          ? _c("el-image", {
                              staticClass: "w50 h50",
                              attrs: {
                                "preview-src-list": [scope.row.c_master_url],
                                src: scope.row.c_master_url,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "ordGoodsName",
                  label: "货品名称",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "warehouse",
                  label: "所在库位",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "ordGoodsNum",
                  label: "数量",
                  width: "70",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "unit",
                  label: "单位",
                  width: "70",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _vm.isConfirm
            ? _c(
                "el-button",
                {
                  staticClass: "marginTop10",
                  attrs: { type: "primary" },
                  on: { click: _vm.confirm },
                },
                [_vm._v("确认出库")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }