<template>
  <div class="noData">
    <el-empty class="bcWhite" description="暂无数据"></el-empty>
  </div>
</template>

<script>
export default {
  name: "NoData",
};
</script>

<style lang="scss">
.noData {
  height: 100%;
  width: 100%;
  .el-collapse {
    border: 0;
  }
  .el-empty {
    height: 100%;
    width: 100%;
  }
}
</style>
