var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderInquiry dialogSetting" },
    [
      _c("div", { staticClass: "orderInquiry__left scrollbar marginRight10" }, [
        _c(
          "div",
          {
            staticStyle: {
              height: "100%",
              display: "flex",
              "flex-direction": "column",
            },
          },
          [
            _c(
              "div",
              { staticClass: "bcWhite box-shadow padding10 marginBottom10" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "marginRight10",
                    attrs: { placeholder: "订单类型" },
                    on: { change: _vm.handleChangeType },
                    model: {
                      value: _vm.typeValue,
                      callback: function ($$v) {
                        _vm.typeValue = $$v
                      },
                      expression: "typeValue",
                    },
                  },
                  _vm._l(_vm.typeOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticClass: "marginRight10",
                    attrs: { placeholder: "订单标签" },
                    on: { change: _vm.handleChangeTag },
                    model: {
                      value: _vm.tagValue,
                      callback: function ($$v) {
                        _vm.tagValue = $$v
                      },
                      expression: "tagValue",
                    },
                  },
                  _vm._l(_vm.tagOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticClass: "marginRight10",
                    attrs: { placeholder: "订单来源" },
                    on: { change: _vm.handleSelectDelivery },
                    model: {
                      value: _vm.deliveryValue,
                      callback: function ($$v) {
                        _vm.deliveryValue = $$v
                      },
                      expression: "deliveryValue",
                    },
                  },
                  _vm._l(_vm.deliveryOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.reset } },
                  [_vm._v("重置")]
                ),
                _c(
                  "el-select",
                  {
                    staticClass: "marginRight10",
                    attrs: {
                      filterable: "",
                      placeholder: "请选择门店",
                      clearable: "",
                    },
                    on: { change: _vm.handleChangeStore },
                    model: {
                      value: _vm.storeValue,
                      callback: function ($$v) {
                        _vm.storeValue = $$v
                      },
                      expression: "storeValue",
                    },
                  },
                  _vm._l(_vm.storeOptions, function (item) {
                    return _c("el-option", {
                      key: item.sid,
                      attrs: { label: item.cname, value: item.sid },
                    })
                  }),
                  1
                ),
                _c(
                  "el-select",
                  {
                    ref: "sortSelect",
                    staticClass: "marginRight10",
                    attrs: { placeholder: "排序方式" },
                    on: { change: _vm.handleSelectSort },
                    model: {
                      value: _vm.sortingValue,
                      callback: function ($$v) {
                        _vm.sortingValue = $$v
                      },
                      expression: "sortingValue",
                    },
                  },
                  _vm._l(_vm.sortingOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
                _c("el-date-picker", {
                  staticClass: "marginTop10",
                  attrs: {
                    align: "right",
                    type: "daterange",
                    "unlink-panels": "",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    "picker-options": _vm.pickerOptions,
                  },
                  on: { change: _vm.handleChangeOrderDate },
                  model: {
                    value: _vm.orderDate,
                    callback: function ($$v) {
                      _vm.orderDate = $$v
                    },
                    expression: "orderDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "bcWhite scrollbar box-shadow",
                staticStyle: { height: "100%" },
              },
              [
                _vm.orderData.length === 0
                  ? _c("no-data", {
                      staticClass: "empty frame",
                      attrs: { description: "暂无数据" },
                    })
                  : _vm._e(),
                _vm.orderData.length > 0
                  ? _c("OrderList", {
                      attrs: { "order-list-data": _vm.orderData },
                      on: { selectOrderId: _vm.selectOrderId },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "orderInquiry__right flex" }, [
        _c(
          "div",
          { staticClass: "bcWhite box-shadow", staticStyle: { flex: "1" } },
          [
            _vm.ordId === "" || _vm.ordId === null
              ? _c(
                  "div",
                  { staticClass: "padding10 flex directionColumn" },
                  [
                    _c("div", { staticClass: "fs20 fWB marginTop10" }, [
                      _vm._v("数据总览"),
                    ]),
                    _c("el-divider"),
                    _c("div", [
                      _vm._v(
                        "总订单" + _vm._s(_vm.statisticalData.totalOrder) + "单"
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "预计收入 " +
                          _vm._s(
                            _vm._f("parsePrice")(
                              _vm.statisticalData.totalOrderPrice
                            )
                          ) +
                          "元"
                      ),
                    ]),
                    _c("el-divider"),
                    _c("div", [
                      _vm._v(
                        "已完成订单 " +
                          _vm._s(_vm.statisticalData.completeOrder) +
                          " 单"
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "预计收入 " +
                          _vm._s(
                            _vm._f("parsePrice")(
                              _vm.statisticalData.completeOrderPrice
                            )
                          ) +
                          "元"
                      ),
                    ]),
                    _c("el-divider"),
                    _c("div", [
                      _vm._v(
                        "进行中订单 " +
                          _vm._s(_vm.statisticalData.ongoingOrder) +
                          " 单"
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "预计收入 " +
                          _vm._s(
                            _vm._f("parsePrice")(
                              _vm.statisticalData.ongoingOrderPrice
                            )
                          ) +
                          "元"
                      ),
                    ]),
                    _c("el-divider"),
                    _c("div", [
                      _vm._v(
                        "取消/退款订单 " +
                          _vm._s(_vm.statisticalData.refundOrder) +
                          " 单"
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "预计损失 " +
                          _vm._s(
                            _vm._f("parsePrice")(
                              _vm.statisticalData.refundOrderPrice
                            )
                          ) +
                          "元"
                      ),
                    ]),
                    _c("el-divider"),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = true
                          },
                        },
                      },
                      [_vm._v("导出订单到表格")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.ordId !== "" && _vm.ordId !== null
              ? _c(
                  "div",
                  { staticClass: "hHP" },
                  [
                    _vm.ordId != null && _vm.ordId !== ""
                      ? _c("OrderListInfo", {
                          staticClass: "hHP bcWhite box-shadow",
                          attrs: { "order-id": _vm.ordId },
                          on: { refreshPage: _vm.refreshPage },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "报表中心",
            center: "",
            "append-to-body": "",
            top: "0vh",
            visible: _vm.dialogVisible,
            width: "55%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "wHP marginTop10 flex1 export" }, [
            _c("div", { staticClass: "marginBottom10" }, [
              _c("div", { staticClass: "marginBottom10 fs17" }, [
                _vm._v("筛选订单"),
              ]),
              _c(
                "div",
                [
                  _vm._v(" 订单来源: "),
                  _c(
                    "el-select",
                    {
                      staticClass: "marginRight10",
                      attrs: { placeholder: "订单来源" },
                      on: { change: _vm.handleSelectDelivery },
                      model: {
                        value: _vm.source,
                        callback: function ($$v) {
                          _vm.source = $$v
                        },
                        expression: "source",
                      },
                    },
                    _vm._l(_vm.deliveryOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v(" 查询店铺: "),
                  _c(
                    "el-select",
                    {
                      staticClass: "marginRight10",
                      attrs: {
                        filterable: "",
                        placeholder: "请选择门店",
                        clearable: "",
                      },
                      on: { change: _vm.handleChangeStore },
                      model: {
                        value: _vm.shop,
                        callback: function ($$v) {
                          _vm.shop = $$v
                        },
                        expression: "shop",
                      },
                    },
                    _vm._l(_vm.storeOptions, function (item) {
                      return _c("el-option", {
                        key: item.sid,
                        attrs: { label: item.cname, value: item.sid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm._v(" 下单日期: "),
                  _c("el-date-picker", {
                    staticClass: "marginTop10",
                    attrs: {
                      align: "right",
                      type: "daterange",
                      "unlink-panels": "",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.pickerOptions,
                    },
                    on: { change: _vm.handleChangeOrderDate },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "h400 scrollbar" }, [
              _c("div", { staticClass: "marginBottom10 fs17" }, [
                _vm._v("表格字段"),
              ]),
              _c("div", { staticClass: "flex marginBottom20" }, [
                _c("div", { staticClass: "marginRight10" }, [
                  _vm._v("默认字段"),
                ]),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "marginBottom5" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marginLeft20 paddingRight20",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.defaultInfo,
                            callback: function ($$v) {
                              _vm.defaultInfo = $$v
                            },
                            expression: "defaultInfo",
                          },
                        },
                        [_vm._v("流水号")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marginLeft20 paddingRight20",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.defaultInfo,
                            callback: function ($$v) {
                              _vm.defaultInfo = $$v
                            },
                            expression: "defaultInfo",
                          },
                        },
                        [_vm._v("来源平台")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marginLeft20 paddingRight20",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.defaultInfo,
                            callback: function ($$v) {
                              _vm.defaultInfo = $$v
                            },
                            expression: "defaultInfo",
                          },
                        },
                        [_vm._v("平台店铺")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marginLeft20 paddingRight20",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.defaultInfo,
                            callback: function ($$v) {
                              _vm.defaultInfo = $$v
                            },
                            expression: "defaultInfo",
                          },
                        },
                        [_vm._v("状态")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marginBottom5" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marginLeft20 paddingRight20",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.defaultInfo,
                            callback: function ($$v) {
                              _vm.defaultInfo = $$v
                            },
                            expression: "defaultInfo",
                          },
                        },
                        [_vm._v("原流水号")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marginLeft20 paddingRight20",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.defaultInfo,
                            callback: function ($$v) {
                              _vm.defaultInfo = $$v
                            },
                            expression: "defaultInfo",
                          },
                        },
                        [_vm._v("订单编号")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marginLeft20 paddingRight20",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.defaultInfo,
                            callback: function ($$v) {
                              _vm.defaultInfo = $$v
                            },
                            expression: "defaultInfo",
                          },
                        },
                        [_vm._v("是否预约")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marginLeft20 paddingRight20",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.defaultInfo,
                            callback: function ($$v) {
                              _vm.defaultInfo = $$v
                            },
                            expression: "defaultInfo",
                          },
                        },
                        [_vm._v("下单日期")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marginBottom5" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "marginLeft20 paddingRight20",
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.defaultInfo,
                            callback: function ($$v) {
                              _vm.defaultInfo = $$v
                            },
                            expression: "defaultInfo",
                          },
                        },
                        [_vm._v("期望送达")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "flex marginBottom20" }, [
                _c("div", { staticClass: "marginRight10" }, [
                  _vm._v("基本信息"),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "marginBottom5" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "marginLeft20 paddingRight20",
                            attrs: { indeterminate: _vm.basicIndeterminate },
                            on: { change: _vm.basicCheckAllChange },
                            model: {
                              value: _vm.basicCheckAll,
                              callback: function ($$v) {
                                _vm.basicCheckAll = $$v
                              },
                              expression: "basicCheckAll",
                            },
                          },
                          [_vm._v("全选")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.basicInfo,
                          callback: function ($$v) {
                            _vm.basicInfo = $$v
                          },
                          expression: "basicInfo",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "备注" },
                              },
                              [_vm._v("备注")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "订单总金额" },
                              },
                              [_vm._v("订单总金额")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "商家实收金额" },
                              },
                              [_vm._v(" 商家实收金额 ")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "用户实付金额" },
                              },
                              [_vm._v(" 用户实付金额 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "用户实付配送金额" },
                              },
                              [_vm._v("用户实付配送金额")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "商品" },
                              },
                              [_vm._v("商品")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "数量" },
                              },
                              [_vm._v("数量")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "价格" },
                              },
                              [_vm._v("价格")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "取消时间" },
                              },
                              [_vm._v("取消时间")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "取消原因" },
                              },
                              [_vm._v("取消原因")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "渠道店铺ID" },
                              },
                              [_vm._v("渠道店铺ID")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex marginBottom20" }, [
                _c("div", { staticClass: "marginRight10" }, [
                  _vm._v("收货信息"),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "marginBottom5" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "marginLeft20 paddingRight20",
                            attrs: { indeterminate: _vm.receiptsIndeterminate },
                            on: { change: _vm.receiptsCheckAllChange },
                            model: {
                              value: _vm.receiptsCheckAll,
                              callback: function ($$v) {
                                _vm.receiptsCheckAll = $$v
                              },
                              expression: "receiptsCheckAll",
                            },
                          },
                          [_vm._v("全选")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.receiptsInfo,
                          callback: function ($$v) {
                            _vm.receiptsInfo = $$v
                          },
                          expression: "receiptsInfo",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "收货人" },
                              },
                              [_vm._v("收货人")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "收货人电话" },
                              },
                              [_vm._v("收货人电话")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "预订人电话" },
                              },
                              [_vm._v("预订人电话")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "地址" },
                              },
                              [_vm._v("地址")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "详细地址" },
                              },
                              [_vm._v("详细地址 ")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "距离" },
                              },
                              [_vm._v("距离")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "新老顾客" },
                              },
                              [_vm._v("新老顾客")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "收藏顾客" },
                              },
                              [_vm._v("收藏顾客")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex marginBottom20" }, [
                _c("div", { staticClass: "marginRight10" }, [
                  _vm._v("配送信息"),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "marginBottom5" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "marginLeft20 paddingRight20",
                            attrs: { indeterminate: _vm.deliveryIndeterminate },
                            on: { change: _vm.deliveryCheckAllChange },
                            model: {
                              value: _vm.deliveryCheckAll,
                              callback: function ($$v) {
                                _vm.deliveryCheckAll = $$v
                              },
                              expression: "deliveryCheckAll",
                            },
                          },
                          [_vm._v("全选")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.deliveryInfo,
                          callback: function ($$v) {
                            _vm.deliveryInfo = $$v
                          },
                          expression: "deliveryInfo",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "预计发货时间" },
                              },
                              [_vm._v("预计发货时间")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "实际发货时间" },
                              },
                              [_vm._v("实际发货时间")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "完成时间" },
                              },
                              [_vm._v("完成时间")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "配送门店" },
                              },
                              [_vm._v("配送门店")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "配送平台" },
                              },
                              [_vm._v("配送平台")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "配送单号" },
                              },
                              [_vm._v("配送单号")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "骑手姓名" },
                              },
                              [_vm._v("骑手姓名")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "骑手电话" },
                              },
                              [_vm._v("骑手电话")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "配送费" },
                              },
                              [_vm._v("配送费")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "小费" },
                              },
                              [_vm._v("小费")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "配送状态" },
                              },
                              [_vm._v("配送状态")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "总配送费" },
                              },
                              [_vm._v("总配送费")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "配送距离" },
                              },
                              [_vm._v("配送距离")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "首次发单时间" },
                              },
                              [_vm._v("首次发单时间")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "首发完成时间" },
                              },
                              [_vm._v("首发完成时间")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "首次发单人" },
                              },
                              [_vm._v("首次发单人")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "配送备注" },
                              },
                              [_vm._v("配送备注")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "抢单时间" },
                              },
                              [_vm._v("抢单时间")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "到店时间" },
                              },
                              [_vm._v("到店时间")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "开始配送时间" },
                              },
                              [_vm._v("开始配送时间")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex marginBottom20" }, [
                _c("div", { staticClass: "marginRight10" }, [
                  _vm._v("其他信息"),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "marginBottom5" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "marginLeft20 paddingRight20",
                            attrs: { indeterminate: _vm.otherIndeterminate },
                            on: { change: _vm.otherCheckAllChange },
                            model: {
                              value: _vm.otherCheckAll,
                              callback: function ($$v) {
                                _vm.otherCheckAll = $$v
                              },
                              expression: "otherCheckAll",
                            },
                          },
                          [_vm._v("全选")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.otherInfo,
                          callback: function ($$v) {
                            _vm.otherInfo = $$v
                          },
                          expression: "otherInfo",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "内部备注" },
                              },
                              [_vm._v("内部备注")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "团购券码" },
                              },
                              [_vm._v("团购券码")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "蛋糕祝语" },
                              },
                              [_vm._v("蛋糕祝语")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "贺卡" },
                              },
                              [_vm._v("贺卡")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marginBottom5" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "蜡烛" },
                              },
                              [_vm._v("蜡烛")]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "marginLeft20 paddingRight20",
                                attrs: { label: "配件" },
                              },
                              [_vm._v("配件")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "marginLeftAuto marginRightAuto marginTop10" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "success" }, on: { click: _vm.exportData } },
                  [_vm._v("导出表格")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }