var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderProcessCol" }, [
    _c("div", { staticClass: "orderProcessCol__top" }, [
      _c("div", { staticClass: "left" }, [
        _c(
          "div",
          { staticClass: "bcWhite box-shadow" },
          [
            _c(
              "el-tabs",
              {
                staticClass: "bcWhite",
                attrs: { type: "card" },
                on: { "tab-click": _vm.handleTabClick },
                model: {
                  value: _vm.queryParams.zFlow,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "zFlow", $$v)
                  },
                  expression: "queryParams.zFlow",
                },
              },
              [
                _c("el-tab-pane", { attrs: { label: "新订单", name: "1" } }, [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 新订单"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.countOrderMap[1].n_count &&
                              _vm.countOrderMap[1].n_count > 0,
                            expression:
                              "countOrderMap[1].n_count && countOrderMap[1].n_count > 0",
                          },
                        ],
                        staticClass: "el-badge__content",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("dot")(_vm.countOrderMap[1].n_count)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-tab-pane", { attrs: { label: "异常订单", name: "2" } }, [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 异常订单"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.countOrderMap[2].n_count &&
                              _vm.countOrderMap[2].n_count > 0,
                            expression:
                              "countOrderMap[2].n_count && countOrderMap[2].n_count > 0",
                          },
                        ],
                        staticClass: "el-badge__content",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("dot")(_vm.countOrderMap[2].n_count)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-tab-pane", { attrs: { label: "催单", name: "3" } }, [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 催单"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.countOrderMap[3].n_count &&
                              _vm.countOrderMap[3].n_count > 0,
                            expression:
                              "countOrderMap[3].n_count && countOrderMap[3].n_count > 0",
                          },
                        ],
                        staticClass: "el-badge__content",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("dot")(_vm.countOrderMap[3].n_count)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-tab-pane", { attrs: { label: "配送异常", name: "4" } }, [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 配送异常"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.countOrderMap[4].n_count &&
                              _vm.countOrderMap[4].n_count > 0,
                            expression:
                              "countOrderMap[4].n_count && countOrderMap[4].n_count > 0",
                          },
                        ],
                        staticClass: "el-badge__content",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("dot")(_vm.countOrderMap[4].n_count)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "取消/退款", name: "5" } },
                  [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _vm._v(" 取消/ 退款"),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.countOrderMap[5].n_count &&
                                _vm.countOrderMap[5].n_count > 0,
                              expression:
                                "countOrderMap[5].n_count && countOrderMap[5].n_count > 0",
                            },
                          ],
                          staticClass: "el-badge__content",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("dot")(_vm.countOrderMap[5].n_count)
                            ) + " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c("el-tab-pane", { attrs: { label: "预订单", name: "10" } }, [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 预订单"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.countOrderMap[10].n_count &&
                              _vm.countOrderMap[10].n_count > 0,
                            expression:
                              "countOrderMap[10].n_count && countOrderMap[10].n_count > 0",
                          },
                        ],
                        staticClass: "el-badge__content",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("dot")(_vm.countOrderMap[10].n_count)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-tab-pane", { attrs: { label: "待抢单", name: "7" } }, [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 待抢单"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.countOrderMap[7].n_count &&
                              _vm.countOrderMap[7].n_count > 0,
                            expression:
                              "countOrderMap[7].n_count && countOrderMap[7].n_count > 0",
                          },
                        ],
                        staticClass: "el-badge__content",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("dot")(_vm.countOrderMap[7].n_count)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-tab-pane", { attrs: { label: "待取货", name: "8" } }, [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 待取货"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.countOrderMap[8].n_count &&
                              _vm.countOrderMap[8].n_count > 0,
                            expression:
                              "countOrderMap[8].n_count && countOrderMap[8].n_count > 0",
                          },
                        ],
                        staticClass: "el-badge__content",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("dot")(_vm.countOrderMap[8].n_count)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-tab-pane", { attrs: { label: "配送中", name: "9" } }, [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 配送中"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.countOrderMap[9].n_count &&
                              _vm.countOrderMap[9].n_count > 0,
                            expression:
                              "countOrderMap[9].n_count && countOrderMap[9].n_count > 0",
                          },
                        ],
                        staticClass: "el-badge__content",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("dot")(_vm.countOrderMap[9].n_count)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-tab-pane", { attrs: { label: "待处理", name: "11" } }, [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 待处理"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.countOrderMap[11].n_count &&
                              _vm.countOrderMap[11].n_count > 0,
                            expression:
                              "countOrderMap[11].n_count && countOrderMap[11].n_count > 0",
                          },
                        ],
                        staticClass: "el-badge__content",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("dot")(_vm.countOrderMap[11].n_count)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-tab-pane", { attrs: { label: "已完成", name: "0" } }, [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" 已完成"),
                    _c("span", { staticClass: "el-badge__content" }, [
                      _vm._v(
                        _vm._s(_vm._f("dot")(_vm.countOrderMap[0].n_count)) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bcWhite box-shadow" },
          [
            _c(
              "el-select",
              {
                staticClass: "marginRight10",
                attrs: { filterable: "", placeholder: "全部门店" },
                on: { change: _vm.handleSelectStore },
                model: {
                  value: _vm.shopValue,
                  callback: function ($$v) {
                    _vm.shopValue = $$v
                  },
                  expression: "shopValue",
                },
              },
              _vm._l(_vm.storeOptions, function (item) {
                return _c("el-option", {
                  key: item.sid,
                  attrs: { label: item.cname, value: item.sid },
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "marginRight10",
                attrs: { placeholder: "" },
                on: { change: _vm.handleSelectDelivery },
                model: {
                  value: _vm.deliveryValue,
                  callback: function ($$v) {
                    _vm.deliveryValue = $$v
                  },
                  expression: "deliveryValue",
                },
              },
              _vm._l(_vm.deliveryOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                ref: "sortSelect",
                staticClass: "marginRight10",
                attrs: { placeholder: "排序方式" },
                on: { change: _vm.handleSelectSort },
                model: {
                  value: _vm.sortingValue,
                  callback: function ($$v) {
                    _vm.sortingValue = $$v
                  },
                  expression: "sortingValue",
                },
              },
              _vm._l(_vm.sortingOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "right bcWhite box-shadow h100",
          staticStyle: { "min-width": "27%" },
        },
        [
          _vm.queryParams.zFlow === "0"
            ? _c(
                "div",
                {
                  staticClass: "hHP flex directionColumn contentCenter",
                  staticStyle: { "background-color": "rgb(235, 244, 246)" },
                },
                [
                  _c("div", { staticClass: "marginLeft20 marginBottom5" }, [
                    _vm._v("已完成"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "marginLeft20 fs20 fWB",
                      staticStyle: { color: "rgb(0, 170, 48)" },
                    },
                    [_vm._v(_vm._s(_vm.countOrderMap[0].n_count))]
                  ),
                ]
              )
            : _vm._e(),
          !(_vm.queryParams.zFlow === "0")
            ? _c(
                "div",
                { staticClass: "hHP marginTop10 marginLeft20" },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "marginBottom5",
                      staticStyle: { height: "23px" },
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "fs13 pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleTabClick({ name: "1" })
                              },
                            },
                          },
                          [_vm._v("新订单")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "fs13" }, [
                          _vm._v(_vm._s(_vm.countOrderMap[1].n_count)),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "fs13" }, [_vm._v("预订单")]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "fs13" }, [
                          _vm._v(_vm._s(_vm.countOrderMap[10].n_count)),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "fs13 pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleTabClick({ name: "3" })
                              },
                            },
                          },
                          [_vm._v("催单")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "fs13" }, [
                          _vm._v(_vm._s(_vm.countOrderMap[3].n_count)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticClass: "marginBottom5 h20",
                      staticStyle: { height: "23px" },
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "fs13 pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleTabClick({ name: "4" })
                              },
                            },
                          },
                          [_vm._v("配送异常")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "fs13" }, [
                          _vm._v(_vm._s(_vm.countOrderMap[4].n_count)),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "fs13 pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleTabClick({ name: "5" })
                              },
                            },
                          },
                          [_vm._v("取消退款")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "fs13" }, [
                          _vm._v(_vm._s(_vm.countOrderMap[5].n_count)),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "fs13 pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleTabClick({ name: "6" })
                              },
                            },
                          },
                          [_vm._v("待配送")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "fs13" }, [
                          _vm._v(_vm._s(_vm.countOrderMap[6].n_count)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "fs13 pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleTabClick({ name: "7" })
                              },
                            },
                          },
                          [_vm._v("待配送")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "fs13" }, [
                          _vm._v(_vm._s(_vm.countOrderMap[7].n_count)),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "fs13 pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleTabClick({ name: "8" })
                              },
                            },
                          },
                          [_vm._v("待配送")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "fs13" }, [
                          _vm._v(_vm._s(_vm.countOrderMap[8].n_count)),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "fs13 pointer",
                            on: {
                              click: function ($event) {
                                return _vm.handleTabClick({ name: "9" })
                              },
                            },
                          },
                          [_vm._v("配送中")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "fs13" }, [
                          _vm._v(_vm._s(_vm.countOrderMap[9].n_count)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]),
    _c("div", { staticClass: "orderProcessCol__body" }, [
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "body__left flex directionColumn" }, [
          _vm.queryParams.zFlow === "1" || _vm.queryParams.zFlow === "6"
            ? _c(
                "div",
                {
                  staticClass:
                    "h50 marginBottom10 box-shadow bcWhite wHP flex alignItemsCenter paddingLeft20",
                },
                [
                  _c(
                    "div",
                    [
                      _vm._v(" 自动发单 "),
                      _c("el-switch", {
                        staticClass: "marginLeft20",
                        on: { change: _vm.handleAutoSend },
                        model: {
                          value: _vm.autoSend,
                          callback: function ($$v) {
                            _vm.autoSend = $$v
                          },
                          expression: "autoSend",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marginLeft20" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "marginRight10",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择发单频次",
                          },
                          model: {
                            value: _vm.OrderFrequency,
                            callback: function ($$v) {
                              _vm.OrderFrequency = $$v
                            },
                            expression: "OrderFrequency",
                          },
                        },
                        _vm._l(_vm.frequencyOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "flex1 scrollAuto scrollbar" }, [
            _c(
              "div",
              { staticClass: "hHP" },
              [
                _vm.orderData.length === 0 ? _c("no-data") : _vm._e(),
                _vm.orderData.length > 0
                  ? _c("OrderList", {
                      staticClass: "hHP",
                      attrs: { "order-list-data": _vm.orderData },
                      on: { selectOrderId: _vm.selectOrderId },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "body__right" },
          [
            _vm.orderId == null || _vm.orderId === ""
              ? _c("no-data", { staticClass: "box-shadow" })
              : _vm._e(),
            _vm.orderId != null && _vm.orderId !== ""
              ? _c("OrderListInfo", {
                  staticClass: "bcWhite box-shadow",
                  attrs: { "order-id": _vm.orderId },
                  on: { refreshPage: _vm.refreshPage },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }