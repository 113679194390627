export function orderPickingState(value) {
  // 单据状态 1-制单中 2-待出货 3-已出货 4-已取消
  if (value === 1) {
    return "制单中";
  } else if (value === 2) {
    return "待出货";
  } else if (value === 3) {
    return "已出货";
  } else if (value === 4) {
    return "已取消";
  } else if (value === 5) {
    return "部分核销";
  } else if (value === 6) {
    return "已核销";
  } else {
    return "";
  }
}
