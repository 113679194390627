import request from "../../utils/request";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { tansParams } from "@/api/device/device";

/**货品列表
 *
 * @param params
 * @returns {*}
 */
export function orderAdd(params) {
  return request({
    url: "/api/order/order/add",
    method: "post",
    data: params,
  });
}

export function importOrderTemplate(params) {
  exportExcel("/api/order/order/exportData", params, `订单导出.xlsx`);
}

export function exportExcel(url, params, fileName) {
  axios
    .post(url, params, {
      transformRequest: [
        (params) => {
          return tansParams(params);
        },
      ],
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: getToken() },
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
}
