var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PickingInfo" },
    [
      _c(
        "div",
        { staticClass: "PickingInfo__top" },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 3 } },
            [
              _c("el-descriptions-item", { attrs: { label: "系统单号" } }, [
                _vm._v(" " + _vm._s(_vm.pickingInfo.rsys) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "检索单号" } }, [
                _vm._v(" " + _vm._s(_vm.pickingInfo.sretrieval) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "订单号" } }, [
                _vm._v(" " + _vm._s(_vm.pickingInfo.rorder) + " "),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 2 } },
            [
              _c("el-descriptions-item", { attrs: { label: "出货门店" } }, [
                _vm._v(" " + _vm._s(_vm.pickingInfo.sstore) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "门店地址" } }, [
                _vm._v(" " + _vm._s(_vm.pickingInfo.sstoreAddr) + " "),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 1 } },
            [
              _c("el-descriptions-item", { attrs: { label: "顾客地址" } }, [
                _vm._v(" " + _vm._s(_vm.pickingInfo.ccustomerAddr) + " "),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 2 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "单据状态" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("orderPickingState")(_vm.pickingInfo.zstate)
                        ) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "单据备注" } }, [
                    _vm._v(" " + _vm._s(_vm.pickingInfo.cremark) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "w200 flex",
                  staticStyle: { "justify-content": "flex-end" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini", plain: "" },
                      on: {
                        click: function ($event) {
                          _vm.deviceDialogVisible = true
                        },
                      },
                    },
                    [_vm._v("门店智控")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 4 } },
            [
              _c("el-descriptions-item", { attrs: { label: "制单人" } }, [
                _vm._v(" " + _vm._s(_vm.pickingInfo.screate) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "制单时间" } }, [
                _vm._v(
                  " " + _vm._s(_vm._f("time")(_vm.pickingInfo.tcreate)) + " "
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "审核人" } }, [
                _vm._v(" " + _vm._s(_vm.pickingInfo.saudit) + " "),
              ]),
              _c("el-descriptions-item", { attrs: { label: "审核时间" } }, [
                _vm._v(
                  " " + _vm._s(_vm._f("time")(_vm.pickingInfo.taudit)) + " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "h450" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                data: _vm.tableData,
                height: "450",
                "row-class-name": _vm.formatRow,
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  align: "center",
                  width: "50",
                  selectable: _vm.checkSelectable,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  align: "center",
                  width: "50",
                  type: "index",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "SKU",
                  align: "center",
                  width: "70",
                  prop: "c_barcode",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "图片", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.c_master_url != null
                          ? _c("el-image", {
                              staticClass: "w50 h50",
                              attrs: {
                                "preview-src-list": [scope.row.c_master_url],
                                src: scope.row.c_master_url,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "货品编码",
                  align: "center",
                  width: "130",
                  prop: "c_sku",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "货品名称", align: "center", prop: "c_name" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单位",
                  align: "center",
                  width: "70",
                  prop: "s_unit",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "单价", align: "center", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.n_price
                          ? _c("div", [
                              _vm._v(
                                "￥ " + _vm._s(scope.row.n_price.toFixed(2))
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库位",
                  align: "center",
                  width: "70",
                  prop: "c_num",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "可用库存",
                  align: "center",
                  width: "70",
                  prop: "n_old_surplus",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "数量", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.num > 1
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(scope.row.num)),
                            ])
                          : _c("span", [_vm._v(_vm._s(scope.row.num))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex alignItemsCenter marginTop10",
          staticStyle: { "justify-content": "space-between", flex: "1" },
        },
        [
          _c("div", [
            _vm._v(" 共计 "),
            _c("span", { staticClass: "blue" }, [
              _vm._v(_vm._s(_vm.tableData.length) + "种"),
            ]),
            _vm._v(" 货品 "),
          ]),
          _c(
            "div",
            [
              _vm.pickingInfo.zstate === 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", plain: "" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("取消")]
                  )
                : _vm._e(),
              _vm.pickingInfo.zstate === 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success", plain: "" },
                      on: { click: _vm.update },
                    },
                    [_vm._v("改单")]
                  )
                : _vm._e(),
              _vm.pickingInfo.zstate === 2
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.shipment } },
                    [_vm._v("出货")]
                  )
                : _vm._e(),
              _vm.pickingInfo.zstate === 3 || _vm.pickingInfo.zstate === 5
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.checkDestroy },
                    },
                    [_vm._v("核销")]
                  )
                : _vm._e(),
              _vm.pickingInfo.zstate !== 2
                ? _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取消"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.deviceDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "elDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "查看门店智控",
                visible: _vm.deviceDialogVisible,
                width: "360px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.deviceDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "hHP flex directionColumn marginLeft10 marginRight10",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "padding10 border borderRadius10 marginBottom10",
                    },
                    [
                      _c("div", { staticClass: "fs13" }, [
                        _vm._v(_vm._s(_vm.pickingInfo.sstore)),
                      ]),
                      _c("a", { staticClass: "purple fs13" }, [
                        _vm._v(_vm._s(_vm.pickingInfo.sstoreAddr)),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "padding10 border borderRadius10 marginBottom10 hHP",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "borderBottom",
                          staticStyle: {
                            "padding-bottom": "5px",
                            "margin-bottom": "5px",
                          },
                        },
                        [
                          _c("span", { staticClass: "fs20" }, [
                            _vm._v("门店监控"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex directionColumn" },
                        _vm._l(_vm.videoList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "flex alignItemsCenter directionColumn",
                            },
                            [
                              _c("a", {
                                staticClass:
                                  "el-icon-video-play fs150 ft-color",
                                on: {
                                  click: function ($event) {
                                    return _vm.openVideo(item)
                                  },
                                },
                              }),
                              _c("p", [_vm._v(_vm._s(item.cname))]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "padding10 border borderRadius10" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "borderBottom",
                          staticStyle: {
                            "padding-bottom": "5px",
                            "margin-bottom": "5px",
                          },
                        },
                        [
                          _c("span", { staticClass: "fs20" }, [
                            _vm._v(" 门店智控"),
                          ]),
                        ]
                      ),
                      _c(
                        "el-row",
                        { staticClass: "padding10" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("el-switch", {
                                attrs: { "inactive-text": "远程门禁" },
                                on: {
                                  change: function ($event) {
                                    return _vm.lampOrDoorSwitch(0)
                                  },
                                },
                                model: {
                                  value: _vm.AccessControl,
                                  callback: function ($$v) {
                                    _vm.AccessControl = $$v
                                  },
                                  expression: "AccessControl",
                                },
                              }),
                              _c("el-switch", {
                                attrs: { "inactive-text": "远程灯光" },
                                on: {
                                  change: function ($event) {
                                    return _vm.lampOrDoorSwitch(1)
                                  },
                                },
                                model: {
                                  value: _vm.lighting,
                                  callback: function ($$v) {
                                    _vm.lighting = $$v
                                  },
                                  expression: "lighting",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "h60 w150",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.activateInStore },
                                },
                                [_vm._v("到店模式")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "adding10",
                          staticStyle: { color: "#b5b4bf" },
                          attrs: { href: "#" },
                        },
                        [_vm._v("更多控制")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "监控视频",
            visible: _vm.videoDialog,
            width: "50%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoDialog = $event
            },
          },
        },
        [
          _vm.videoDialog
            ? _c("i-mou-video", {
                attrs: {
                  "device-id": _vm.deviceId,
                  "channel-id": "0",
                  pwd: _vm.pwd,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }