<template>
  <!-- 人工配送下单弹窗页面 -->
  <el-dialog class="elDialog" :close-on-click-modal="false" :title="title" :visible.sync="deliveryOrderVisible" width="700px" append-to-body @open="init" @close="onClose">
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="bg-purple">
          <el-form ref="form" label-position="left" label-width="auto" class="grey padding10">
            <el-form-item label="销售渠道">
              <p>{{ orderInfo.zplatOrder === 0 ? "美团" : orderInfo.zplatOrder === 1 ? "京东到家" : orderInfo.zplatOrder === 2 ? "饿了么" : "tops" }}</p>
            </el-form-item>
            <el-form-item label="商家实收">
              <p>{{ orderInfo.noriginPrice | parsePrice }}元</p>
            </el-form-item>
            <el-form-item label="订单状态">
              <p :class="[calculateTimeDifferenceInMinutes(orderInfo.tarriveReq).timeDifference > 0 ? 'overdue-time' : 'remaining-time']">
                {{ calculateTimeDifferenceInMinutes(orderInfo.tarriveReq).displayText }}
              </p>
            </el-form-item>
            <el-form-item label="收货地址">
              <p>{{ orderInfo.creceiveAddr }}</p>
            </el-form-item>
            <el-form-item label="预定人">
              <p>{{ orderInfo.ccustomerName }}</p>
            </el-form-item>
            <el-form-item label="手机号码">
              <p>{{ orderInfo.ccustomerPhone }}</p>
            </el-form-item>
            <el-form-item label="用户备注">
              <p>{{ orderInfo.corderRemark }}</p>
            </el-form-item>
            <!--            <el-form-item label="发单方式" class="input145px">-->
            <!--              <el-button size="mini" @click="isReserve = false">立即发单</el-button>-->
            <!--              <el-button size="mini" @click="isReserve = true">预定时间</el-button>-->
            <!--              <el-date-picker v-if="isReserve" v-model="formData.presetTime" size="mini" class="marginLeft5" type="datetime" placeholder="选择日期时间"></el-date-picker>-->
            <!--            </el-form-item>-->
            <el-form-item label="配送平台">
              <div class="delivery-platform-container">
                <el-popover v-if="deliveryPlatform.length > 0" placement="top-start" title="配送信息" width="330" trigger="click">
                  <div class="wHP">
                    <div class="textCenter marginBottom5" style="color: rgb(134, 144, 156)">会同步给顾客,不填默认门店电话</div>
                    <div class="marginBottom5">骑手姓名: <el-input v-model="other.name" class="marginLeft10" placeholder="请输入骑手姓名"></el-input></div>
                    <div class="marginBottom5">骑手电话: <el-input v-model="other.phone" class="marginLeft10" placeholder="请输入骑手电话"></el-input></div>
                    <div class="marginBottom5">配送金额: <el-input v-model="other.amount" class="marginLeft10" placeholder="请输入配送金额"></el-input></div>
                  </div>
                  <el-button slot="reference" :class="{ active: other.isActive }" class="marginTop10 h70 marginRight10 border" style="width: 170px" @click="selectOther">
                    <div class="marginBottom20">其他</div>
                    <div>需要填写骑手信息</div>
                  </el-button>
                </el-popover>
                <div v-for="(item, index) in deliveryPlatform" :key="index" :class="{ 'border delivery-block hover-color': true, active: item.isActive }" @click="selectDelivery(item)">
                  <div>{{ item.name }}</div>
                  <div>{{ item.amount }}{{ item.amount !== "" ? "元" : item.remarks }}</div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
      <el-button type="primary" plain @click="$emit('closeHandle')">取 消</el-button>
      <el-button type="primary" @click="sendDelivery">确 认</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "DeliveryOrder",
  filters: {
    time(value) {
      return moment(new Date(value)).format("YYYY-MM-DD HH:MM:SS");
    },
    parsePrice(price) {
      if (price === null || price === undefined || price === "null" || price === "undefined") {
        return 0;
      } else if (price === 0) {
        return 0;
      } else {
        return (price / 100).toFixed(2);
      }
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    orderId: {
      type: String,
      default: "",
    },
    deliveryOrderVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 列表数据
      orderInfo: {},
      //是否预定单
      isReserve: false,
      deliveryPlatform: [],
      formData: {
        presetTime: "",
      },
      //其他配送
      other: {
        name: "",
        phone: "",
        amount: "",
        isActive: false,
        logistic: "00",
        deliveryName: "其他",
      },
    };
  },
  methods: {
    selectOther() {
      this.clearSelection();
      this.other.isActive = !this.other.isActive;
    },
    init() {
      this.deliveryPlatform = [];
      // 先获取订单信息
      this.getOrderById();
    },
    /**
     * 计算超时时间
     * @param targetTime
     * @returns {{displayText: string, timeDifference: number}}
     */
    calculateTimeDifferenceInMinutes(targetTime) {
      let now = new Date();
      let target = new Date(parseInt(targetTime + "000"));
      // 获取两个时间戳之差（毫秒）
      let differenceInMilliseconds = now.getTime() - target.getTime();
      // 转换为分钟，不需要取绝对值，因为我们要保留正负号表示超时还是剩余
      let differenceInMinutes = Math.ceil(differenceInMilliseconds / (1000 * 60));
      return {
        timeDifference: differenceInMinutes,
        displayText: differenceInMinutes > 0 ? `超时 ${differenceInMinutes} 分钟` : `剩余 ${-differenceInMinutes} 分钟`,
      };
    },
    sendDelivery() {
      let url = `${this.baseUrls.mqdelivery}/add`;
      let pf = this.deliveryPlatform.filter((item) => item.isActive);
      if (pf === "" || pf === undefined) {
        if (this.other.isActive) {
          if (this.other.amount === "" && !(this.other.amount > 0)) {
            this.$message({
              message: "请输入配送金额",
              type: "warning",
            });
            return;
          }
          pf = this.other;
        } else {
          this.$message({ message: "请选择配送平台", type: "warning" });
          return;
        }
      }
      let params = {
        orderId: this.orderId,
        deliveryPlatform: pf,
        presetTime: this.formData.presetTime === "" ? this.formatDateAsCustom(new Date()) : this.formData.presetTime,
      };
      this.topsAjax.post(url, params).then((response) => {
        this.$notify({ title: response.code === 1 ? "成功" : "失败", message: response.message, type: response.code === 1 ? "success" : "error" });
        this.$emit("queryList");
        this.$emit("closeHandle");
      });
    },
    selectDelivery(item) {
      if (item.logistic === "0") {
        this.clearSelection();
      }
      this.other.isActive = false;
      if (item.isActive) {
        item.isActive = false;
      } else {
        item.isActive = true;
      }
    },
    getDeliveryPlatformList() {
      let url = `${this.baseUrls.mqdelivery}/getDeliveryPlatformList`;
      let params = {
        ordId: this.orderInfo.sid,
        storeId: this.orderInfo.rstore,
      };
      this.topsAjax.post(url, params).then((response) => {
        let array = response.data.detail;
        array.forEach((obj) => (obj.isActive = false));
        this.deliveryPlatform = array;
        this.deliveryPlatform.push({
          name: "自配送",
          amount: "",
          remarks: "同步门店联系电话",
          logistic: "0",
          isActive: false,
        });
        // 补齐平台列表空缺
        this.paddingBox(this.deliveryPlatform);
      });
    },
    getOrderById() {
      let url = `${this.baseUrls.third}/get/` + this.orderId;
      this.topsAjax.get(url).then((response) => {
        this.orderInfo = response.ordMain;
        console.log(this.orderInfo)
        //取配送平台列表
        this.getDeliveryPlatformList();
      });
    },
    clearSelection() {
      this.deliveryPlatform.forEach((platform) => (platform.isActive = false));
    },
    onClose() {
      this.$emit("closeHandle");
    },
    paddingBox(data) {
      let a = (data.length + 1) % 3;
      if (a !== 0) {
        a = a === 2 ? 1 : 2;
        for (let i = 0; i < a; i++) {
          data.push({
            deliveryName: "",
            amount: "",
            isActive: false,
          });
        }
      }
    },
    formatDateAsCustom(dateInput) {
      // 确保输入的是 Date 类型
      if (!(dateInput instanceof Date)) {
        throw new Error("The input should be an instance of Date.");
      }
      // 获取各个部分并格式化为两位数
      const year = dateInput.getFullYear();
      const month = ("0" + (dateInput.getMonth() + 1)).slice(-2);
      const day = ("0" + dateInput.getDate()).slice(-2);
      const hours = ("0" + dateInput.getHours()).slice(-2);
      const minutes = ("0" + dateInput.getMinutes()).slice(-2);
      const seconds = ("0" + dateInput.getSeconds()).slice(-2);

      // 组合格式化后的日期和时间
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style lang="scss">
.el-popover__title {
  text-align: center;
}

.input145px {
  .el-input {
    width: 145px !important;
  }
}
</style>
<style lang="scss" scoped>
.el-descriptions-item__content > p {
  color: black;
}

.el-form-item {
  margin-bottom: 0;
}

.el-form-item__content {
  display: block;
}

.delivery-platform-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.remaining-time {
  color: green;
}

.overdue-time {
  color: red;
}

.delivery-block {
  height: 70px;
  width: 30%;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.hover-color:hover {
  /* 鼠标悬停时的样式 */
  background-color: rgba(64, 158, 255, 0.21);
}

.active {
  background-color: rgba(64, 158, 255, 0.21);
}
</style>
