<template>
  <div class="container">
    <div class="container__top">
      <div class="indexTabs">
        <el-tabs v-model="tabs" class="marginLeft10">
          <el-tab-pane v-for="tab in tapArray" :key="tab.component" :label="tab.menuName" :name="tab.component" />
        </el-tabs>
        <el-input v-model="search" class="w300 marginLeftAuto" placeholder="手机、姓名、地址、订单号">
          <i slot="suffix" class="el-input__icon el-icon-search pointer" @click="onSearch"></i>
        </el-input>
        <el-button class="margin10" type="primary" @click="openCreateOrderDialog">创建订单</el-button>
      </div>
    </div>
    <div class="container__body">
      <OrderProcessing v-if="tabs === 'orderProcessing'" ref="orderProcessing" :search="search" />
      <OrderInquiry v-if="tabs === 'orderInquiry'" />
      <OrderWarning v-if="tabs === 'orderWarning'" />
      <OrderPicking v-if="tabs === 'orderPicking'" />
    </div>
    <el-dialog :close-on-click-modal="false" title="创建订单" :visible.sync="dialogVisible" width="860px">
      <OrderInfo v-if="dialogVisible" ref="orderInfo" @close-dialog="closeCreateOrderDialog" />
    </el-dialog>
  </div>
</template>

<script>
import store from "../../store";
import OrderInfo from "./orderInfo.vue";
import OrderInquiry from "./orderInquiry.vue";
import OrderWarning from "./orderWarning.vue";
import OrderProcessing from "./orderProcessing.vue";
import { EventBus } from "@/store/event-bus";
import OrderPicking from "@/views/order/orderPicking.vue";

export default {
  name: "Order",
  components: {
    OrderPicking,
    OrderInfo,
    OrderProcessing,
    OrderWarning,
    OrderInquiry,
  },
  data() {
    return {
      tabs: "",
      tapArray: "",
      search: "",
      dialogVisible: false,
    };
  },
  created() {
    const nav = JSON.parse(JSON.stringify(store.getters.navs));
    const result = nav.find((item) => item.component === "orderIndex");
    this.tapArray = result.children;
    this.tabs = this.tapArray[0].component;
    EventBus.$on("refreshPage", this.closeCreateOrderDialog);
  },
  beforeDestroy() {
    EventBus.$off("refreshPage", this.closeCreateOrderDialog);
  },
  methods: {
    openCreateOrderDialog() {
      this.dialogVisible = true;
    },
    closeCreateOrderDialog() {
      this.dialogVisible = false;
      this.$refs.orderProcessing.refreshPage();
    },
    onSearch() {
      this.$refs.orderProcessing.orderList();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  width: 100%;
  background-color: #f6f6f6;
  padding: 10px;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
  &__top {
    height: 70px;
    margin: 0 3px;
    margin-bottom: 10px;
  }
  &__body {
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
}

.el-dialog__header {
  border-bottom: 1px solid rgb(222, 222, 222);
}
.el-dialog__body {
  padding: 10px 20px;
}
.el-form-item--medium .el-form-item__content,
.el-form-item--medium .el-form-item__label {
  line-height: 30px;
}
.el-descriptions--medium:not(.is-bordered) .el-descriptions-item__cell {
  padding-top: 10px;
  border-bottom: 1px solid rgb(222, 222, 222);
}
.el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
.el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 0;
}
</style>
<style lang="scss"></style>
