<template>
  <!--  订单拣货-->
  <div class="OrderPicking">
    <div class="OrderPicking__left">
      <el-input v-model="filterText" placeholder="输入门店名称查询" class="wHP padding10"></el-input>
      <div class="flex1 scrollAuto scrollbar">
        <el-tree ref="tree" class="filter-tree" :data="storeList" :props="defaultProps" default-expand-all :filter-node-method="filterNode" @node-click="handleNodeClick"></el-tree>
      </div>
    </div>
    <div class="OrderPicking__right">
      <div class="fs20">订单拣货管理</div>
      <el-divider></el-divider>
      <div class="marginBottom10 flex flexSpaceBetween">
        <div class="flex alignItemsCenter border borderRadius5">
          <el-input v-model="input" clearable placeholder="门店名称/客户地址/备注/制单人" class="w300" @change="queryPageList">
            <i slot="suffix" class="el-input__icon el-icon-search pointer" @click="queryPageList"></i>
          </el-input>
          <!-- <el-popover placement="bottom-start" width="300" trigger="click">
            <div class="flex alignItemsCenter">
              模糊查询:
              <el-input v-model="input" clearable placeholder="请输入内容" class="w200 marginLeft10" @change="fuzzyQuery" />
            </div>
            <span slot="reference" class="icon iconfont margin10 pointer">&#xe60b;</span>
          </el-popover>

          <el-popover placement="bottom-start" width="450" trigger="click">
            <div>
              <div class="flex alignItemsCenter flexSpaceBetween">
                制单时间:
                <el-date-picker v-model="queryConditions.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
              </div>
              <div class="flex alignItemsCenter flexSpaceBetween marginTop10">
                单据状态:
                <el-select v-model="queryConditions.state" placeholder="请选择单据状态" @change="conditionalQuery">
                  <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
              <div class="pointer blue borderTop marginTop10 paddingTop10">
                <span slot="reference" class="icon iconfont" @click="clearConditions">&#xe635; <span>清除所有筛选条件</span></span>
              </div>
            </div>
            <span slot="reference" class="icon iconfont margin10 pointer">&#xe664;</span>
          </el-popover>
          <el-popover placement="bottom-start" width="200" trigger="click">
            <el-select v-model="screen" placeholder="请选择排序方式" @change="sortedQuery">
              <el-option v-for="item in screenOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <span slot="reference" class="icon iconfont margin10 pointer">&#xe663;</span>
          </el-popover>
          <el-popover placement="bottom-start" width="200" trigger="click">
            <el-select v-model="exportData" placeholder="请选择导出方式">
              <el-option v-for="item in exportOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <span slot="reference" class="icon iconfont margin10 pointer">&#xe662;</span>
          </el-popover> -->
        </div>
        <el-button type="primary" size="medium" @click="addPicking">拣货制单</el-button>
      </div>
      <div class="padding10 bcGrey">当前选中 0 项 <a class="blue" @click="toggleSelection">取消选择</a></div>
      <div class="flexGrow1">
        <el-table ref="multipleTable" height="500" :data="tableData">
          <el-table-column type="selection" align="center" width="55" />
          <el-table-column label="序号" align="center" type="index" width="55" />
          <el-table-column label="出货门店" align="left" header-align="center" prop="sstore" />
          <el-table-column label="客户地址" align="left" header-align="center" prop="ccustomerAddr" />
          <el-table-column label="检索单号" align="left" header-align="center" prop="sretrieval" />
          <el-table-column label="备注" align="center" prop="cremark" />
          <el-table-column label="状态" align="center" prop="state" width="70">
            <template slot-scope="scope">
              <span v-if="scope.row.zstate === 1" style="color: grey">{{ scope.row.zstate | orderPickingState }}</span>
              <span v-else-if="scope.row.zstate === 2" style="color: #409eff">{{ scope.row.zstate | orderPickingState }}</span>
              <span v-else-if="scope.row.zstate === 3" style="color: green">{{ scope.row.zstate | orderPickingState }}</span>
              <span v-else-if="scope.row.zstate === 4" style="color: red">{{ scope.row.zstate | orderPickingState }}</span>
              <span v-else-if="scope.row.zstate === 5" style="color: blue">{{ scope.row.zstate | orderPickingState }}</span>
              <span v-else>{{ scope.row.zstate | orderPickingState }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="制单人" align="center" prop="screate" width="70" /> -->
          <el-table-column label="制单人" align="center" width="70" >
              <template slot-scope="scope">
                  <span v-if="scope.row.supdate !== undefined && scope.row.supdate !== ''">{{ scope.row.supdate }}</span>
                  <span v-else>{{ scope.row.screate }}</span>
              </template>
          </el-table-column>
          <el-table-column label="制单时间" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.tcreate | time }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="left" header-align="center" fixed="right" width="160">
            <template slot-scope="scope">
              <el-button v-if="scope.row.zstate === 1" type="text" size="small" @click="updatePicking(scope.row.sid)">修改</el-button>
              <el-button v-if="scope.row.zstate === 1" type="text" size="small" class="red" @click="handleDelete(scope.row)">删除</el-button>
              <el-button v-if="scope.row.zstate === 2" type="text" size="small" @click="pickingInfo(scope.row.sid)">出货</el-button>
              <el-button v-if="scope.row.zstate === 2" type="text" size="small" style="color: rgb(234, 162, 148)" @click="handleBack(scope.row)">退回</el-button>
              <el-button v-if="scope.row.zstate === 2" type="text" size="small" class="red" @click="handleCancel(scope.row)">取消</el-button>
              <el-button v-if="scope.row.zstate === 3 || scope.row.zstate === 4 || scope.row.zstate === 5  || scope.row.zstate === 6" type="text" size="small" @click="pickingInfo(scope.row.sid)">详细</el-button>
              <!-- <el-button v-if="scope.row.zstate === 3" type="text" size="small" class="red" @click="checkDestroy(scope.row.sid)">核销</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="h50">
        <el-pagination :current-page="queryParams.currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="queryParams.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
    <!--    弹窗-->
    <el-dialog class="elDialog" :close-on-click-modal="false" :title="title" :visible.sync="addOrUpdatePickingDialogVisible" width="950px" append-to-body>
      <addPicking v-if="addOrUpdatePickingDialogVisible" :sid="checkSid" @handleClose="handleClose" @handleShow="handleShow" />
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="拣货单详情" :visible.sync="pickingInfoDialogVisible" width="950px" append-to-body>
      <pickingInfo v-if="pickingInfoDialogVisible" :sid="checkSid" @handleClose="handleClose" @handleUpdate="handleUpdate" />
    </el-dialog>
  </div>
</template>
<script>
import addPicking from "@/views/order/dialog/addPicking.vue";
import pickingInfo from "@/views/order/dialog/pickingInfo.vue";
import { orderPickingState } from "@/utils/order";
import { formatTime } from "@/utils/utils";

export default {
  name: "OrderPicking",
  components: { addPicking, pickingInfo },
  filters: {
    time(value) {
      return formatTime(value);
    },
    orderPickingState(value) {
      return orderPickingState(value);
    },
  },
  data() {
    return {
      //排序方式
      screen: "",
      screenOptions: [
        { value: "0", label: "默认排序" },
        { value: "1", label: "出库时间正序" },
        { value: "2", label: "出库时间倒序" },
      ],
      //导出方式
      exportData: "",
      exportOptions: [
        { value: "1", label: "导出全部记录" },
        { value: "2", label: "导出选中记录" },
        { value: "3", label: "按条件导出记录" },
      ],
      // 状态列表
      stateOptions: [
        { value: "0", label: "全部状态" },
        { value: "1", label: "制单中" },
        { value: "2", label: "待出货" },
        { value: "3", label: "已出货" },
        { value: "4", label: "已取消" },
        { value: "5", label: "部分核销" },
        { value: "6", label: "已核销" }
      ],
      //条件查询
      queryConditions: {
        time: "",
        state: "0",
      },
      //模糊查询
      input: "",
      // 左侧树形
      filterText: "",
      storeList: [{ cname: "全部门店" }],
      defaultProps: {
        children: "children",
        label: "cname",
      },
      // 分页
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        storeId: "",
      },
      // 表格
      tableData: [],
      // 弹窗
      addOrUpdatePickingDialogVisible: false,
      pickingInfoDialogVisible: false,
      title: "",
      // 选中id
      checkSid: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.selectStoreList();
    this.queryPageList();
  },
  methods: {
    toggleSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    handleClose() {
      this.addOrUpdatePickingDialogVisible = false;
      this.pickingInfoDialogVisible = false;
      this.queryPageList();
    },
    handleUpdate() {
      this.queryPageList();
      this.pickingInfoDialogVisible = false;
      this.addOrUpdatePickingDialogVisible = true;
    },
    /**
     * 添加拣货单
     */
    addPicking() {
      this.checkSid = "";
      this.title = "拣货单制单";
      this.addOrUpdatePickingDialogVisible = true;
    },
    /**
     * 修改拣货单
     */
    updatePicking(sid) {
      this.checkSid = "";
      this.title = "拣货单修改";
      this.addOrUpdatePickingDialogVisible = true;
      this.checkSid = sid;
    },
    /**
     * 拣货单详细
     */
    pickingInfo(id) {
      this.checkSid = "";
      this.pickingInfoDialogVisible = true;
      this.checkSid = id;
    },
    // 分页
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryPageList();
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val;
      this.queryPageList();
    },
    // 删除
    handleDelete(row) {
      this.$confirm("是否删除当前记录？")
        .then((_) => {
          this.topsAjax.post(`${this.baseUrls.ordPicking}/deleteById`, { sid: row.sid }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.queryPageList();
          });
        })
        .catch((_) => {});
    },
    // 退回
    handleBack(row) {
      this.$confirm("是否退回当前记录？")
        .then((_) => {
          this.topsAjax.post(`${this.baseUrls.ordPicking}/backById`, { sid: row.sid }).then((res) => {
            this.$message({
              message: "退回成功",
              type: "success",
            });
            this.queryPageList();
          });
        })
        .catch((_) => {});
    },
    // 取消
    handleCancel(row) {
      this.$confirm("是否取消当前记录？")
        .then((_) => {
          this.topsAjax.post(`${this.baseUrls.ordPicking}/cancelById`, { sid: row.sid }).then((res) => {
            this.$message({
              message: "取消成功",
              type: "success",
            });
            this.queryPageList();
          });
        })
        .catch((_) => {});
    },
    // 拣货单查询
    queryPageList() {
      this.input = this.input.trim();
      this.tableData = [];
      let url = `${this.baseUrls.ordPicking}/queryPageList`;
      let params = this.queryParams;
      params.search = this.input;
      // if (!(this.input === undefined || this.input == null || this.input === "")) {
      //   params.like = this.input;
      // } else {
      //   params.like = "";
      // }
      // if (!(this.screen === undefined || this.screen == null || this.screen === "")) {
      //   params.screen = this.screen;
      // } else {
      //   params.screen = "";
      // }
      // if (this.queryConditions.time.length > 0) {
      //   params.time = this.queryConditions.time;
      //   console.log(this.queryConditions.time[0]);
      //   console.log(this.queryConditions.time[1]);
      // } else {
      //   params.time = "";
      // }
      // if (!(this.queryConditions.state === undefined || this.queryConditions.state == null || this.queryConditions.state === "")) {
      //   params.state = this.queryConditions.state;
      // } else {
      //   params.state = "";
      // }
      this.topsAjax.get(url, params).then((res) => {
        this.queryParams.total = res.total;
        this.tableData = res.list;
      });
    },
    // 左侧门店列表
    selectStoreList() {
      let url = `${this.baseUrls.store}/queryList`;
      let params = {};
      this.topsAjax.get(url, params).then((res) => {
        this.storeList = this.storeList.concat(res);
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.cname.indexOf(value) !== -1;
    },
    handleNodeClick(node) {
      this.queryParams.currentPage = 1
      this.queryParams.pageSize = 10
      this.queryParams.total = 0
      this.queryParams.storeId = node.sid;
      this.queryPageList();
    },
    checkDestroy(sid) {
      this.$confirm("确定要核销吗？").then(() => {
        let url = `${this.baseUrls.ordPicking}/checkDestroy`;
        let parameters = {
          sid,
        };
        this.topsAjax.post(url, parameters).then((res) => {
          this.$message({
            message: "核销成功",
            type: "success",
          });
          this.queryPageList();
        });
      });
    },
    //模糊查询
    fuzzyQuery() {
      this.screen = "";
      this.clearConditions();
      this.queryPageList();
    },
    //清空条件
    clearConditions() {
      this.queryConditions = { time: "", state: "0" };
    },
    //条件查询
    conditionalQuery() {
      this.input = "";
      this.screen = "";
      this.queryPageList();
    },
    //排序查询
    sortedQuery() {
      this.input = "";
      this.clearConditions();
      this.queryPageList();
    },
    handleShow(sid) {
      this.addOrUpdatePickingDialogVisible = false;
      this.pickingInfoDialogVisible = false;
      this.tableData = [];
      let url = `${this.baseUrls.ordPicking}/queryPageList`;
      let params = this.queryParams;
      params.search = this.input;
      this.topsAjax.get(url, params).then((res) => {
        this.queryParams.total = res.total;
        this.tableData = res.list;
        this.checkSid = "";
        this.title = "拣货单修改";
        this.addOrUpdatePickingDialogVisible = true;
        this.checkSid = sid;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.OrderPicking {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  padding: 3px;

  .el-divider--horizontal {
    margin: 10px 0;
  }

  &__left {
    margin-right: 20px;
    width: 15%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 1px 1px 5px #888888;
    z-index: 100;
    position: relative;
  }

  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    box-shadow: 1px 1px 5px #888888;
    z-index: 100;
    position: relative;
    padding: 20px 10px;

    .el-radio-button__inner {
      width: auto;
    }
  }
}
</style>
