var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "elDialog my_dialog",
          attrs: {
            "custom-class": "customDialog",
            "close-on-click-modal": false,
            title: "客服中心",
            visible: _vm.dialogVisible,
            width: "700px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.init,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "history-head" }, [
                  _c("div", { staticClass: "history-head-title" }, [
                    _c(
                      "div",
                      {
                        staticClass: "history-head-left-title-text",
                        staticStyle: { color: "black" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.ordMain.ccustomerName) +
                            " ( " +
                            _vm._s(_vm.ordMain.ccustomerPhone) +
                            " )"
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "history-head-left-title-text" }, [
                      _vm._v(_vm._s(_vm.ordMain.sshop)),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    ref: "historyBody",
                    staticClass: "history-body h300 scrollbar im-container",
                  },
                  _vm._l(_vm.msgHistory, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "user-container" },
                      [
                        _c(
                          "div",
                          { staticClass: "user-send-time paddingTop10" },
                          [
                            _c("div", { staticClass: "send-time" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    index === 0
                                      ? _vm.compareFirstTimeStrings(
                                          _vm.msgHistory[index].tsend
                                        )
                                      : _vm.compareTimeStrings(
                                          _vm.msgHistory[index].tsend,
                                          _vm.msgHistory[index - 1].tsend
                                        )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            class:
                              item.zsource === 1
                                ? "user-content-right"
                                : "user-content-left",
                          },
                          [
                            _c("div", { staticClass: "user" }, [
                              _c("div", { staticClass: "user-name" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.zsource === 1 ? "商" : _vm.userName
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "msg" }, [
                              _c(
                                "div",
                                {
                                  class:
                                    item.zsource === 1
                                      ? "user-content-right"
                                      : "user-content-left",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        item.zsource === 1 ? "text-box" : "",
                                    },
                                    [
                                      item.ztype === 1
                                        ? _c("div", [
                                            _vm._v(_vm._s(item.ccontent)),
                                          ])
                                        : item.ztype === 2
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-image", {
                                                staticClass: "h100 w100",
                                                attrs: {
                                                  src: item.ccontent,
                                                  "preview-src-list": [
                                                    item.ccontent,
                                                  ],
                                                  fit: "fit",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _vm._v(_vm._s(item.ccontent)),
                                          ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "input marginTop5" },
                  [
                    _c("el-input", {
                      ref: "msgInput",
                      attrs: {
                        type: "textarea",
                        autofocus: true,
                        rows: 7,
                        resize: "none",
                        "show-word-limit": false,
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.enter.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.msgContent,
                        callback: function ($$v) {
                          _vm.msgContent = $$v
                        },
                        expression: "msgContent",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("el-row", { attrs: { type: "flex", justify: "end" } }, [
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "w100 marginTop10",
                    attrs: { type: "primary", size: "medium", round: "" },
                    on: { click: _vm.sendMsg },
                  },
                  [_vm._v("发 送")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }