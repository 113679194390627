<template>
  <!-- 订单查询 -->
  <div class="orderInquiry dialogSetting">
    <div class="orderInquiry__left scrollbar marginRight10">
      <div style="height: 100%; display: flex; flex-direction: column">
        <div class="bcWhite box-shadow padding10 marginBottom10">
          <el-select v-model="typeValue" placeholder="订单类型" class="marginRight10" @change="handleChangeType">
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <el-select v-model="tagValue" placeholder="订单标签" class="marginRight10" @change="handleChangeTag">
            <el-option v-for="item in tagOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <el-select v-model="deliveryValue" placeholder="订单来源" class="marginRight10" @change="handleSelectDelivery">
            <el-option v-for="item in deliveryOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <el-button type="primary" @click="reset">重置</el-button>
          <el-select v-model="storeValue" filterable placeholder="请选择门店" clearable class="marginRight10" @change="handleChangeStore">
            <el-option v-for="item in storeOptions" :key="item.sid" :label="item.cname" :value="item.sid"></el-option>
          </el-select>
          <el-select ref="sortSelect" v-model="sortingValue" class="marginRight10" placeholder="排序方式" @change="handleSelectSort">
            <el-option v-for="item in sortingOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-date-picker v-model="orderDate" class="marginTop10" align="right" type="daterange" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="handleChangeOrderDate" />
        </div>
        <div class="bcWhite scrollbar box-shadow" style="height: 100%">
          <no-data v-if="orderData.length === 0" class="empty frame" description="暂无数据"></no-data>
          <OrderList v-if="orderData.length > 0" :order-list-data="orderData" @selectOrderId="selectOrderId" />
        </div>
      </div>
    </div>
    <div class="orderInquiry__right flex">
      <div class="bcWhite box-shadow" style="flex: 1">
        <div v-if="ordId === '' || ordId === null" class="padding10 flex directionColumn">
          <div class="fs20 fWB marginTop10">数据总览</div>
          <el-divider></el-divider>
          <div>总订单{{ statisticalData.totalOrder }}单</div>
          <div>预计收入 {{ statisticalData.totalOrderPrice | parsePrice }}元</div>
          <el-divider></el-divider>
          <div>已完成订单 {{ statisticalData.completeOrder }} 单</div>
          <div>预计收入 {{ statisticalData.completeOrderPrice | parsePrice }}元</div>
          <el-divider></el-divider>
          <div>进行中订单 {{ statisticalData.ongoingOrder }} 单</div>
          <div>预计收入 {{ statisticalData.ongoingOrderPrice | parsePrice }}元</div>
          <el-divider></el-divider>
          <div>取消/退款订单 {{ statisticalData.refundOrder }} 单</div>
          <div>预计损失 {{ statisticalData.refundOrderPrice | parsePrice }}元</div>
          <el-divider></el-divider>

          <el-button type="success" @click="dialogVisible = true">导出订单到表格</el-button>
        </div>
        <div v-if="ordId !== '' && ordId !== null" class="hHP">
          <OrderListInfo v-if="ordId != null && ordId !== ''" :order-id="ordId" class="hHP bcWhite box-shadow" @refreshPage="refreshPage" />
        </div>
      </div>
    </div>
    <el-dialog title="报表中心" center append-to-body :top="'0vh'" :visible.sync="dialogVisible" width="55%" :before-close="handleClose">
      <div class="wHP marginTop10 flex1 export">
        <div class="marginBottom10">
          <div class="marginBottom10 fs17">筛选订单</div>
          <div>
            订单来源:
            <el-select v-model="source" placeholder="订单来源" class="marginRight10" @change="handleSelectDelivery">
              <el-option v-for="item in deliveryOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            查询店铺:
            <el-select v-model="shop" filterable placeholder="请选择门店" clearable class="marginRight10" @change="handleChangeStore">
              <el-option v-for="item in storeOptions" :key="item.sid" :label="item.cname" :value="item.sid"></el-option>
            </el-select>
          </div>
          <div>
            下单日期:
            <el-date-picker v-model="date" class="marginTop10" align="right" type="daterange" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="handleChangeOrderDate" />
          </div>
        </div>
        <div class="h400 scrollbar">
          <div class="marginBottom10 fs17">表格字段</div>
          <div class="flex marginBottom20">
            <div class="marginRight10">默认字段</div>
            <div>
              <div class="marginBottom5">
                <el-checkbox v-model="defaultInfo" class="marginLeft20 paddingRight20" disabled>流水号</el-checkbox>
                <el-checkbox v-model="defaultInfo" class="marginLeft20 paddingRight20" disabled>来源平台</el-checkbox>
                <el-checkbox v-model="defaultInfo" class="marginLeft20 paddingRight20" disabled>平台店铺</el-checkbox>
                <el-checkbox v-model="defaultInfo" class="marginLeft20 paddingRight20" disabled>状态</el-checkbox>
              </div>
              <div class="marginBottom5">
                <el-checkbox v-model="defaultInfo" class="marginLeft20 paddingRight20" disabled>原流水号</el-checkbox>
                <el-checkbox v-model="defaultInfo" class="marginLeft20 paddingRight20" disabled>订单编号</el-checkbox>
                <el-checkbox v-model="defaultInfo" class="marginLeft20 paddingRight20" disabled>是否预约</el-checkbox>
                <el-checkbox v-model="defaultInfo" class="marginLeft20 paddingRight20" disabled>下单日期</el-checkbox>
              </div>
              <div class="marginBottom5">
                <el-checkbox v-model="defaultInfo" class="marginLeft20 paddingRight20" disabled>期望送达</el-checkbox>
              </div>
            </div>
          </div>
          <div class="flex marginBottom20">
            <div class="marginRight10">基本信息</div>
            <div>
              <div class="marginBottom5">
                <el-checkbox v-model="basicCheckAll" class="marginLeft20 paddingRight20" :indeterminate="basicIndeterminate" @change="basicCheckAllChange">全选</el-checkbox>
              </div>
              <el-checkbox-group v-model="basicInfo">
                <div class="marginBottom5">
                  <el-checkbox label="备注" class="marginLeft20 paddingRight20">备注</el-checkbox>
                  <el-checkbox label="订单总金额" class="marginLeft20 paddingRight20">订单总金额</el-checkbox>
                  <el-checkbox label="商家实收金额" class="marginLeft20 paddingRight20"> 商家实收金额 </el-checkbox>
                  <el-checkbox label="用户实付金额" class="marginLeft20 paddingRight20"> 用户实付金额 </el-checkbox>
                </div>
                <div class="marginBottom5">
                  <el-checkbox label="用户实付配送金额" class="marginLeft20 paddingRight20">用户实付配送金额</el-checkbox>
                  <el-checkbox label="商品" class="marginLeft20 paddingRight20">商品</el-checkbox>
                  <el-checkbox label="数量" class="marginLeft20 paddingRight20">数量</el-checkbox>
                  <el-checkbox label="价格" class="marginLeft20 paddingRight20">价格</el-checkbox>
                </div>
                <div class="marginBottom5">
                  <el-checkbox label="取消时间" class="marginLeft20 paddingRight20">取消时间</el-checkbox>
                  <el-checkbox label="取消原因" class="marginLeft20 paddingRight20">取消原因</el-checkbox>
                  <el-checkbox label="渠道店铺ID" class="marginLeft20 paddingRight20">渠道店铺ID</el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
          <div class="flex marginBottom20">
            <div class="marginRight10">收货信息</div>
            <div>
              <div class="marginBottom5">
                <el-checkbox v-model="receiptsCheckAll" class="marginLeft20 paddingRight20" :indeterminate="receiptsIndeterminate" @change="receiptsCheckAllChange">全选</el-checkbox>
              </div>
              <el-checkbox-group v-model="receiptsInfo">
                <div class="marginBottom5">
                  <el-checkbox label="收货人" class="marginLeft20 paddingRight20">收货人</el-checkbox>
                  <el-checkbox label="收货人电话" class="marginLeft20 paddingRight20">收货人电话</el-checkbox>
                  <el-checkbox label="预订人电话" class="marginLeft20 paddingRight20">预订人电话</el-checkbox>
                  <el-checkbox label="地址" class="marginLeft20 paddingRight20">地址</el-checkbox>
                </div>
                <div class="marginBottom5">
                  <el-checkbox label="详细地址" class="marginLeft20 paddingRight20">详细地址 </el-checkbox>
                  <el-checkbox label="距离" class="marginLeft20 paddingRight20">距离</el-checkbox>
                  <el-checkbox label="新老顾客" class="marginLeft20 paddingRight20">新老顾客</el-checkbox>
                  <el-checkbox label="收藏顾客" class="marginLeft20 paddingRight20">收藏顾客</el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
          <div class="flex marginBottom20">
            <div class="marginRight10">配送信息</div>
            <div>
              <div class="marginBottom5">
                <el-checkbox v-model="deliveryCheckAll" class="marginLeft20 paddingRight20" :indeterminate="deliveryIndeterminate" @change="deliveryCheckAllChange">全选</el-checkbox>
              </div>
              <el-checkbox-group v-model="deliveryInfo">
                <div class="marginBottom5">
                  <el-checkbox label="预计发货时间" class="marginLeft20 paddingRight20">预计发货时间</el-checkbox>
                  <el-checkbox label="实际发货时间" class="marginLeft20 paddingRight20">实际发货时间</el-checkbox>
                  <el-checkbox label="完成时间" class="marginLeft20 paddingRight20">完成时间</el-checkbox>
                  <el-checkbox label="配送门店" class="marginLeft20 paddingRight20">配送门店</el-checkbox>
                </div>
                <div class="marginBottom5">
                  <el-checkbox label="配送平台" class="marginLeft20 paddingRight20">配送平台</el-checkbox>
                  <el-checkbox label="配送单号" class="marginLeft20 paddingRight20">配送单号</el-checkbox>
                  <el-checkbox label="骑手姓名" class="marginLeft20 paddingRight20">骑手姓名</el-checkbox>
                  <el-checkbox label="骑手电话" class="marginLeft20 paddingRight20">骑手电话</el-checkbox>
                </div>
                <div class="marginBottom5">
                  <el-checkbox label="配送费" class="marginLeft20 paddingRight20">配送费</el-checkbox>
                  <el-checkbox label="小费" class="marginLeft20 paddingRight20">小费</el-checkbox>
                  <el-checkbox label="配送状态" class="marginLeft20 paddingRight20">配送状态</el-checkbox>
                  <el-checkbox label="总配送费" class="marginLeft20 paddingRight20">总配送费</el-checkbox>
                </div>
                <div class="marginBottom5">
                  <el-checkbox label="配送距离" class="marginLeft20 paddingRight20">配送距离</el-checkbox>
                  <el-checkbox label="首次发单时间" class="marginLeft20 paddingRight20">首次发单时间</el-checkbox>
                  <el-checkbox label="首发完成时间" class="marginLeft20 paddingRight20">首发完成时间</el-checkbox>
                  <el-checkbox label="首次发单人" class="marginLeft20 paddingRight20">首次发单人</el-checkbox>
                </div>
                <div class="marginBottom5">
                  <el-checkbox label="配送备注" class="marginLeft20 paddingRight20">配送备注</el-checkbox>
                  <el-checkbox label="抢单时间" class="marginLeft20 paddingRight20">抢单时间</el-checkbox>
                  <el-checkbox label="到店时间" class="marginLeft20 paddingRight20">到店时间</el-checkbox>
                  <el-checkbox label="开始配送时间" class="marginLeft20 paddingRight20">开始配送时间</el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
          <div class="flex marginBottom20">
            <div class="marginRight10">其他信息</div>
            <div>
              <div class="marginBottom5">
                <el-checkbox v-model="otherCheckAll" class="marginLeft20 paddingRight20" :indeterminate="otherIndeterminate" @change="otherCheckAllChange">全选</el-checkbox>
              </div>
              <el-checkbox-group v-model="otherInfo">
                <div class="marginBottom5">
                  <el-checkbox label="内部备注" class="marginLeft20 paddingRight20">内部备注</el-checkbox>
                  <el-checkbox label="团购券码" class="marginLeft20 paddingRight20">团购券码</el-checkbox>
                  <el-checkbox label="蛋糕祝语" class="marginLeft20 paddingRight20">蛋糕祝语</el-checkbox>
                  <el-checkbox label="贺卡" class="marginLeft20 paddingRight20">贺卡</el-checkbox>
                </div>
                <div class="marginBottom5">
                  <el-checkbox label="蜡烛" class="marginLeft20 paddingRight20">蜡烛</el-checkbox>
                  <el-checkbox label="配件" class="marginLeft20 paddingRight20">配件</el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="marginLeftAuto marginRightAuto marginTop10">
          <el-button type="success" @click="exportData">导出表格</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import OrderListInfo from "./orderListInfo.vue";
import OrderList from "./orderList.vue";
import moment from "moment";
import NoData from "@/components/noData.vue";
import { importOrderTemplate } from "@/api/order/order";

export default {
  name: "OrderInquiry",
  components: {
    NoData,
    OrderList,
    OrderListInfo,
  },
  filters: {
    parsePrice(price) {
      if (price === null || price === undefined || price === "null" || price === "undefined") {
        return 0;
      } else if (price === 0) {
        return 0;
      } else {
        return (price / 100).toFixed(2);
      }
    },
  },
  props: {},
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      orderDate: "",
      //类型筛选
      typeOptions: [
        { value: "-1", label: "全部类型" },
        { value: "0", label: "已完成" },
        { value: "1", label: "及时单" },
        { value: "2", label: "预订单" },
        { value: "3", label: "未配送" },
        { value: "4", label: "取消/退款" },
        { value: "5", label: "已删除" },
      ],
      typeValue: "-1",
      //标签筛选
      tagOptions: [
        { value: "-1", label: "全部标签" },
        { value: "0", label: "配送超时" }, // 加字段
        { value: "1", label: "顾客自提" }, // 加字段
      ],
      tagValue: "-1",
      //渠道筛选
      deliveryOptions: [
        { value: "-1", label: "全部渠道" },
        { value: "0", label: "美团闪购" },
        { value: "1", label: "京东到家" },
        { value: "2", label: "饿了吗" },
        { value: "3", label: "其他订单" },
      ],
      deliveryValue: "-1",
      //门店筛选
      storeOptions: [{ sid: "-1", cname: "全部门店" }],
      storeValue: "-1",
      //排序方式
      sortingOptions: [
        { value: "0", label: "发单时间倒序", field: "t_create", indexType: 0 },
        { value: "1", label: "发单时间正序", field: "t_create", indexType: 1 },
        { value: "2", label: "商家实收倒序", field: "t_business_receipt", indexType: 0 },
        { value: "3", label: "商家实收正序", field: "t_business_receipt", indexType: 1 },
        { value: "4", label: "发货时间倒序", field: "t_send", indexType: 0 },
      ],
      sortingValue: "0",
      //订单列表数据
      queryParams: {},
      orderData: [],
      //数据总览
      statisticalData: "",
      ordId: "",
      //订单导出相关
      dialogVisible: false,
      source: "",
      shop: "",
      date: [],
      defaultInfo: true,
      basicInfo: [],
      basicCheckAll: false,
      basicIndeterminate: false,
      receiptsInfo: [],
      receiptsCheckAll: false,
      receiptsIndeterminate: false,
      deliveryInfo: [],
      deliveryCheckAll: false,
      deliveryIndeterminate: false,
      otherInfo: [],
      otherCheckAll: false,
      otherIndeterminate: false,
    };
  },
  created() {
    this.orderList();
    this.resetParams();
    this.queryShop();
    // this.dataOverview();
  },
  methods: {
    //基本信息全选
    basicCheckAllChange(val) {
      this.basicInfo = val ? ["备注", "订单总金额", "商家实收金额", "用户实付金额", "用户实付配送金额", "商品", "数量", "价格", "取消时间", "取消原因", "渠道店铺ID"] : [];
      this.basicIndeterminate = false;
    },
    //收货信息全选
    receiptsCheckAllChange(val) {
      this.receiptsInfo = val ? ["收货人", "收货人电话", "预订人电话", "地址", "详细地址", "距离", "新老顾客", "收藏顾客", "配送信息"] : [];
      this.receiptsIndeterminate = false;
    },
    //配送信息全选
    deliveryCheckAllChange(val) {
      this.deliveryInfo = val ? ["预计发货时间", "实际发货时间", "完成时间", "配送门店", "配送平台", "配送单号", "骑手姓名", "骑手电话", "配送费", "小费", "配送状态", "总配送费", "配送距离", "首次发单时间", "首发完成时间", "首次发单人", "配送备注", "抢单时间", "到店时间", "开始配送时间"] : [];
      this.deliveryIndeterminate = false;
    },
    //其他信息全选
    otherCheckAllChange(val) {
      this.otherInfo = val ? ["内部备注", "团购券码", "蛋糕祝语", "贺卡", "蜡烛", "配件"] : [];
      this.otherIndeterminate = false;
    },
    //导出
    exportData() {
      let params = {
        source: this.source === "" ? null : this.source,
        shop: this.shop === "" ? null : this.shop,
        date: this.date.length > 0 ? moment(this.date[0]).format("YYYY-MM-DD") + "," + moment(this.date[1]).format("YYYY-MM-DD") : null,
        basicInfo: this.basicInfo.length > 0 ? this.basicInfo : null,
        receiptsInfo: this.receiptsInfo.length > 0 ? this.receiptsInfo : null,
        deliveryInfo: this.deliveryInfo.length > 0 ? this.deliveryInfo : null,
        otherInfo: this.otherInfo.length > 0 ? this.otherInfo : null,
      };
      importOrderTemplate(params);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    refreshPage() {},
    selectOrderId(id) {
      this.ordId = id;
    },
    /**
     * 重置
     */
    reset() {
      this.typeValue = "-1";
      this.tagValue = "-1";
      this.deliveryValue = "-1";
      this.storeValue = "-1";
      this.orderDate = "";
      this.sortingValue = "0";
      this.ordId = "";
      this.queryParams = {};
      this.orderList();
    },
    // 查询所有商铺列表
    queryShop() {
      let url = `${this.baseUrls.order}/queryList`;
      let params = {
        bDelete: 0,
        bValid: 1,
      };
      this.topsAjax.get(url, params).then((res) => {
        this.storeOptions = this.storeOptions.concat(res);
      });
    },
    //统计
    count(res) {
      let orderStatistics = {};
      orderStatistics.totalOrder = res.length;
      orderStatistics.totalOrderPrice = res.reduce((total, order) => total + order.noriginPrice, 0);
      const completeOrder = res.filter((order) => order.zflow === 0);
      const refundOrder = res.filter((order) => order.zflow === 5);
      const ongoingOrder = res.filter((order) => order.zflow !== 0 && order.zflow !== 5);
      orderStatistics.completeOrder = completeOrder.length;
      orderStatistics.completeOrderPrice = completeOrder.reduce((total, order) => total + order.noriginPrice, 0);
      orderStatistics.ongoingOrder = ongoingOrder.length;
      orderStatistics.ongoingOrderPrice = ongoingOrder.reduce((total, order) => total + order.noriginPrice, 0);
      orderStatistics.refundOrder = refundOrder.length;
      orderStatistics.refundOrderPrice = refundOrder.reduce((total, order) => total + order.noriginPrice, 0);
      this.statisticalData = orderStatistics;
    },
    /**
     * 获取订单列表
     */
    async orderList() {
      this.orderData = [];
      let url = `${this.baseUrls.order}/queryList`;
      let params = this.queryParams;
      this.topsAjax.get(url, params).then((res) => {
        this.orderData = res;
        this.count(res);
      });
    },
    resetParams() {
      this.queryParams = {
        field: "t_create",
        indexType: 0,
        //日期选择
        orderDate: null,
        orderType: null,
        orderTag: null,
        //渠道选择
        zPlatOrder: null,
        zFlow: null,
      };
    },
    handleChangeType(val) {
      this.queryParams.orderType = val === "-1" ? null : val;
      this.orderList();
    },
    handleChangeTag(val) {
      this.queryParams.orderTag = val === "-1" ? null : val;
      this.orderList();
    },
    //排序方式选择
    handleSelectSort(val) {
      let o = this.sortingOptions[val];
      this.queryParams.field = o.field;
      this.queryParams.indexType = o.indexType;
      this.orderList();
    },
    //日期选择
    handleChangeOrderDate(value) {
      if (value !== null && value[0] !== null && value[1] !== null) {
        let stateTime = moment(value[0]).format("YYYY-MM-DD");
        let endTime = moment(value[1]).format("YYYY-MM-DD");
        this.queryParams.orderDate = stateTime + "," + endTime;
      } else {
        this.queryParams.orderDate = null;
      }
      this.orderList();
    },
    //渠道选择
    handleSelectDelivery(val) {
      this.queryParams.zPlatOrder = val === "-1" ? null : val;
      this.orderList();
    },
    //门店
    handleChangeStore(val) {
      this.queryParams.rShop = val === "-1" ? null : val;
      this.orderList();
    },
  },
};
</script>
<style lang="scss">
.export {
  .el-checkbox__label {
    width: 80px;
  }
}
.orderInquiry {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;

  &__left {
    padding: 3px;
    flex: 1;
    overflow: auto;
  }

  &__right {
    padding: 3px;
    width: 30%;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}
.dialogSetting {
  .el-dialog {
    margin: 0 auto 0;
    height: 100%;
  }
  .el-dialog--center .el-dialog__body {
    padding: 0 0 10px 30px;
  }
}
</style>
