<template>
  <!-- 订单预警 -->
  <div class="orderWarning">
    <div class="orderWarning__left scrollbar bcWhite box-shadow padding10">
      <div v-for="(card, innerIndex) in cardData" :key="innerIndex" @click="selectCard(card, innerIndex)">
        <el-card shadow="never" class="box-card marginBottom10" :class="{ 'is-selected': selectIndex === innerIndex }">
          <div class="fs12 marginBottom5">美团闪购</div>
          <div>{{ card.shopName }}</div>
        </el-card>
      </div>
    </div>
    <div class="orderWarning__right bcWhite box-shadow padding10">
      <div class="box-shadow marginBottom10 padding10" style="width: 35%">
        <div style="display: flex; justify-content: space-between; width: 100%; align-items: center">
          <span class="fs16 fWB">自配送状态回传率</span>
          <span class="fs12">2小时前 更新</span>
        </div>
        <div class="marginTop10 marginBottom10 flex">
          <div class="wFP marginRight10 padding10" style="background-color: rgb(255, 248, 244)">
            <div class="fs16 fWB">今日</div>
            <div class="selfConfiguredReturnRate">0.0%</div>
          </div>
          <div class="wFP marginLeft10 padding10" style="background-color: rgb(255, 248, 244)">
            <div class="fs16 fWB">昨日</div>
            <div class="selfConfiguredReturnRate">0.0%</div>
          </div>
        </div>
        <div class="fs12">美团外卖对"配送信息上传"行为进行规范,若自然周有效配送信息上传率 &lt; 90%的商家将对门店"调整配送范围"</div>
      </div>
      <el-radio-group v-model="radio">
        <el-radio-button label="未上传"></el-radio-button>
        <el-radio-button label="上传中"></el-radio-button>
        <el-radio-button label="上传失败"></el-radio-button>
        <el-radio-button label="上传成功"></el-radio-button>
      </el-radio-group>
      <div class="box-shadow marginTop10 scrollbar" style="flex: 1">
        <el-table :data="tableData" style="width: 100%" height="100%">
          <el-table-column :resizable="false" align="center" prop="type" label="订单来源" width="100">美团闪购</el-table-column>
          <el-table-column :resizable="false" align="center" prop="orderCode" label="流水号" width="100" />
          <el-table-column :resizable="false" align="center" prop="address" label="顾客信息">
            <template slot-scope="scope">
              <div>{{ scope.row.customerName + " " + scope.row.customerPhonePrivate }}</div>
              <div>{{ scope.row.shopName }}</div>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" align="center" prop="address" label="指标说明" />
          <el-table-column :resizable="false" align="center" label="操作" width="150">
            <template #default="scope">
              <el-button type="text" @click="infoPage(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import index from "vuex";

export default {
  name: "OrderWarning",
  props: {},
  data() {
    return {
      tableData: [],
      radio: "",
      cardData: [],
      shopData: [],
      selectIndex: "",
    };
  },
  computed: {
    index() {
      return index;
    },
  },
  created() {
    this.selectWarningOrder();
  },
  methods: {
    infoPage(sid) {},
    /**
     * 统计订单数
     */
    selectWarningOrder(shopId) {
      let params = {};
      if (shopId) params.shopId = shopId;
      let url = `${this.baseUrls.order}/selectWarningOrder`;
      this.topsAjax.get(url, params).then((res) => {
        if (shopId) {
          this.tableData = res;
        } else {
          const groupedData = res.reduce((acc, { shopName, shopId }) => {
            // 定义一个唯一的键用来检查是否已经有了这个组合
            const key = `${shopName}-${shopId}`;
            if (!acc[key]) {
              acc[key] = { shopName, shopId, count: 0 };
            }
            acc[key].count += 1; // 计算相同shopName和shopId的商品数量
            return acc;
          }, {});
          const result = Object.values(groupedData); // 转换为数组
          this.cardData = result;
        }
      });
    },
    /**
     * 选中卡片
     * @param index
     */
    selectCard(shop, index) {
      // 点击卡片时更新选中状态
      this.selectIndex = index;
      this.selectWarningOrder(shop.shopId);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.orderWarning {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  padding: 3px;
  &__left {
    margin-right: 20px;
    width: 25%;
    display: block;
    background-color: white;
  }
  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.selfConfiguredReturnRate {
  color: rgb(255, 115, 38);
  font-weight: bold;
  font-size: 30px;
}

.is-selected {
  /* 选中效果的样式 */
  border-color: #409eff;
}
</style>

<style lang="scss" scoped>
.el-radio-button__inner {
  width: 100px;
}
</style>
