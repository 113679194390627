var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OrderPicking" },
    [
      _c(
        "div",
        { staticClass: "OrderPicking__left" },
        [
          _c("el-input", {
            staticClass: "wHP padding10",
            attrs: { placeholder: "输入门店名称查询" },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
          _c(
            "div",
            { staticClass: "flex1 scrollAuto scrollbar" },
            [
              _c("el-tree", {
                ref: "tree",
                staticClass: "filter-tree",
                attrs: {
                  data: _vm.storeList,
                  props: _vm.defaultProps,
                  "default-expand-all": "",
                  "filter-node-method": _vm.filterNode,
                },
                on: { "node-click": _vm.handleNodeClick },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "OrderPicking__right" },
        [
          _c("div", { staticClass: "fs20" }, [_vm._v("订单拣货管理")]),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "marginBottom10 flex flexSpaceBetween" },
            [
              _c(
                "div",
                { staticClass: "flex alignItemsCenter border borderRadius5" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "w300",
                      attrs: {
                        clearable: "",
                        placeholder: "门店名称/客户地址/备注/制单人",
                      },
                      on: { change: _vm.queryPageList },
                      model: {
                        value: _vm.input,
                        callback: function ($$v) {
                          _vm.input = $$v
                        },
                        expression: "input",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search pointer",
                        attrs: { slot: "suffix" },
                        on: { click: _vm.queryPageList },
                        slot: "suffix",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.addPicking },
                },
                [_vm._v("拣货制单")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "padding10 bcGrey" }, [
            _vm._v("当前选中 0 项 "),
            _c(
              "a",
              { staticClass: "blue", on: { click: _vm.toggleSelection } },
              [_vm._v("取消选择")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "flexGrow1" },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  attrs: { height: "500", data: _vm.tableData },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", align: "center", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      align: "center",
                      type: "index",
                      width: "55",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "出货门店",
                      align: "left",
                      "header-align": "center",
                      prop: "sstore",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "客户地址",
                      align: "left",
                      "header-align": "center",
                      prop: "ccustomerAddr",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "检索单号",
                      align: "left",
                      "header-align": "center",
                      prop: "sretrieval",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "备注", align: "center", prop: "cremark" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      align: "center",
                      prop: "state",
                      width: "70",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.zstate === 1
                              ? _c("span", { staticStyle: { color: "grey" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderPickingState")(
                                        scope.row.zstate
                                      )
                                    )
                                  ),
                                ])
                              : scope.row.zstate === 2
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#409eff" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("orderPickingState")(
                                          scope.row.zstate
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : scope.row.zstate === 3
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "green" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("orderPickingState")(
                                          scope.row.zstate
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : scope.row.zstate === 4
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderPickingState")(
                                        scope.row.zstate
                                      )
                                    )
                                  ),
                                ])
                              : scope.row.zstate === 5
                              ? _c("span", { staticStyle: { color: "blue" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderPickingState")(
                                        scope.row.zstate
                                      )
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderPickingState")(
                                        scope.row.zstate
                                      )
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "制单人", align: "center", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.supdate !== undefined &&
                            scope.row.supdate !== ""
                              ? _c("span", [_vm._v(_vm._s(scope.row.supdate))])
                              : _c("span", [_vm._v(_vm._s(scope.row.screate))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "制单时间", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(_vm._f("time")(scope.row.tcreate))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "left",
                      "header-align": "center",
                      fixed: "right",
                      width: "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.zstate === 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updatePicking(scope.row.sid)
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                )
                              : _vm._e(),
                            scope.row.zstate === 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "red",
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                            scope.row.zstate === 2
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.pickingInfo(scope.row.sid)
                                      },
                                    },
                                  },
                                  [_vm._v("出货")]
                                )
                              : _vm._e(),
                            scope.row.zstate === 2
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      color: "rgb(234, 162, 148)",
                                    },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleBack(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("退回")]
                                )
                              : _vm._e(),
                            scope.row.zstate === 2
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "red",
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCancel(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                )
                              : _vm._e(),
                            scope.row.zstate === 3 ||
                            scope.row.zstate === 4 ||
                            scope.row.zstate === 5 ||
                            scope.row.zstate === 6
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.pickingInfo(scope.row.sid)
                                      },
                                    },
                                  },
                                  [_vm._v("详细")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "h50" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryParams.currentPage,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.queryParams.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.queryParams.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.addOrUpdatePickingDialogVisible,
            width: "950px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrUpdatePickingDialogVisible = $event
            },
          },
        },
        [
          _vm.addOrUpdatePickingDialogVisible
            ? _c("addPicking", {
                attrs: { sid: _vm.checkSid },
                on: {
                  handleClose: _vm.handleClose,
                  handleShow: _vm.handleShow,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "拣货单详情",
            visible: _vm.pickingInfoDialogVisible,
            width: "950px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.pickingInfoDialogVisible = $event
            },
          },
        },
        [
          _vm.pickingInfoDialogVisible
            ? _c("pickingInfo", {
                attrs: { sid: _vm.checkSid },
                on: {
                  handleClose: _vm.handleClose,
                  handleUpdate: _vm.handleUpdate,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }