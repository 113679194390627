<template>
  <!--  查看路线-->
  <div class="getStoreLocation">
    <div id="container" class="hHP wHP" />
  </div>
</template>

<script>
export default {
  name: "GetStoreLocation",
  props: {
    deliveryId: {
      type: [String, Object, Array],
      default: "",
    },
  },
  data() {
    return {
      deliveryInfo: {},
      orderElemeInfo: {},
      distance: null
    };
  },
  mounted() {
    this.getDeliveryInfo(this.deliveryId);
  },
  methods: {
    getDeliveryInfo(id) {
      let url = `${this.baseUrls.mqdelivery}/location/` + id;
      this.topsAjax.get(url).then((res) => {
        this.deliveryInfo = res.location;
        console.log(this.deliveryInfo)
        this.orderElemeInfo = res.eleme;
        this.distance = res.distance
        this.getStoreLocation();
      });
    },
    getStoreLocation() {
      var map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: 15,
        center: [this.deliveryInfo.rider_longitude, this.deliveryInfo.rider_latitude],
      });
      var marker = new AMap.Marker({
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
        position: [this.orderElemeInfo.caddrLongitude, this.orderElemeInfo.caddrLatitude],
        anchor: "bottom-center",
      });
      var position = [this.deliveryInfo.rider_longitude, this.deliveryInfo.rider_latitude];

      //构建自定义信息窗体
      var infoWindow = new AMap.InfoWindow({
        anchor: "top-left",
        content: `骑手距离顾客：${this.distance/1000}公里`,
      });
      infoWindow.open(map, position);
      new AMap.Marker({ position: position, map });
      map.add(marker);
      // map.setFitView();
      let params = {
        deliveryId: this.deliveryId,
      };
      let url = `${this.baseUrls.mqdelivery}/location/sync`;
      this.topsAjax.get(url, params).then((res) => {
        
      });
    },
  },
};
</script>

<style lang="scss">
.getStoreLocation {
  height: 400px;
}
</style>
