<template>
  <!-- 添加拣货单-->
  <div class="AddPicking">
    <div class="AddPicking__top">
      <el-descriptions :column="3">
        <el-descriptions-item label="系统单号">
          <div>{{ pickingData.rsys }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="检索单号">
          <el-input v-model="pickingData.retrieval" placeholder="请输入内容(选填)" />
        </el-descriptions-item>
        <el-descriptions-item label="订单号">
          <el-input v-model="pickingData.order" placeholder="请输入内容(选填)" />
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="2">
        <el-descriptions-item label="出货门店">
          <el-select v-model="storeInfo.cname" filterable placeholder="请选择" :filter-method="selectByStoreName" @change="checkStore">
            <el-option v-for="item in storeOptions" :key="item.sid" :label="item.cname" :value="item" />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="门店地址">
          <!--          <el-tooltip class="item" effect="dark" :content="storeInfo.caddr" placement="top">-->
          <div class="wHP">{{ storeInfo.caddr }}</div>
          <!--          </el-tooltip>-->
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div class="flex alignItemsCenter">
        <el-descriptions :column="1">
          <el-descriptions-item label="顾客地址">
            <el-input v-model="pickingData.customerAddr" placeholder="请输入内容" />
          </el-descriptions-item>
        </el-descriptions>
        <span class="icon iconfont grey">&#xe675;</span>
      </div>
      <el-divider></el-divider>
      <div class="flex">
        <el-descriptions :column="1">
          <el-descriptions-item label="单据备注">
            <el-input v-model="pickingData.remarks" placeholder="请输入内容(选填)" />
          </el-descriptions-item>
        </el-descriptions>
        <div class="w200 flex" style="justify-content: flex-end">
          <el-button v-if="pickingId !== ''" type="primary" size="mini" plain @click="open">重新选取</el-button>
          <el-button type="primary" size="mini" plain @click="openDeviceDialog">门店智控</el-button>
        </div>
      </div>
    </div>
    <div class="h450 AddPicking__table">
      <el-table ref="multipleTable" :data="tableData" height="450">
        <el-table-column label="序号" align="center" width="50" type="index" />
        <el-table-column align="center" label="SKU" width="90">
          <template #default="scope">
            <el-input v-model="scope.row.c_barcode" maxlength="5" placeholder="SKU" @input="checkSKU(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="图片" align="center" width="70">
          <template #default="scope">
            <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="货品编码" align="center" width="130" prop="c_sku" />
        <el-table-column label="货品名称" align="center">
          <template #default="scope">
            <el-tooltip class="item" effect="dark" :disabled="!scope.row.c_name" :content="scope.row.c_name" placement="top">
              <el-select v-model="scope.row.c_name" style="color: red" filterable placeholder="请选择" :filter-method="(value) => selectByGoodsName(scope.row, value)" @change="checkGoodsName(scope.row)">
                <el-option v-for="item in options" :key="item.iSid" :label="item.label" :value="item.c_name" />
              </el-select>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="单位" align="center" width="50" />
        <el-table-column label="单价" align="center" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.n_price">￥ {{ scope.row.n_price.toFixed(2) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="库位" align="center" width="60" prop="c_num" />
        <el-table-column v-if="pickingId !== ''" label="可用库存" align="center" width="60">
          <template slot-scope="scope">
            {{ formatSurplus(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="数量" align="center" width="130">
          <template #default="scope">
            <!--            <el-input-number  v-model="scope.row.num" controls-position="right" :min="1" :max="999"></el-input-number>-->
            <el-input-number v-if="scope.row.c_barcode || scope.row.c_name" v-model="scope.row.num" controls-position="right" :min="1" :max="999"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right" width="55">
          <template slot-scope="scope">
            <el-button type="text" size="small" class="red" @click="onDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex alignItemsCenter marginTop10" style="justify-content: space-between; flex: 1">
      <div>
        共计 <span class="blue">{{ tableData.length > 1 ? tableData.length - 1 : tableData.length }}种</span> 货品
      </div>
      <div>
        <el-button v-if="pickingId !== ''" type="primary" plain @click="doSubmit(1)">保存</el-button>
        <el-button type="primary" @click="doSubmit(2)">提交</el-button>
      </div>
    </div>
    <inventOrderGoodsAdd ref="orderGoodsAdd" :goods="[]" :select-store="{ rstore: storeInfo.sid }" :max-num="true" @OrderGoodsAdd="AddGoods" />
    <el-dialog v-if="deviceDialogVisible" class="elDialog" :close-on-click-modal="false" title="查看门店智控" :visible.sync="deviceDialogVisible" width="360px" append-to-body>
      <div class="hHP flex directionColumn marginLeft10 marginRight10">
        <div class="padding10 border borderRadius10 marginBottom10">
          <div class="fs13">{{ storeInfo.cname }}</div>
          <a class="purple fs13">{{ storeInfo.caddr }}</a>
        </div>
        <div class="padding10 border borderRadius10 marginBottom10 hHP">
          <div class="borderBottom" style="padding-bottom: 5px; margin-bottom: 5px">
            <span class="fs20">门店监控</span>
          </div>
          <div class="flex directionColumn">
            <div v-for="(item, index) in videoList" :key="index" class="flex alignItemsCenter directionColumn">
              <a class="el-icon-video-play fs150 ft-color" @click="openVideo(item)" />
              <p>{{ item.cname }}</p>
            </div>
          </div>
        </div>
        <div class="padding10 border borderRadius10">
          <div class="borderBottom" style="padding-bottom: 5px; margin-bottom: 5px">
            <span class="fs20"> 门店智控</span>
          </div>
          <el-row class="padding10">
            <el-col :span="12">
              <el-switch v-model="AccessControl" inactive-text="远程门禁" @change="lampOrDoorSwitch(0)" />
              <el-switch v-model="lighting" inactive-text="远程灯光" @change="lampOrDoorSwitch(1)" />
            </el-col>
            <el-col :span="12">
              <el-button type="primary" class="h60 w150" @click="activateInStore">到店模式</el-button>
            </el-col>
          </el-row>
          <a href="#" class="adding10" style="color: #b5b4bf">更多控制</a>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="videoDialog" :close-on-click-modal="false" title="监控视频" :visible.sync="videoDialog" width="50%" append-to-body>
      <i-mou-video v-if="videoDialog" :device-id="deviceId" channel-id="0" :pwd="pwd" />
    </el-dialog>
  </div>
</template>
<script>
import iMouVideo from "@/components/ImouVideo/iMouVideo.vue";
import inventOrderGoodsAdd from "@/views/store/inventoryOrder/inventOrderGoodsAdd.vue";
import { determineExists } from "@/utils/utils";
import { lowerFirst } from "lodash/string";

export default {
  name: "AddPicking",
  components: { iMouVideo, inventOrderGoodsAdd },
  props: {
    sid: {
      type: [String, Object, Array],
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      //商品列表
      options: [],
      // 表格列表
      tableData: [],
      transferData: [],
      // 门店信息
      storeInfo: {},
      storeOptions: [],
      pickingData: {
        rsys: "",
        retrieval: "",
        order: "",
        customerAddr: "",
        remarks: "",
      },
      // 修改信息
      pickingId: "",
      // 智控弹窗
      deviceDialogVisible: false,
      videoList: [],
      hostList: [],
      deviceId: "",
      pwd: "",
      videoDialog: false,
      btnLoading: false,
      AccessControl: false,
      lighting: false,
    };
  },
  computed: {},
  created() {
    if (this.sid) {
      this.pickingId = this.sid;
      this.selectById();
    }
  },
  methods: {
    formatSurplus(row) {
      console.log(row)
      return row.n_surplus < 0 ? 0 : row.n_surplus;
    },
    openDeviceDialog() {
      if (this.storeInfo.sid) {
        this.deviceDialogVisible = true;
      } else {
        this.$message.error("请选择门店！");
      }
    },
    activateInStore() {
      let url = `${this.baseUrls.device}/activateInStore`;
      this.topsAjax.post(url, { sid: this.storeInfo.sid, breachMode: true }).then((res) => {
        // this.inStoreMode = "success";
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    lampOrDoorSwitch(type) {
      let params = {
        storeId: this.storeInfo.sid,
        type: type,
        control: type === 0 ? this.AccessControl : this.lighting,
      };
      let url = `${this.baseUrls.device}/lampOrDoorSwitch`;
      this.topsAjax.post(url, params).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    storeDevice(id) {
      let url = `${this.baseUrls.store}/getStoreDevice/` + id;
      this.topsAjax.get(url).then((response) => {
        this.btnLoading = false;
        this.videoList = [];
        this.hostList = [];
        let tempHost = [];
        for (let i = 0; i < response.deviceList.length; i++) {
          let o = response.deviceList[i];
          if (o.btype === 4) {
            this.videoList.push(o);
          } else {
            tempHost.push(o);
          }
        }
        tempHost.forEach((d) => {
          d.terminalList.forEach((t) => {
            let o = {
              ccode: d.ccode,
              cname: t.cname,
              cterminalId: t.cterminalId,
              bstatus: t.bstatus,
            };
            this.hostList.push(o);
          });
        });
        //TODO 临时处理 再视频没调好之前不显示数据
        // this.videoList = [];
      });
    },
    openVideo(item) {
      this.deviceId = item.ccode;
      this.pwd = item.cpwd;
      this.videoDialog = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    open() {
      // this.transferData = this.tableData.slice();
      // this.transferData.pop();
      // this.dialogVisible = true;
      let arr = [];
      for (let i = 0; i < this.tableData.length; i++) {
        let json = this.tableData[i];
        if (Object.keys(json).length > 0 && json.goodsId) {
          arr.push({ ...this.tableData[i] });
        }
      }
      this.$refs.orderGoodsAdd.setTableData(arr);
    },
    AddGoods(goodsList) {
      this.dialogVisible = false;
      if (goodsList.length > 0) {
        this.tableData = goodsList;
        this.tableData.push({});
      }
    },
    checkSKU(value) {
      let sku = value.c_barcode;
      if (sku.length === 0) {
        if (this.tableData.length !== 1) {
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].c_barcode === "") {
              this.tableData.splice(i, 1);
              break;
            }
          }
          let json = this.tableData[this.tableData.length - 1];
          if (Object.keys(json).length > 0) {
            this.tableData.push({});
          }
        }
      } else if (sku.length === 5) {
        // 判断是否重复1
        const filteredItems = this.tableData.filter((item) => {
          if (Object.keys(item).length === 0 || !determineExists(item.c_barcode)) {
            return false;
          }
          return item.c_barcode.toUpperCase() === sku.toUpperCase();
        });
        if (filteredItems.length > 1) {
          this.$message.error("存在重复商品");
          value.c_barcode = "";
          return;
        }
        //回显数据
        let url = `${this.baseUrls.inventory}/queryBySKU`;
        let parameters = {
          rstore: this.storeInfo.sid,
          csku: sku,
        };
        this.topsAjax
          .post(url, parameters)
          .then((res) => {
            if (res === undefined) {
              this.$message.error("未获取到商品信息");
              this.setData(value, res);
            } else {
              if (!res.gSid) {
                this.$message.error("未获取到商品信息");
              }
              this.setData(value, res);
            }
          })
          .catch((res) => {
            res.c_barcode = sku;
            this.setData(value, res);
          });
      }
    },
    // 添加空白列表
    pushTableData() {
      let lastObject = null;
      if (this.tableData.length > 0) {
        lastObject = this.tableData[this.tableData.length - 1];
        if (determineExists(lastObject.c_name) || determineExists(lastObject.sid) || determineExists(lastObject.c_barcode)) {
          this.tableData.push({});
        }
      }
    },
    selectById() {
      let url = `${this.baseUrls.ordPicking}/selectById`;
      let params = { sid: this.pickingId };
      this.topsAjax.get(url, params).then((res) => {
        let pickingInfo = res.picking;
        if (pickingInfo.sretrieval) this.pickingData.retrieval = pickingInfo.sretrieval;
        if (pickingInfo.rorder) this.pickingData.order = pickingInfo.rorder;
        if (pickingInfo.cremark) this.pickingData.remarks = pickingInfo.cremark;
        if (pickingInfo.ccustomerAddr) this.pickingData.customerAddr = pickingInfo.ccustomerAddr;
        if (pickingInfo.rsys) this.pickingData.rsys = pickingInfo.rsys;
        this.storeInfo.sid = pickingInfo.rstore;
        this.storeInfo.cname = pickingInfo.sstore;
        this.storeInfo.caddr = pickingInfo.sstoreAddr;
        this.tableData = res.pickingDetailList;
        this.tableData.push({});
        this.storeDevice(pickingInfo.rstore);
      });
    },
    selectByGoodsName(row, value) {
      // row.c_name = value;
      if (value.length > 0) {
        let url = `${this.baseUrls.inventory}/queryByName`;
        let parameters = {
          rstore: this.storeInfo.sid,
          csku: value,
        };
        this.topsAjax.post(url, parameters).then((res) => {
          this.options = res;
          if (this.options.length === 0) {
            // Object.keys(row).forEach((key) => {
            //   this.$set(row, key, null);
            // });
            // 设置新值
            // this.$set(row, "num", 1);
            // this.$set(row, "c_name", value);
            // this.pushTableData();
            // this.pushTableData();
          }
        });
      } else {
        this.options = [];
      }
    },
    selectByStoreName(value) {
      if (value.length > 0) {
        let url = `${this.baseUrls.store}/queryList`;
        let stoStore = {
          cName: value,
        };
        this.topsAjax.get(url, stoStore).then((res) => {
          this.storeOptions = res;
        });
      } else {
        this.storeOptions = [];
      }
    },
    setData(value, data) {
      this.$set(value, "inventoryId", data.iSid);
      this.$set(value, "goodsId", data.gSid);
      this.$set(value, "c_master_url", data.c_master_url);
      this.$set(value, "c_barcode", data.c_barcode);
      this.$set(value, "c_sku", data.c_sku);
      this.$set(value, "c_num", data.c_warehouse);
      this.$set(value, "c_name", data.c_name);
      this.$set(value, "n_price", data.n_price);
      this.$set(value, "n_surplus", data.n_surplus);
      this.$set(value, "num", 1);
      this.$set(value, "disabled", true);
      if (this.tableData.length === 0) {
        this.tableData.push({});
      } else {
        let json = this.tableData[this.tableData.length - 1];
        if (Object.keys(json).length > 0) {
          this.tableData.push({});
        }
      }
    },
    checkGoodsName(row) {
      const filteredItems = this.tableData.filter((item) => item.c_name === row.c_name);
      if (filteredItems.length > 1) {
        this.$message.error("存在重复商品");
        this.selectValue = "";
        return;
      }
      for (let i = 0; i < this.options.length; i++) {
        if (row.c_name === this.options[i].c_name) {
          this.setData(row, this.options[i]);
        }
      }
      this.options = [];
    },
    checkStore(value) {
      this.storeInfo = value;
      this.storeOptions = [];
      this.tableData = [{}];
      this.storeDevice(value.sid);
    },
    onDel(row) {
      const index = this.tableData.indexOf(row);
      if (index + 1 === this.tableData.length) return;
      if (index !== -1) {
        this.tableData.splice(index, 1);
      }
    },
    doSubmit: _.debounce(function (state) {
      this.addOrUpdate(state);
    }, 500),
    addOrUpdate(state) {
      if (!this.storeInfo.sid) {
        this.$message.error("请选择门店!");
        return;
      }
      let goodsList = [];
      for (let i = 0; i < this.tableData.length; i++) {
        let json = this.tableData[i];
        if (determineExists(json.c_name) || determineExists(json.c_barcode)) {
          if (!(json.num > 0)) {
            this.$message.error("请输入数量");
            return;
          }
          goodsList.push(this.tableData[i]);
        }
      }
      if (!(goodsList.length > 0)) {
        this.$message.error("请选择商品!");
        return;
      }
      let url;
      if (this.pickingId === "") {
        url = `${this.baseUrls.ordPicking}/add`;
      } else {
        if (state === 2) {
          for (let i = 0; i < goodsList.length; i++) {
            if (!goodsList[i].goodsId) {
              let sku = goodsList[i].c_barcode ? goodsList[i].c_barcode : "";
              this.$message.error("存在未知SKU" + sku + "!");
              return;
            }
            if (goodsList[i].num > goodsList[i].n_surplus) {
              this.$message.error("库存不足，请检查商品数量是否大于可用库存!");
              return;
            }
            if (!goodsList[i].c_name) {
              let sku = goodsList[i].c_barcode ? goodsList[i].c_barcode : "";
              this.$message.error(sku + "货品名称不能为空!");
              return;
            }
          }
        }
        url = `${this.baseUrls.ordPicking}/updatePickingDetail`;
      }
      let parameter = {
        picking: {
          sid: this.pickingId,
          sretrieval: this.pickingData.retrieval,
          rorder: this.pickingData.order,
          rstore: this.storeInfo.sid,
          sstore: this.storeInfo.cname,
          sstoreAddr: this.storeInfo.caddr,
          ccustomerAddr: this.pickingData.customerAddr,
          cremark: this.pickingData.remarks,
          zstate: state,
        },
        goodsList: goodsList,
      };
      this.topsAjax.post(url, parameter).then((res) => {
        // if (res.code === 200) {
        this.$message({
          showClose: true,
          message: this.pickingId === "" ? "新增成功" : "修改成功",
          type: "success",
        });
        if (this.pickingId === "") {
          if (res.zState && res.zState === "1") {
            this.$emit("handleShow", res.sid);
          } else {
            this.cancel();
          }
        } else {
          this.cancel();
        }
      });
    },
    /**
     * 取消
     */
    cancel() {
      this.$emit("handleClose");
    },
  },
};
</script>
<style lang="scss">
.AddPicking {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__top {
    .el-divider--horizontal {
      margin: 7px 0;
    }

    .el-descriptions--medium:not(.is-bordered) .el-descriptions-item__cell {
      padding-bottom: 5px;
    }

    .el-input--medium .el-input__inner {
      height: 20px;
      line-height: 20px;
    }

    .el-input__inner {
      border: 0px;
    }

    //隐藏图标
    .el-input__icon {
      display: none;
    }
  }

  &__table {
    .el-input-number--medium {
      width: 100px;
    }

    .el-tabs__item.is-active {
      text-decoration: underline;
    }

    .el-popper[x-placement^="bottom"] {
      margin-left: -40px;
    }
  }
}
</style>
