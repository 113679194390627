var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "elDialog",
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.deliveryOrderVisible,
        width: "700px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.deliveryOrderVisible = $event
        },
        open: _vm.init,
        close: _vm.onClose,
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "bg-purple" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "grey padding10",
                    attrs: { "label-position": "left", "label-width": "auto" },
                  },
                  [
                    _c("el-form-item", { attrs: { label: "销售渠道" } }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.orderInfo.zplatOrder === 0
                              ? "美团"
                              : _vm.orderInfo.zplatOrder === 1
                              ? "京东到家"
                              : _vm.orderInfo.zplatOrder === 2
                              ? "饿了么"
                              : "tops"
                          )
                        ),
                      ]),
                    ]),
                    _c("el-form-item", { attrs: { label: "商家实收" } }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm._f("parsePrice")(_vm.orderInfo.noriginPrice)
                          ) + "元"
                        ),
                      ]),
                    ]),
                    _c("el-form-item", { attrs: { label: "订单状态" } }, [
                      _c(
                        "p",
                        {
                          class: [
                            _vm.calculateTimeDifferenceInMinutes(
                              _vm.orderInfo.tarriveReq
                            ).timeDifference > 0
                              ? "overdue-time"
                              : "remaining-time",
                          ],
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.calculateTimeDifferenceInMinutes(
                                  _vm.orderInfo.tarriveReq
                                ).displayText
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("el-form-item", { attrs: { label: "收货地址" } }, [
                      _c("p", [_vm._v(_vm._s(_vm.orderInfo.creceiveAddr))]),
                    ]),
                    _c("el-form-item", { attrs: { label: "预定人" } }, [
                      _c("p", [_vm._v(_vm._s(_vm.orderInfo.ccustomerName))]),
                    ]),
                    _c("el-form-item", { attrs: { label: "手机号码" } }, [
                      _c("p", [_vm._v(_vm._s(_vm.orderInfo.ccustomerPhone))]),
                    ]),
                    _c("el-form-item", { attrs: { label: "用户备注" } }, [
                      _c("p", [_vm._v(_vm._s(_vm.orderInfo.corderRemark))]),
                    ]),
                    _c("el-form-item", { attrs: { label: "配送平台" } }, [
                      _c(
                        "div",
                        { staticClass: "delivery-platform-container" },
                        [
                          _vm.deliveryPlatform.length > 0
                            ? _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top-start",
                                    title: "配送信息",
                                    width: "330",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "wHP" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textCenter marginBottom5",
                                        staticStyle: {
                                          color: "rgb(134, 144, 156)",
                                        },
                                      },
                                      [_vm._v("会同步给顾客,不填默认门店电话")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marginBottom5" },
                                      [
                                        _vm._v("骑手姓名: "),
                                        _c("el-input", {
                                          staticClass: "marginLeft10",
                                          attrs: {
                                            placeholder: "请输入骑手姓名",
                                          },
                                          model: {
                                            value: _vm.other.name,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.other, "name", $$v)
                                            },
                                            expression: "other.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marginBottom5" },
                                      [
                                        _vm._v("骑手电话: "),
                                        _c("el-input", {
                                          staticClass: "marginLeft10",
                                          attrs: {
                                            placeholder: "请输入骑手电话",
                                          },
                                          model: {
                                            value: _vm.other.phone,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.other, "phone", $$v)
                                            },
                                            expression: "other.phone",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marginBottom5" },
                                      [
                                        _vm._v("配送金额: "),
                                        _c("el-input", {
                                          staticClass: "marginLeft10",
                                          attrs: {
                                            placeholder: "请输入配送金额",
                                          },
                                          model: {
                                            value: _vm.other.amount,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.other, "amount", $$v)
                                            },
                                            expression: "other.amount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass:
                                        "marginTop10 h70 marginRight10 border",
                                      class: { active: _vm.other.isActive },
                                      staticStyle: { width: "170px" },
                                      attrs: { slot: "reference" },
                                      on: { click: _vm.selectOther },
                                      slot: "reference",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "marginBottom20" },
                                        [_vm._v("其他")]
                                      ),
                                      _c("div", [_vm._v("需要填写骑手信息")]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(_vm.deliveryPlatform, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                class: {
                                  "border delivery-block hover-color": true,
                                  active: item.isActive,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectDelivery(item)
                                  },
                                },
                              },
                              [
                                _c("div", [_vm._v(_vm._s(item.name))]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(item.amount) +
                                      _vm._s(
                                        item.amount !== "" ? "元" : item.remarks
                                      )
                                  ),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("closeHandle")
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.sendDelivery } },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }