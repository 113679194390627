let orderConstant = {
  // 订单流转状态：1-新订单  2-异常订单 3-催单 4-配送异常 5-取消/退款 6-待配送 7-待抢单 8-待取货 9-配送中 0-已完成
  // orderCountMap: [
  //   { z_flow: 1, s_flow: "新订单", n_count: 0 },
  //   { z_flow: 2, s_flow: "异常订单", n_count: 0 },
  //   { z_flow: 3, s_flow: "催单", n_count: 0 },
  //   { z_flow: 4, s_flow: "配送异常", n_count: 0 },
  //   { z_flow: 5, s_flow: "取消/退款", n_count: 0 },
  //   { z_flow: 6, s_flow: "待配送", n_count: 0 },
  //   { z_flow: 7, s_flow: "待抢单", n_count: 0 },
  //   { z_flow: 8, s_flow: "待取货", n_count: 0 },
  //   { z_flow: 9, s_flow: "配送中", n_count: 0 },
  //   { z_flow: 0, s_flow: "已完成", n_count: 0 },
  //   { z_flow: 10, s_flow: "预订单", n_count: 0 },
  //   { z_flow: 11, s_flow: "待处理", n_count: 0 },
  // ],

  orderCountMap: [
    { z_flow: 1, s_flow: "新订单", n_count: 0 },
    { z_flow: 2, s_flow: "异常订单", n_count: 0 },
    { z_flow: 3, s_flow: "催单", n_count: 0 },
    { z_flow: 4, s_flow: "配送异常", n_count: 0 },
    { z_flow: 5, s_flow: "取消/退款", n_count: 0 },
    { z_flow: 6, s_flow: "待配送", n_count: 0 },
    { z_flow: 7, s_flow: "待抢单", n_count: 0 },
    { z_flow: 8, s_flow: "待取货", n_count: 0 },
    { z_flow: 9, s_flow: "配送中", n_count: 0 },
    { z_flow: 0, s_flow: "已完成", n_count: 0 },
    { z_flow: 10, s_flow: "待处理", n_count: 0 },
    { z_flow: 11, s_flow: "待接单", n_count: 0 },
    { z_flow: 12, s_flow: "预留", n_count: 0 },
  ],
};

export default orderConstant;
