<template>
  <!-- im聊天弹窗页面 -->
  <div>
    <el-dialog custom-class="customDialog" class="elDialog my_dialog" :close-on-click-modal="false" title="客服中心" :visible.sync="dialogVisible" width="700px" append-to-body @open="init">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="history-head">
            <div class="history-head-title">
              <div class="history-head-left-title-text" style="color: black">{{ ordMain.ccustomerName }} ( {{ ordMain.ccustomerPhone }} )</div>
              <!--              <div class="history-head-left-title-text">{{ ordMain.sshop }} ({{ ordMain.sstore }})</div>-->
              <div class="history-head-left-title-text">{{ ordMain.sshop }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <div ref="historyBody" class="history-body h300 scrollbar im-container">
            <div v-for="(item, index) in msgHistory" :key="index" class="user-container">
              <div class="user-send-time paddingTop10">
                <div class="send-time">
                  {{ index === 0 ? compareFirstTimeStrings(msgHistory[index].tsend) : compareTimeStrings(msgHistory[index].tsend, msgHistory[index - 1].tsend) }}
                </div>
              </div>
              <div :class="item.zsource === 1 ? 'user-content-right' : 'user-content-left'">
                <div class="user">
                  <div class="user-name">
                    {{ item.zsource === 1 ? "商" : userName }}
                  </div>
                </div>
                <div class="msg">
                  <div :class="item.zsource === 1 ? 'user-content-right' : 'user-content-left'">
                    <div :class="item.zsource === 1 ? 'text-box' : ''">
                      <div v-if="item.ztype === 1">{{ item.ccontent }}</div>
                      <div v-else-if="item.ztype === 2">
                        <el-image :src="item.ccontent" :preview-src-list="[item.ccontent]" class="h100 w100" fit="fit"></el-image>
                      </div>
                      <div v-else>{{ item.ccontent }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="input marginTop5">
            <el-input ref="msgInput" v-model="msgContent" type="textarea" :autofocus="true" :rows="7" resize="none" :show-word-limit="false" @keyup.enter.native="enter"></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="end">
        <div class="footer">
          <el-button class="w100 marginTop10" type="primary" size="medium" round @click="sendMsg">发 送</el-button>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/store/event-bus";

export default {
  name: "Im",
  props: {
    orderId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //是否预定单
      isReserve: false,
      ordMain: {},
      dialogVisible: false,
      deliveryPlatform: [],
      msgContent: "",
      userName: "",
      msgHistory: [],
      socket: null,
    };
  },
  methods: {
    init() {
      this.initWebSocket();
      this.msgContent = "";
      this.msgHistory = [];
      this.getOrderById();
      // 获取历史记录
      this.getImHistory();
      // 确保 DOM 更新后聚焦输入框
      this.$nextTick(() => {
        this.$refs.msgInput.focus();
      });
    },
    initWebSocket() {
      let ws = `${this.baseUrls.ws}/im/` + this.orderId;
      this.socket = new WebSocket(ws);
      this.socket.onopen = this.socketopen;
      this.socket.onerror = this.error;
      this.socket.onmessage = this.getMessage;
      this.socket.close = this.close;
    },
    enter() {
      //判断内容是否为空 若为空则提示 并重置光标和内容
      if (this.msgContent === "\n") {
        let inputElement = this.$refs.msgInput.$el.querySelector("textarea");
        inputElement.setSelectionRange(0, 0); // 将光标置于第一行的第一个字符处
        this.msgContent = "";
        this.$message({
          message: "请输入内容",
          type: "warning",
        });
      } else {
        this.sendMsg();
      }
    },
    setReadStatus() {
      let url = `${this.baseUrls.orderMain}/third/im/updateMsgReadStatus/` + this.orderId;
      this.topsAjax.get(url).then((response) => {
        if (response > 0) {
          this.msgUnReadCount = 0;
        }
      });
    },
    sendMsg() {
      let url = `${this.baseUrls.mqorderIm}/saveAndSend`;
      let content = this.msgContent.replace(/[\r\n]+$/, "");
      let params = {
        orderId: this.orderId,
        msgContent: content,
      };
      this.topsAjax.post(url, params).then((response) => {
        this.msgHistory = response;
        this.rollToBottom();
      });
      this.msgContent = "";
    },
    pushData(data) {
      this.$emit("pushData", data);
    },
    rollToBottom() {
      // 让滚动条始终在最底部
      this.$nextTick(() => {
        this.$refs.historyBody.scrollTop = this.$refs.historyBody.scrollHeight;
      });
    },
    getImHistory() {
      let url = `${this.baseUrls.mqorderIm}/list`;
      let params = {
        rorder: this.orderId,
      };
      this.topsAjax.get(url, params).then((response) => {
        this.msgHistory = response;
        this.rollToBottom();
      });
    },
    getOrderById() {
      let url = `${this.baseUrls.third}/get/` + this.orderId;
      this.topsAjax.get(url).then((response) => {
        this.ordMain = response.ordMain;
        this.userName = this.ordMain.ccustomerName.substring(0, 1);
      });
    },
    formatDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    open() {
      this.dialogVisible = true;
    },
    compareFirstTimeStrings(t1) {
      let pt1 = new Date(parseInt(t1));
      return this.formatDateTime(pt1);
    },
    compareTimeStrings(t1, t2) {
      let pt1 = new Date(parseInt(t1));
      let pt2 = new Date(parseInt(t2));

      let d = Math.abs(pt1 - pt2);
      let differenceInMinutes = Math.round(d / 1000 / 60);

      if (differenceInMinutes < 30) {
        return "";
      } else {
        const maxTime = differenceInMinutes > pt1 ? pt2 : pt1;
        return this.formatDateTime(maxTime);
      }
    },
    // 连接成功方法
    socketopen() {
      console.log("im连接成功");
    },
    // 连接错误
    error(data) {
      console.log("连接错误");
      console.log(data);
    },
    // 接受信息接口
    getMessage(message) {
      console.log("收到消息");
      this.getImHistory();
      if (this.dialogVisible) {
        this.setReadStatus();
      }
      //当窗口打开时，不推送消息
      if (!this.dialogVisible) {
        this.pushData(JSON.parse(message.data));
      }
    },
    // 关闭处理
    close() {
      console.log("连接关闭");
    },
  },
};
</script>

<style lang="scss" scoped>
.im-container {
  .history-head {
    min-height: 50px;
    background: #ecf3f8;
    border-bottom: 1px solid #dfe6ec;
  }

  .customDialog > .el-dialog__body {
    padding: 0;
  }

  .history-body {
    min-height: 300px;
    background: #f7f8fa;
    border-bottom: 1px solid #dfe6ec;
  }
  .input {
    min-height: 150px;
    border-bottom: 1px solid #dfe6ec;
  }
  .footer {
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .history-head-title {
    margin-left: 10px;
  }
  .user-content-left {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
  }
  .user-content-right {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 10px;
  }
  .text-box {
    background-color: #e2eff7;
  }
  .user {
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #86909c;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user-name {
    color: white;
    font-size: 13px;
  }
  .msg {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .user-send-time {
    display: flex;
    justify-content: center;
  }
}
</style>
