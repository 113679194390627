var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddPicking" },
    [
      _c(
        "div",
        { staticClass: "AddPicking__top" },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 3 } },
            [
              _c("el-descriptions-item", { attrs: { label: "系统单号" } }, [
                _c("div", [_vm._v(_vm._s(_vm.pickingData.rsys))]),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "检索单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容(选填)" },
                    model: {
                      value: _vm.pickingData.retrieval,
                      callback: function ($$v) {
                        _vm.$set(_vm.pickingData, "retrieval", $$v)
                      },
                      expression: "pickingData.retrieval",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容(选填)" },
                    model: {
                      value: _vm.pickingData.order,
                      callback: function ($$v) {
                        _vm.$set(_vm.pickingData, "order", $$v)
                      },
                      expression: "pickingData.order",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-descriptions",
            { attrs: { column: 2 } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "出货门店" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择",
                        "filter-method": _vm.selectByStoreName,
                      },
                      on: { change: _vm.checkStore },
                      model: {
                        value: _vm.storeInfo.cname,
                        callback: function ($$v) {
                          _vm.$set(_vm.storeInfo, "cname", $$v)
                        },
                        expression: "storeInfo.cname",
                      },
                    },
                    _vm._l(_vm.storeOptions, function (item) {
                      return _c("el-option", {
                        key: item.sid,
                        attrs: { label: item.cname, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "门店地址" } }, [
                _c("div", { staticClass: "wHP" }, [
                  _vm._v(_vm._s(_vm.storeInfo.caddr)),
                ]),
              ]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "flex alignItemsCenter" },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "顾客地址" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.pickingData.customerAddr,
                          callback: function ($$v) {
                            _vm.$set(_vm.pickingData, "customerAddr", $$v)
                          },
                          expression: "pickingData.customerAddr",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("span", { staticClass: "icon iconfont grey" }, [_vm._v("")]),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-descriptions",
                { attrs: { column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "单据备注" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容(选填)" },
                        model: {
                          value: _vm.pickingData.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.pickingData, "remarks", $$v)
                          },
                          expression: "pickingData.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "w200 flex",
                  staticStyle: { "justify-content": "flex-end" },
                },
                [
                  _vm.pickingId !== ""
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini", plain: "" },
                          on: { click: _vm.open },
                        },
                        [_vm._v("重新选取")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini", plain: "" },
                      on: { click: _vm.openDeviceDialog },
                    },
                    [_vm._v("门店智控")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "h450 AddPicking__table" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: { data: _vm.tableData, height: "450" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  align: "center",
                  width: "50",
                  type: "index",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "SKU", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { maxlength: "5", placeholder: "SKU" },
                          on: {
                            input: function ($event) {
                              return _vm.checkSKU(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.c_barcode,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "c_barcode", $$v)
                            },
                            expression: "scope.row.c_barcode",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "图片", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.c_master_url != null
                          ? _c("el-image", {
                              staticClass: "w50 h50",
                              attrs: {
                                "preview-src-list": [scope.row.c_master_url],
                                src: scope.row.c_master_url,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "货品编码",
                  align: "center",
                  width: "130",
                  prop: "c_sku",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "货品名称", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              disabled: !scope.row.c_name,
                              content: scope.row.c_name,
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { color: "red" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                  "filter-method": (value) =>
                                    _vm.selectByGoodsName(scope.row, value),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkGoodsName(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.c_name,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "c_name", $$v)
                                  },
                                  expression: "scope.row.c_name",
                                },
                              },
                              _vm._l(_vm.options, function (item) {
                                return _c("el-option", {
                                  key: item.iSid,
                                  attrs: {
                                    label: item.label,
                                    value: item.c_name,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "单位", align: "center", width: "50" },
              }),
              _c("el-table-column", {
                attrs: { label: "单价", align: "center", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.n_price
                          ? _c("div", [
                              _vm._v(
                                "￥ " + _vm._s(scope.row.n_price.toFixed(2))
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库位",
                  align: "center",
                  width: "60",
                  prop: "c_num",
                },
              }),
              _vm.pickingId !== ""
                ? _c("el-table-column", {
                    attrs: { label: "可用库存", align: "center", width: "60" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.formatSurplus(scope.row)) + " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2552983775
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "数量", align: "center", width: "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.c_barcode || scope.row.c_name
                          ? _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                min: 1,
                                max: 999,
                              },
                              model: {
                                value: scope.row.num,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "num", $$v)
                                },
                                expression: "scope.row.num",
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  fixed: "right",
                  width: "55",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "red",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDel(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex alignItemsCenter marginTop10",
          staticStyle: { "justify-content": "space-between", flex: "1" },
        },
        [
          _c("div", [
            _vm._v(" 共计 "),
            _c("span", { staticClass: "blue" }, [
              _vm._v(
                _vm._s(
                  _vm.tableData.length > 1
                    ? _vm.tableData.length - 1
                    : _vm.tableData.length
                ) + "种"
              ),
            ]),
            _vm._v(" 货品 "),
          ]),
          _c(
            "div",
            [
              _vm.pickingId !== ""
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.doSubmit(1)
                        },
                      },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.doSubmit(2)
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("inventOrderGoodsAdd", {
        ref: "orderGoodsAdd",
        attrs: {
          goods: [],
          "select-store": { rstore: _vm.storeInfo.sid },
          "max-num": true,
        },
        on: { OrderGoodsAdd: _vm.AddGoods },
      }),
      _vm.deviceDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "elDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "查看门店智控",
                visible: _vm.deviceDialogVisible,
                width: "360px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.deviceDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "hHP flex directionColumn marginLeft10 marginRight10",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "padding10 border borderRadius10 marginBottom10",
                    },
                    [
                      _c("div", { staticClass: "fs13" }, [
                        _vm._v(_vm._s(_vm.storeInfo.cname)),
                      ]),
                      _c("a", { staticClass: "purple fs13" }, [
                        _vm._v(_vm._s(_vm.storeInfo.caddr)),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "padding10 border borderRadius10 marginBottom10 hHP",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "borderBottom",
                          staticStyle: {
                            "padding-bottom": "5px",
                            "margin-bottom": "5px",
                          },
                        },
                        [
                          _c("span", { staticClass: "fs20" }, [
                            _vm._v("门店监控"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex directionColumn" },
                        _vm._l(_vm.videoList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "flex alignItemsCenter directionColumn",
                            },
                            [
                              _c("a", {
                                staticClass:
                                  "el-icon-video-play fs150 ft-color",
                                on: {
                                  click: function ($event) {
                                    return _vm.openVideo(item)
                                  },
                                },
                              }),
                              _c("p", [_vm._v(_vm._s(item.cname))]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "padding10 border borderRadius10" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "borderBottom",
                          staticStyle: {
                            "padding-bottom": "5px",
                            "margin-bottom": "5px",
                          },
                        },
                        [
                          _c("span", { staticClass: "fs20" }, [
                            _vm._v(" 门店智控"),
                          ]),
                        ]
                      ),
                      _c(
                        "el-row",
                        { staticClass: "padding10" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("el-switch", {
                                attrs: { "inactive-text": "远程门禁" },
                                on: {
                                  change: function ($event) {
                                    return _vm.lampOrDoorSwitch(0)
                                  },
                                },
                                model: {
                                  value: _vm.AccessControl,
                                  callback: function ($$v) {
                                    _vm.AccessControl = $$v
                                  },
                                  expression: "AccessControl",
                                },
                              }),
                              _c("el-switch", {
                                attrs: { "inactive-text": "远程灯光" },
                                on: {
                                  change: function ($event) {
                                    return _vm.lampOrDoorSwitch(1)
                                  },
                                },
                                model: {
                                  value: _vm.lighting,
                                  callback: function ($$v) {
                                    _vm.lighting = $$v
                                  },
                                  expression: "lighting",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "h60 w150",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.activateInStore },
                                },
                                [_vm._v("到店模式")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "adding10",
                          staticStyle: { color: "#b5b4bf" },
                          attrs: { href: "#" },
                        },
                        [_vm._v("更多控制")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "监控视频",
            visible: _vm.videoDialog,
            width: "50%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoDialog = $event
            },
          },
        },
        [
          _vm.videoDialog
            ? _c("i-mou-video", {
                attrs: {
                  "device-id": _vm.deviceId,
                  "channel-id": "0",
                  pwd: _vm.pwd,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }