<template>
  <!-- 出库单详情-->
  <div class="goodsLocation">
    <!--    <div class="marginLeftAuto marginBottom10">-->
    <!--      &lt;!&ndash;      <span class="marginRight20"> 出库类型:<span class="marginLeft10">订单出库</span></span>&ndash;&gt;-->
    <!--      <span class="marginRight20"-->
    <!--        >出库单号:<span class="marginLeft10">{{ inventoryOutCode === undefined ? "" : inventoryOutCode }}</span></span-->
    <!--      >-->
    <!--    </div>-->
    <el-descriptions>
      <el-descriptions-item label="出库单号">{{ inventoryOrderOut === undefined ? "" : inventoryOrderOut.ccode }}</el-descriptions-item>
      <el-descriptions-item label="订单编号">{{ order.ccode }}</el-descriptions-item>
      <el-descriptions-item label="下单时间">{{ order.tcreate | time }}</el-descriptions-item>
      <el-descriptions-item label="要求送达">{{ order.tarriveReq }}</el-descriptions-item>
      <el-descriptions-item label="预订人">{{ order.ccustomerName }}</el-descriptions-item>
      <el-descriptions-item label="预定电话">{{ order.ccustomerPhone }}</el-descriptions-item>
      <el-descriptions-item label="隐私号码">{{ order.cun }}</el-descriptions-item>
      <el-descriptions-item label="收货地址">{{ order.ccustomerAddr }}</el-descriptions-item>
      <el-descriptions-item label="配送门店">{{ order.sshop }}</el-descriptions-item>
    </el-descriptions>
    <div class="marginBottom10">
      共计 {{ goodsList.length }} 件货品 合计：<span class="fs16">{{ order.noriginPrice | parsePrice }}</span> 元
    </div>
    <div>
      <el-table ref="product" border :data="goodsList" max-height="400">
        <el-table-column align="center" type="index" label="序号" width="70" />
        <el-table-column align="center" prop="ordGoodsSku" label="货品SKU" width="170"></el-table-column>
        <el-table-column align="center" label="图片" width="90">
          <template #default="scope">
            <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ordGoodsName" label="货品名称"></el-table-column>
        <el-table-column align="center" prop="warehouse" label="所在库位" width="100"></el-table-column>
        <el-table-column align="center" prop="ordGoodsNum" label="数量" width="70"></el-table-column>
        <el-table-column align="center" prop="unit" label="单位" width="70"></el-table-column>
        <!--        <el-table-column align="center" prop="remark" label="备注" width="100"></el-table-column>-->
      </el-table>
    </div>
    <el-row type="flex" justify="end">
      <el-button v-if="isConfirm" class="marginTop10" type="primary" @click="confirm">确认出库</el-button>
    </el-row>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "GoodsLocation",
  filters: {
    parsePrice(price) {
      if (price === null || price === undefined || price === "null" || price === "undefined") {
        return 0;
      } else if (price === 0) {
        return 0;
      } else {
        return (price / 100).toFixed(2);
      }
    },
    time(value) {
      return moment(new Date(value)).format("YYYY-MM-DD HH:MM:SS");
    },
  },
  props: {
    goodsList: {
      type: [Array, Object, Number],
    },
    order: {
      type: Object,
    },
    inventoryOrderOut: {
      type: Object,
    },
    showBtn: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      isConfirm: false,
      tableData: [],
      totalAmount: "",
    };
  },
  created() {
    this.getStatus();
  },
  methods: {
    confirm() {
      this.topsAjax.post(`${this.baseUrls.repInventoryOrderOut}/approved`, { InventoryOrderOutId: this.inventoryOrderOut.sid }).then((response) => {
        let mes = "出库失败";
        if (response === 200) {
          mes = "出库成功";
        }
        this.$message({
          message: mes,
          type: "success",
        });
        this.$emit("closeDialog");
      });
    },
    getStatus() {
      this.topsAjax.post(`${this.baseUrls.repInventoryOrderOut}/getByOrderId`, this.order.sid).then((response) => {
        if (response.paudit === 1) {
          this.isConfirm = true;
        } else {
          this.isConfirm = false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.goodsLocation {
  display: flex;
  flex-direction: column;
}
</style>
