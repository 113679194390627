<template>
  <!-- 订单列表组件-->
  <div class="box-shadow orderList">
    <div v-for="(order, index) in orderData" :key="index" @click="selectCard(order, index)">
      <el-card shadow="hover" :class="{ 'is-selected': selectIndex === index, ' box-shadow': selectIndex === index, marginBottom10: 1 }" body-style="background-color: white;">
        <div slot="header" class="clearfix flex marginBottom10" style="align-items: flex-end">
          <span v-if="order.zflow === 5 && order.zrefund !== 0" class="iconfont grey" :class="{ blue: selectIndex === index }" style="font-size: 35px">{{ "&#xe668;" }}</span>
          <span v-else style="height: 35px; width: 10px" />
          <span :style="setColor(order.zplatOrder)">{{ order.zplatOrder | zPlatOrder }}</span>
          <span class="marginLeft5 blue fs19"> {{ order.corderCode }}</span>
          <span v-if="order.zflow !== 3 && order.zflow !== 5 && order.zflow !== 8 && order.zflow !== 9 && order.zflow !== 0" class="marginLeft5 grey">
            {{ order.bdeliveryDelay === 0 ? "立即送达," : "延迟配送," }}
            <span class="marginLeft5 red"> {{ calculateOrderPlacementTime(order.tcreate) }}</span>
            <span class="marginLeft5 grey">下单</span>
          </span>
          <span v-if="order.zflow === 0" class="marginLeft5">
            <span class="grey">{{ topsUtils.parseTime(order.tupdate) }} 已完成</span>
          </span>
          <span v-if="order.zflow === 3" class="marginLeft5">
            <!--            {{ order.zplatOrder }}-->
            <span class="fWB" style="color: rgb(217, 0, 27)">{{ order.tarriveReq | compareDate }}</span>
            <span class="fWB" style="color: rgb(217, 0, 27)">{{ order.tarriveReq === undefined ? "" : order.tarriveReq.slice(-8) }}</span>
            <span class="grey">前送达</span>
            <span v-if="compareDateTime(order.tarriveReq)" class="grey"
              >, 已超时
              <span class="fWB" style="color: rgb(217, 0, 27)">{{ getTimeDifferenceInMinutes(order.tarriveReq) }}分钟</span>
            </span>
          </span>
          <span v-if="order.zflow === 5" class="marginLeft5">
            <span v-if="order.zrefund === 1" class="grey">{{ topsUtils.parseTime(order.tupdate) }} 已完成</span>
            <span v-if="order.zrefund === 0 || order.zrefund === 2">
              <span class="grey">已取消，</span>
              <span class="fWB red">{{ calculateOrderPlacementTime(order.tcreate) }}</span>
              <span class="grey">下单1</span>
            </span>
          </span>
          <span v-if="order.zflow === 8" class="marginLeft5">
            <span class="fWB" style="color: rgb(217, 0, 27)">{{ calculateOrderPlacementTime(order.torderGrabbing) }}</span>
            <span class="grey">抢单</span>
          </span>
          <span v-if="order.zflow === 9" class="marginLeft5">
            <span class="fWB" style="color: rgb(217, 0, 27)">{{ order.tarriveReq | compareDate }}</span>
            <span class="fWB" style="color: rgb(217, 0, 27)"> {{ order.tarriveReq === undefined ? "" : order.tarriveReq.slice(-8) }}</span>
            <span class="grey">前送达，</span>
            <span v-if="compareDateTime(order.tarriveReq)" class="grey">
              剩余
              {{ getTimeDifferenceInMinutes(order.tarriveReq) }}分钟
            </span>
            <span v-else class="grey">
              已超时
              <span style="color: rgb(217, 0, 27)">{{ getTimeDifferenceInMinutes(order.tarriveReq) }}分钟</span>
            </span>
          </span>
          <span class="marginLeftAuto marginRight20">
            <el-tooltip content="订单日志" placement="top" effect="light">
              <i class="el-icon-document marginRight10"></i>
            </el-tooltip>
            <el-tooltip content="复制订单" placement="top" effect="light">
              <i v-if="order.zplatOrder === 6" class="el-icon-document-copy marginRight10"></i>
            </el-tooltip>
            <el-tooltip content="撤回订单" placement="top" effect="light">
              <i v-if="order.zplatOrder === 6" class="el-icon-back marginRight10"></i>
            </el-tooltip>
            <el-tooltip content="删除订单" placement="top" effect="light">
              <i v-if="order.zplatOrder === 6" class="el-icon-delete"></i>
            </el-tooltip>
          </span>
        </div>
        <div>
          <span class="marginLeftAuto">
            <a class="blue">{{ order.sshop }}</a>
          </span>
          <span class="C909399">#{{ order.nplatIndex }}</span>
        </div>
        <div class="marginTop5">
          {{ order.ccustomerName }} <a class="blue" @click="callPhone(order.ccustomerPhone)">{{ order.ccustomerPhone }}</a>
        </div>
        <div>
          <a class="blue" @click="checkMap(order)">{{ order.ccustomerAddr }}</a>
          <span v-if="order.ntripDist" class="grey">
            ·
            {{ order.ntripDist }}公里
          </span>
        </div>
        <!--        <div v-if="order.corderRemark !== undefined && order.corderRemark === ''">用户备注：{{ order.corderRemark }}</div>-->
        <div v-if="order.cremark !== '' && order.cremark !== undefined && order.cremark !== null" class="grey">
          备注：<span>{{ order.cremark }}</span>
        </div>
        <!--        <div>{{ order.zflow | zFlow }}</div>-->
        <div v-if="order.zflow === 8 || order.zflow === 9" class="grey">
          <div v-if="order.zflow === 8 && !order.tarriveStore" class="black marginTop10 marginBottom10">接单成功</div>
          <div v-if="order.zflow === 8 && order.tarriveStore" class="black marginTop10 marginBottom10">骑手到店</div>
          <div v-if="order.zflow === 9" class="black marginTop10 marginBottom10">配送中</div>
          配送员: {{ order.cdeliveryName }}, <a class="blue marginLeft5" @click="callPhone(order.cdeliveryPhone)">{{ order.cdeliveryPhone }}</a>
          <el-tooltip class="item marginLeft5 pointer" effect="dark" content="点击向骑手发起短信" placement="bottom-start">
            <span class="iconfont" @click="openMessageDialog(order.cdeliveryPhone)">{{ "&#xe612;" }}</span>
          </el-tooltip>
        </div>
        <el-button v-if="order.zflow === 5 && order.zrefund === 0" class="marginTop5" size="mini" type="primary"> 待处理 </el-button>
        <div v-if="order.zflow === 3">
          <el-divider></el-divider>
          <el-button size="mini"> 未回复 </el-button>
        </div>
      </el-card>
    </div>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="查看位置" :visible.sync="addrDialogVisible" width="800px" append-to-body>
      <getDeliveryRoutes v-if="addrDialogVisible" :ord="orderInfo" />
    </el-dialog>

    <el-dialog v-if="messageDialogVisible" class="videoDialog" :close-on-click-modal="false" title="发送短信" :visible.sync="messageDialogVisible" width="350px" append-to-body>
      <div class="flex alignItemsCenter marginBottom10">
        <div class="marginRight10">接收号码 :</div>
        <el-input v-model="receivePhone" clearable placeholder="请输入内容"></el-input>
      </div>
      <div class="marginBottom10">
        <div class="marginRight10">短信内容:</div>
        <el-input v-model="messageContent" placeholder="请输入短信内容，最多不超过70个汉字。" class="marginTop5" type="textarea"></el-input>
      </div>
      <el-row type="flex" justify="end">
        <el-button type="primary" plain @click="onCencer">取 消</el-button>
        <el-button type="primary" @click="sendSMS">确 认</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import getDeliveryRoutes from "@/views/order/getDeliveryRoutes.vue";
import baseUrls from "@/api/baseUrls";
import moment from "moment";
import { topsUtils } from "@/utils/topsUtils";
export default {
  name: "OrderList",
  components: {
    getDeliveryRoutes,
  },
  filters: {
    compareDate(orderDate) {
      const today = moment().startOf("day");
      const inputMoment = moment(orderDate).startOf("day");
      const diffDays = inputMoment.diff(today, "days");
      if (diffDays === 0) {
        return "今日";
      } else if (diffDays === -1) {
        return "昨日";
      } else if (diffDays === 1) {
        return "明日";
      } else if (diffDays < 0) {
        return `${Math.abs(diffDays)}天前`;
      } else {
        return `${diffDays}天后`;
      }
    },
    zPlatOrder(zPlatOrder) {
      if (zPlatOrder === 0) {
        return "美团闪购";
      } else if (zPlatOrder === 1) {
        return "京东到家";
      } else if (zPlatOrder === 2) {
        return "饿了么";
      } else if (zPlatOrder === 3) {
        return "米度优选";
      }
      return "TOPS订单";
    },
    zFlow(zFlow) {
      // 订单流转状态：1-新订单  2-异常订单 3-催单 4-配送异常 5-取消/退款 6-待配送 7-待抢单 8-待取货 9-配送中 0-已完成
      if (zFlow === 1) {
        return "新订单";
      } else if (zFlow === 2) {
        return "异常订单";
      } else if (zFlow === 3) {
        return "催单";
      } else if (zFlow === 4) {
        return "配送异常";
      } else if (zFlow === 5) {
        return "取消/退款";
      } else if (zFlow === 6) {
        return "待配送";
      } else if (zFlow === 7) {
        return "待抢单";
      } else if (zFlow === 8) {
        return "待取货";
      } else if (zFlow === 9) {
        return "配送中";
      } else if (zFlow === 10) {
        return "预订单";
      }
      return "已完成";
    },
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    orderListData: {
      type: Array,
      Default: [],
    },
  },
  data() {
    return {
      addrDialogVisible: false,
      orderInfo: {},
      //弹窗
      orderData: [],
      //选中index
      selectIndex: 0,
      //
      messageDialogVisible: false,
      //短信接受电话
      receivePhone: "",
      //短信内容
      messageContent: "",
    };
  },
  computed: {
    topsUtils() {
      return topsUtils;
    },
  },
  watch: {
    orderListData(val) {
      this.orderData = val;
      if (this.orderData.length > 0) {
        this.selectCard(val[0], 0);
      }
    },
  },
  mounted() {
    this.orderData = this.orderListData;
    for (let i = 0; i < this.orderData.length; i++) {
      if (this.orderData[i].zflow === 8 || this.orderData[i].zflow === 9) {
        let url = `${this.baseUrls.mqdelivery}/location`;
        let params = {
          sid: this.orderData[i].sid
        };
        this.topsAjax.get(url, params).then((res) => {
          this.$set(this.orderData, i, { ...this.orderData[i], ntripDist: res.distance / 1000 })
        });
      }
    }
  },
  methods: {
    //差距多少分钟
    getTimeDifferenceInMinutes(dateTimeString) {
      const inputDateTime = moment(dateTimeString);
      const currentDateTime = moment();
      const differenceInMinutes = currentDateTime.diff(inputDateTime, "minutes");
      return Math.abs(differenceInMinutes);
    },
    //是否超时
    compareDateTime(dateTimeString) {
      // 确保givenTime是Date对象，如果不是尝试将其转换为Date对象
      let timeParam = typeof dateTimeString === "string" ? new Date(dateTimeString) : dateTimeString;
      // 获取当前时间
      let currentTime = new Date();
      // 比较当前时间是否早于给定时间
      return currentTime < timeParam;
    },
    onCencer() {
      this.messageDialogVisible = false;
      this.receivePhone = "";
      this.messageContent = "";
    },
    sendSMS() {
      if (this.receivePhone === "" || this.receivePhone === null) {
        this.$message({ message: "请输入手机号", type: "warning" });
        return;
      }
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(this.receivePhone)) {
        this.$message({ message: "请输入正确格式手机号", type: "warning" });
        return;
      }
      if (this.messageContent === "" || this.messageContent === null) {
        this.$message({ message: "请输入短信内容", type: "warning" });
        return;
      }
      //短信接口
    },
    openMessageDialog(phone) {
      this.receivePhone = phone;
      this.messageDialogVisible = true;
    },
    calculateOrderPlacementTime(value) {
      let target = new Date(parseInt(value));
      let differenceInMilliseconds = new Date().getTime() - target.getTime();
      let differenceInMinutes = Math.ceil(differenceInMilliseconds / (1000 * 60));

      let diff;

      if (differenceInMinutes < 1) {
        diff = "刚刚";
      } else if (differenceInMinutes < 60) {
        diff = differenceInMinutes + "分钟前";
      } else if (differenceInMinutes < 1440) {
        // Less than a day
        let hours = Math.floor(differenceInMinutes / 60);
        let minutes = differenceInMinutes % 60;
        diff = hours + "小时" + (minutes > 0 ? minutes + "分钟" : "") + "前";
      } else {
        // More than a day
        let days = Math.floor(differenceInMinutes / 1440);
        let hours = Math.floor((differenceInMinutes % 1440) / 60);
        let minutes = differenceInMinutes % 60;
        diff = days + "天" + (hours > 0 ? hours + "小时" : "") + (minutes > 0 ? minutes + "分钟" : "") + "前";
      }

      return diff;
    },
    checkMap(orderInfo) {
      this.orderInfo = orderInfo;
      this.addrDialogVisible = true;
    },
    /**
     * 删除订单
     */
    async deleteOrder(sid) {
      this.$confirm("是否删除该订单？删除后将无法恢复")
        .then((res) => {
          let url = `${this.baseUrls.order}/update`;
          let params = {
            sid: sid,
            bDelete: 1,
          };
          this.topsAjax.post(url, params).then((res) => {
            this.$message.success("删除订单成功！");
            window.location.reload();
          });
        })
        .catch((res) => {});
    },
    /**
     * 选中卡片
     * @param index
     */
    selectCard(order, index) {
      // 点击卡片时更新选中状态
      this.selectIndex = index;
      this.$emit("selectOrderId", order.sid);
    },
    /**
     * 关闭状态信息弹窗
     */
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    setColor(type) {
      if (type == 0) {
        // return "  background: linear-gradient(to left top, rgba(255,255,0,1), rgba(0,0,0,0));";
        return "color: orange;";
      } else if (type == 1) {
        // return "  background: linear-gradient(to left top, rgba(255,0,0,1), rgba(0,0,0,0));";
        return "color: green;";
      } else if (type == 2) {
        // return "  background: linear-gradient(to left top, rgba(0,0,255,1), rgba(0,0,0,0));";
        return "color: #409EFF;";
      } else if (type == 3) {
        return "color: #F56C6C";
      }
      return "";
    },
    callPhone(toTel) {
      toTel = toTel.substr(0, 11);
      this.$confirm(`是否拨打[<span style="color: red;">${toTel}</span>]的电话？`, {
        dangerouslyUseHTMLString: true,
      })
        .then((_) => {
          // let agent = JSON.parse(sessionStorage.getItem("userInfo")).agent;
          // if (agent == null || agent == undefined) return this.$message.error("当前账号未绑定分机号，请联系管理员！");
          let url = `${baseUrls.call}/call?phone=${toTel}`;
          let params = {};
          this.topsAjax.get(url, params).then((res) => {
            let data = JSON.parse(res);
            if (data.status) {
              this.$message.success("拨号成功！");
            } else {
              this.$message.error(data.msg);
            }
          });
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.orderListButton {
  margin: 0 5px;
  float: right;
  padding: 0;
  border: none;
}

.is-selected {
  /* 选中效果的样式 */
  border-color: #409eff;
}
</style>

<style lang="scss">
.orderList {
  .el-card__header {
    padding: 0;
  }

  .el-divider--horizontal {
    margin: 20px 0 10px 0;
  }
}
</style>
