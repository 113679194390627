<template>
  <!-- 订单新增与修改弹窗-->
  <div class="orderInfoAD">
    <div v-show="false" id="container" />
    <div class="h60 bcGrey wHP flex paddingLeft20 alignItemsCenter marginBottom20">订单类型</div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="bg-purple bcGrey">
          <el-form ref="form" label-position="left" :rules="rules" :model="ordMain" label-width="auto" class="grey padding10">
            <el-tabs v-model="ordMain.zdelivery" class="marginBottom10 marginLeft10">
              <el-tab-pane label="同城配送" name="1" />
              <el-tab-pane label="顾客自提" name="2" />
            </el-tabs>
            <el-form-item v-if="ordMain.zdelivery === '1'" label="选择城市">
              <el-cascader ref="cascader" placeholder="选择城市" :options="options" :props="defaultProps" clearable filterable @change="selectCity"></el-cascader>
            </el-form-item>
            <el-form-item label="线上店铺">
              <el-select v-model="shop" clearable filterable placeholder="线上店铺" @change="handleSelectShop">
                <el-option v-for="item in shopList" :key="item.sid" :label="item.cname" :value="item.sid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="ordMain.zdelivery === '1'" label="收货地址" prop="ccustomerAddr">
              <el-select v-model="ordMain.ccustomerAddr" clearable filterable :filter-method="getReceivingAddress" placeholder="请选择" @change="changeInfo">
                <el-option v-for="item in infoOptions" :key="item.id" :label="item.name" :value="item">
                  <div class="flex directionColumn">
                    <div>{{ item.name }}</div>
                    <div class="grey">{{ item.address }}</div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="ordMain.zdelivery === '1'" label="详细地址" prop="creceiveAddr">
              <el-input v-model="ordMain.creceiveAddr"></el-input>
            </el-form-item>
            <el-form-item label="收货人" prop="ccustomerName">
              <el-input v-model="ordMain.ccustomerName"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="ccustomerPhone">
              <el-input v-model="ordMain.ccustomerPhone" class="w150"></el-input>
              -
              <el-input v-model="extensionNumber" class="w100" @blur="onInteger"></el-input>
            </el-form-item>
            <el-form-item v-if="ordMain.zdelivery === '1'" label="送达时间">
              <div class="wHP">
                <el-date-picker v-model="value1" type="date" placeholder="选择日期" @change="getArrivePre" />
                <div class="flex marginTop5">
                  <el-time-select
                    v-model="startTime"
                    placeholder="起始时间"
                    style="width: 120px"
                    class="marginRight10"
                    :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '24:00',
                    }"
                    @change="getArrivePre"
                  />
                  -
                  <el-time-select
                    v-model="endTime"
                    placeholder="结束时间"
                    class="marginLeft10"
                    style="width: 120px"
                    :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '24:00',
                      minTime: startTime,
                    }"
                    @change="getArrivePre"
                  />
                </div>
              </div>
              <!--              <el-date-picker v-model="ordMain.tarriveReq" type="datetime" placeholder="请选择送达时间"></el-date-picker>-->
            </el-form-item>
            <el-form-item v-if="ordMain.zdelivery === '2'" label="自提时间" prop="tpick">
              <el-date-picker v-model="ordMain.tpick" type="datetime" placeholder="请选择自提时间"></el-date-picker>
            </el-form-item>
            <el-form-item v-if="ordMain.zdelivery === '1'" label="配送门店">
              <el-select v-model="store" placeholder="请选择配送门店" @change="handleSelectStore">
                <el-option v-for="item in storeList" :key="item.sid" :label="item.cname" :value="item.sid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="ordMain.zdelivery === '2'" label="自提门店">
              <el-select v-model="store" placeholder="请选择配送门店" @change="handleSelectStore">
                <el-option v-for="item in storeList" :key="item.sid" :label="item.cname" :value="item.sid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付方式" prop="pay">
              <el-select v-model="pay" placeholder="支付方式" @change="handleSelectPay">
                <el-option v-for="item in payOption" :key="item.z_pay" :label="item.s_pay" :value="item.z_pay"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="bg-purple-light bcGrey hHP">
          <el-form ref="form-goods" :model="ordMain" label-width="auto" class="grey padding10">
            <el-form-item label="货品信息">
              <div style="float: right">合计: {{ totalAmount }}</div>
            </el-form-item>
            <div v-if="goodsList.length > 0" class="bcGrey padding10 marginBottom10 scrollbar" style="height: 290px">
              <div v-for="(goods, index) in goodsList" :key="goods.sid" class="marginBottom10">
                <div class="wHP bcWhite flex padding5">
                  <el-image :preview-src-list="[goods.c_master_url]" class="w60 h60 marginRight5" fit="fit" :src="goods.c_master_url" />
                  <div class="flex1">
                    <div class="fs20 blue">{{ goods.c_name }}</div>
                    <div class="flex marginTopAuto alignItemsCenter">
                      <div style="width: 35%">单价: {{ goods.n_price }}</div>
                      <div class="flexGrow">数量: {{ goods.num }}</div>
                      <el-button type="primary" style="margin-left: auto" icon="el-icon-delete" @click="deleteGoods(index)"></el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-button class="wHP block" type="primary" plain @click="addGoodsShow">+ 添加货品</el-button>
            <el-form-item label="用户备注" class="marginTop10">
              <el-input v-model="ordMain.corderRemark" autosize type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="内部备注" class="marginTop10">
              <el-input v-model="ordMain.cremark" autosize type="textarea"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <div class="marginTop20 paddingLeft20 bcGrey h60 wHP marginBottom20 flex alignItemsCenter">
      配送通道
      <div class="marginLeft20">
        <el-radio v-model="deliveryChannel" label="1">松鼠屋</el-radio>
        <el-radio v-model="deliveryChannel" label="2">快服务</el-radio>
      </div>
    </div>
    <el-row type="flex" justify="end">
      <el-button v-show="ordMain.zdelivery === '1'" type="primary" plain @click="saveAndDelivery(6)">保存并发单</el-button>
      <el-button type="primary" @click="saveAndDelivery(0)">保存</el-button>
    </el-row>

    <el-dialog class="elDialog" :close-on-click-modal="false" title="添加商品" :visible.sync="dialogVisible" width="1000px" :before-close="handleClose" append-to-body>
      <OrderGoodsAdd v-if="dialogVisible" ref="OrderGoodsAdd" :select-store="ordMain" :max-num="true" :order-goods="goodsList" @OrderGoodsAdd="orderGoodsAdd" />
    </el-dialog>
  </div>
</template>

<script>
import OrderGoodsAdd from "@/views/order/orderGoodsAdd.vue";
import lodash from "lodash";
import moment from "moment";
import { orderAdd } from "@/api/order/order";

export default {
  name: "OrderInfo",
  components: { OrderGoodsAdd },
  props: {
    ordId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: "",
      infoOptions: [],
      deliveryChannel: "1",
      // 列表数据
      dataList: [],
      addr: {},
      loading: false,
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      //新增信息
      ordMain: {
        zdelivery: "1",
        noriginPrice: 0,
      },
      totalAmount: 0,
      shopList: [],
      storeList: [],
      goodsList: [],
      dialogVisible: false,
      store: null,
      shop: null,
      rules: {
        ccustomerAddr: [{ required: true, message: "请输入收货地址", trigger: "blur" }],
        creceiveAddr: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        ccustomerName: [{ required: true, message: "请输入收货人", trigger: "blur" }],
        ccustomerPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { pattern: /^(1[3-9]\d{9}|\d{3,4}-\d{7,8})$/, message: "请输入有效的手机号或分机号", trigger: "blur" },
        ],
        tpick: [{ required: true, message: "请选择自提时间", trigger: "blur" }],
      },
      payOption: [
        { z_pay: 0, s_pay: "平台支付" },
        { z_pay: 1, s_pay: "货到付款" },
        { z_pay: 2, s_pay: "微信支付" },
        { z_pay: 3, s_pay: "支付宝" },
        { z_pay: 4, s_pay: "现金支付" },
        { z_pay: 5, s_pay: "定期结算" },
        { z_pay: 6, s_pay: "店内支付" },
      ],
      pay: null,
      options: [],
      defaultProps: {
        expandTrigger: "hover",
        children: "children",
        label: "NODE_NAME",
        value: "NODE_CODE",
      },
      extensionNumber: "",
      value1: "",
      startTime: "",
      endTime: "",
    };
  },
  watch: {
    addr(val) {
      this.$set(this.ordMain, "ccustomerAddr", val.district + val.name);
      this.$set(this.ordMain, "caddrLongitude", val.location.lng);
      this.$set(this.ordMain, "caddrLatitude", val.location.lat);
    },
  },
  created() {
    this.queryBaseAreaList();
    if (this.ordId) {
      this.queryShop([]);
      this.selectOrderById(this.ordId);
    }
  },
  mounted() {},
  methods: {
    /**
     * 选中收货地址
     * @param val
     */
    changeInfo(val) {
      this.ordMain.ccustomerAddr = val.name;
      this.ordMain.creceiveAddr = val.address;
      this.ordMain.caddrLongitude = val.location.lng;
      this.ordMain.caddrLatitude = val.location.lat;
    },
    /**
     * 获取收货地址
     * @param val
     */
    getReceivingAddress(val) {
      var self = this;
      var map = new AMap.Map("container", {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 11, //初始地图级别
      });
      var keywords = val;
      AMap.plugin("AMap.AutoComplete", function () {
        var autoOptions = {
          city: self.value,
        };
        var autoComplete = new AMap.AutoComplete(autoOptions);
        autoComplete.search(keywords, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 搜索成功时，result即是对应的匹配数据
            self.infoOptions = result.tips.filter((city) => city.id !== null && city.id !== "");
          }
        });
      });
    },
    getArrivePre() {
      if (this.value1 !== "" && this.value1 !== null && this.startTime !== "" && this.startTime !== null && this.endTime !== "" && this.endTime !== null) {
        let startDay = moment(this.value1, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (中国标准时间)");
        let endDay = moment(this.value1, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (中国标准时间)");
        let startTime = this.startTime.split(":").map(Number);
        let endTime = this.endTime.split(":").map(Number);
        startDay = startDay.add(startTime[0], "hours").add(startTime[1], "minutes");
        this.ordMain.tarriveReq = startDay.format("YYYY-MM-DD HH:mm");
        endDay = endDay.add(endTime[0], "hours").add(endTime[1], "minutes");
        this.ordMain.tarriveEnd = endDay.format("YYYY-MM-DD HH:mm");
      }
    },
    /**
     * 选择城市
     * @param val
     */
    selectCity(val) {
      this.infoOptions = [];
      let city;
      this.queryShop(val);
      this.options.forEach((item) => {
        if (item.children) {
          item.children.forEach((subItem) => {
            if (subItem.NODE_CODE === val[1]) city = subItem;
          });
        }
      });
      if (city) {
        let search = city.NODE_NAME !== "市辖区" ? city.NODE_NAME : city.PROVINCE_NAME;
        this.value = search;
        // this.$nextTick(() => {
        //   var self = this;
        //   var map = new AMap.Map("container", {
        //     resizeEnable: true,
        //   });
        //   var autoOptions = {
        //     city: search,
        //     input: "tipinput",
        //   };
        //   AMap.plugin(["AMap.PlaceSearch", "AMap.AutoComplete"], function () {
        //     var auto = new AMap.AutoComplete(autoOptions);
        //     var placeSearch = new AMap.PlaceSearch({
        //       map: map,
        //     });
        //     auto.on("select", select); //注册监听，当选中某条记录时会触发
        //     function select(e) {
        //       placeSearch.setCity(e.poi.adcode);
        //       placeSearch.search(e.poi.name);
        //       self.addr = e.poi;
        //       if (self.storeList.length > 0) {
        //         let maxDistance = 999999;
        //         let store = {};
        //         let count = 0;
        //         self.storeList.forEach((currentItem) => {
        //           self.calculateDistance([currentItem.clongitude.toString(), currentItem.clatitude.toString()], [self.addr.location.lng.toString(), self.addr.location.lat.toString()], (distance) => {
        //             count += 1;
        //             if (distance !== null && distance < maxDistance) {
        //               maxDistance = distance;
        //               store = currentItem;
        //             }
        //             if (count === self.storeList.length) {
        //               let id = store.sid;
        //               self.handleSelectStore(id);
        //               self.store = id;
        //             }
        //           });
        //         });
        //       }
        //     }
        //   });
        // });
      }
    },
    onInteger() {
      const regex = /^\d{3,4}$/;
      if (regex.test(this.extensionNumber)) {
        return;
      } else if (this.extensionNumber === "") {
        return;
      } else {
        this.$message.error("分机号必须为3到4位的正整数！");
        this.extensionNumber = "";
      }
    },
    /**
     * 获取省市县数据
     */
    queryBaseAreaList() {
      let url = "/api/system/baseArea/queryList";
      this.topsAjax.get(url).then((res) => {
        res.forEach((item) => {
          if (item.children) {
            item.children.forEach((subItem) => {
              if (subItem.children) {
                delete subItem.children;
                // subItem.children.forEach((thirdItem) => {
                //   if (thirdItem.children) {
                //     delete thirdItem.children; // 删除第四层 children
                //   }
                // });
              }
            });
          }
        });
        this.options = res;
      });
    },
    selectOrderById(id) {
      let url = `${this.baseUrls.order}/get/` + id;
      this.topsAjax.get(url).then((response) => {
        this.goodsList = response.ordGoodsList.map((item) => {
          return {
            ...item,
            name: item.c_name,
            price: item.n_price,
            num: item.c_goods_num,
            masterUrl: item.c_master_url,
            sku: item.c_sku,
          };
        });
        let ordMain = response.ordMain;
        this.extensionNumber = ordMain.ccustomerPhone.split("_")[1];
        this.queryStore(ordMain.rshop);
        this.shop = ordMain.rshop;
        this.pay = ordMain.zpay;
        this.store = ordMain.rstore;
        this.$set(this.ordMain, "sid", ordMain.sid);
        this.$set(this.ordMain, "ccustomerAddr", ordMain.ccustomerAddr);
        this.$set(this.ordMain, "ccustomerName", ordMain.ccustomerName);
        let resPhone = this.processPhone(ordMain.ccustomerPhone);
        this.$set(this.ordMain, "ccustomerPhone", resPhone.phone);
        this.extensionNumber = resPhone.extensionNumber;
        this.$set(this.ordMain, "cremark", ordMain.cremark);
        this.$set(this.ordMain, "corderRemark", ordMain.corderRemark);
        if (ordMain.tarriveReq !== "" && ordMain.tarriveReq !== null) {
          let time = ordMain.tarriveReq.split(" ")[0];
          this.value1 = moment(time);
          this.startTime = ordMain.tarriveReq.split(/[\s-]+/)[1];
          this.endTime = ordMain.tarriveReq.split(/[\s-]+/)[2];
        }
        this.$set(this.ordMain, "tarriveReq", ordMain.tarriveReq);
        this.$set(this.ordMain, "creceiveAddr", ordMain.creceiveAddr);
        this.$set(this.ordMain, "zdelivery", ordMain.zdelivery.toString());
        this.$set(this.ordMain, "noriginPrice", ordMain.noriginPrice);
        this.$set(this.ordMain, "rstore", ordMain.rstore);
        this.totalAmount = (ordMain.noriginPrice / 100).toFixed(2);
      });
    },
    /**
     * 计算距离
     */
    calculateDistance(startPoint, endPoint, callback) {
      const riding = new AMap.Riding({
        map: null, // 如果需要展示路线，可以传入地图实例
        panel: null, // 如果需要展示路线规划信息，可以传入DOM元素
      });
      riding.search(startPoint, endPoint, (status, result) => {
        if (status === "complete") {
          const distance = result.routes[0].distance;
          const distanceInKilometers = (distance / 1000).toFixed(2);
          callback(distanceInKilometers);
        } else {
          callback(null);
        }
      });
    },
    saveAndDelivery(type) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let _this = this;
          let ordInfo = JSON.parse(JSON.stringify(this.ordMain));
          ordInfo.noriginPrice = (this.totalAmount * 100).toFixed(0);
          if (!this.ordMain.sid && this.ordMain.zdelivery === "1") {
            const startPoint = [this.ordMain.storeLongitude, this.ordMain.storeLatitude]; // 起点经纬度
            const endPoint = [this.ordMain.caddrLongitude.toString(), this.ordMain.caddrLatitude.toString()]; // 终点经纬度
            this.calculateDistance(startPoint, endPoint, (distanceInKilometers) => {
              if (distanceInKilometers !== null) {
                ordInfo.ntripDist = distanceInKilometers;
                // 距离计算完成后，执行保存逻辑
                _this.saveOrder(ordInfo, type);
              } else {
                this.$message.error("跨区超出配送范围");
                return;
              }
            });
          } else {
            // 如果不需要计算距离，直接执行保存逻辑
            this.saveOrder(ordInfo, type);
          }
        }
      });
    },
    saveOrder(ordInfo, type) {
      let goodsList = [];
      this.goodsList.forEach((p) => {
        let goods = {};
        goods.cgoodsSku = p.c_sku;
        goods.cgoodsName = p.c_name;
        goods.cgoodsNum = p.num;
        goods.sgoodsUnit = p.s_unit;
        goods.cgoodsSpecification = p.c_specification;
        goods.ngoodsPrice = p.n_price;
        goods.cremark = p.remark;
        goodsList.push(goods);
      });
      if (goodsList.length === 0) {
        this.$message.error("请添加货品信息");
        return;
      }
      if (this.extensionNumber !== null && this.extensionNumber !== "") ordInfo.ccustomerPhone = ordInfo.ccustomerPhone + "_" + this.extensionNumber;
      if (this.totalAmount !== null && this.totalAmount !== "") ordInfo.ccustomerPrice = this.convertAmount(this.totalAmount);
      let params = { ordMain: ordInfo, ordGoodsList: goodsList, type: type };
      orderAdd(params).then((res) => {
        if (this.ordMain.sid) {
          this.$message.success("修改成功");
          this.$emit("click-back");
        } else {
          this.$message.success("保存成功");
          this.$emit("close-dialog");
        }
      });
    },
    convertAmount(val) {
      let amountWithoutDecimal = val.toString().replace(".", "");
      if (amountWithoutDecimal.length === val.length) {
        amountWithoutDecimal += "00";
      }
      if (amountWithoutDecimal.length === val.length + 1) {
        amountWithoutDecimal += "0";
      }
      return amountWithoutDecimal;
    },
    orderGoodsAdd(goodsList) {
      this.dialogVisible = false;
      this.goodsList = goodsList.filter((p) => p.goodsId);
      this.totalAmount = lodash.sumBy(this.goodsList, (p) => p.num * p.n_price);
    },
    deleteGoods(index) {
      this.goodsList.splice(index, 1);
      this.totalAmount = lodash.sumBy(this.goodsList, (p) => p.num * p.price);
    },
    addGoodsShow() {
      if (this.shop == null) {
        this.$message.error("请先选择线上店铺！");
        return;
      }

      if (this.store == null) {
        this.$message.error("请先选择配送门店！");
        return;
      }
      this.dialogVisible = true;
    },
    //传入电话号码，返回电话号码和分机号
    processPhone(phone) {
      let phoneObj = {};
      let arr = [];
      if (phone.indexOf("_") > -1) {
        arr = phone.split("_");
        phoneObj.phone = arr[0];
        phoneObj.extensionNumber = arr[1];
      } else if (phone.indexOf(",") > -1) {
        arr = phone.split(",");
        phoneObj.phone = arr[0];
        phoneObj.extensionNumber = arr[1];
      } else {
        phoneObj.phone = phone;
        phoneObj.extensionNumber = "";
      }
      return phoneObj;
    },
    // 查询所有商铺列表
    queryShop(areaIds) {
      let url = `${this.baseUrls.shop}/queryList`;
      let params = {
        bDelete: 0,
        bValid: 1,
        areaIds: areaIds[areaIds.length - 1],
      };
      this.topsAjax.get(url, params).then((res) => {
        this.shopList = res;
      });
    },
    // 查询所有门店(无人仓)列表
    queryStore(shopId) {
      this.storeList = [];
      let url = `${this.baseUrls.store}/queryListByShop`;
      let params = {
        shopId: shopId,
      };
      this.topsAjax.get(url, params).then((res) => {
        this.storeList = res;
        if (this.storeList.length === 1) {
          let id = this.storeList[0].sid;
          this.handleSelectStore(id);
          this.store = id;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleSelectShop(val) {
      let shop = lodash.filter(this.shopList, { sid: val })[0];
      this.ordMain.sshop = shop.cname;
      this.ordMain.rshop = shop.sid;
      this.storeList = [];
      this.store = null;
      this.ordMain.sstore = null;
      this.ordMain.rstore = null;
      this.queryStore(val);
    },
    handleSelectStore(val) {
      let store = lodash.filter(this.storeList, { sid: val })[0];
      this.ordMain.sstore = store.cname;
      this.ordMain.rstore = store.sid;
      this.ordMain.storeLongitude = store.clongitude;
      this.ordMain.storeLatitude = store.clatitude;
    },
    handleSelectPay(val) {
      let pay = lodash.filter(this.payOption, { z_pay: val })[0];
      this.ordMain.zpay = pay.z_pay;
      this.ordMain.spay = pay.s_pay;
    },
  },
};
</script>

<style lang="scss">
.amap-sug-result {
  z-index: 9999;
}

.orderInfoAD {
  width: 100%;

  .el-input {
    width: 270px;
  }

  .el-picker-panel__body > .el-date-picker__time-header > .el-date-picker__editor-wrap > .el-input {
    width: 100% !important;
  }

  .orderGoodsAddDialog {
    .el-date-picker__time-header > div {
      width: 100% !important;
    }

    .el-dialog__body {
      background-color: white !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.el-select-dropdown__item {
  height: auto;
}
</style>
