<template>
  <!-- 订单列表右侧订单信息 -->
  <div class="container paddingTop10 orderListInfo">
    <el-tabs v-model="tabs" class="marginLeft10" @tab-click="handleTabs">
      <el-tab-pane v-if="orderInfo.zflow === 5" label="申请动态" name="0"></el-tab-pane>
      <el-tab-pane v-else-if="orderInfo.zflow !== 1" label="配送跟踪" name="1"></el-tab-pane>
      <el-tab-pane label="订单详情" name="2"></el-tab-pane>
      <el-tab-pane v-if="orderInfo.zflow === 8" label="门店智控" name="3"></el-tab-pane>
    </el-tabs>
    <div class="container__body scrollbar">
      <!--      申请动态-->
      <div v-if="tabs === '0'">
        <el-timeline class="marginLeft10 marginTop10" :reverse="true">
          <el-timeline-item v-for="(activity, index) in refundList" :key="index" :timestamp="activity.tcreate | time">
            <div>
              {{ activity.ztype | refundType }} <span class="marginLeft5">{{ activity.tcreate | Hour }}</span>&nbsp;<span v-if="activity.ztype != 50" class="marginLeft5">{{ activity.desc }}</span>
            </div>
            <div v-if="activity.ztype === 10">
              退款商品:
              <el-tooltip effect="dark" placement="top" :content="formattedGoodsInfo(activity.crefGoodsName, activity.crefGoodsNum)">
                <span class="text-ellipsis">{{ formattedGoodsInfo(activity.crefGoodsName, activity.crefGoodsNum) }}</span>
              </el-tooltip>
            </div>
            <div v-if="activity.ztype === 10">
              退款原因:
              <el-tooltip effect="dark" placement="top" :content="activity.creason">
                <span class="text-ellipsis">{{ activity.creason }}</span>
              </el-tooltip>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
      <!-- 配送跟踪 -->
      <div v-if="tabs === '1'" class="ordListInfo paddingLeft10 paddingRight10">
        <div v-for="(delivery, index) in DeliveryData" :key="delivery.sid">
          <el-card class="box-card bcGrey marginBottom10">
            <el-collapse accordion>
              <el-collapse-item>
                <template slot="title">
                  <div class="flex directionColumn padding10">
                    <div>[{{ delivery.c_platform_name | deliveryPlatform }}]第{{ index + 1 }}次下单</div>
                    <div class="fs11 C86909c">状态: {{ delivery.z_delivery_status | deliveryType }} · {{ delivery.t_create | Hour }} · {{ delivery.n_price }}元</div>
                    <div class="fs11 C86909c">备注:{{ delivery.c_remark }}</div>
                  </div>
                </template>
                <div class="marginLeft10">
                  <el-timeline class="marginTop10" :reverse="reverse">
                    <el-timeline-item v-for="info in delivery.deliveryInfo" :key="info.sid" :hide-timestamp="true" :timestamp="info.tcreate | Hour">
                      <div>{{ info.zstageStatus | deliveryType }} · {{ info.tcreate | Hour }}</div>
                      <div class="fs11 C86909c">
                        {{ info.cdescribe }}
                      </div>
                      <div v-if="info.zstageStatus === 20" class="flex marginTop5">
                        {{ delivery.s_logistic | deliveryPlatformOrder }}
                      </div>
                      <div v-if="orderInfo.zflow === 7" class="flex marginTop5">
                        <el-button size="mini" @click="openAddTip(delivery)">加小费</el-button>
                        <!--                        <el-button size="mini">追加配送员</el-button>-->
                      </div>
                      <div v-if="info.zstageStatus === 20 || info.zstageStatus === 30 || info.zstageStatus === 40 || info.zstageStatus === 50" class="C86909c">
                        <div class="fs11">配送员：{{ delivery.c_shipper_name }}</div>
                        <div class="fs11">
                          联系方式：<a class="purple fs11" @click="callPhone(delivery.c_shipper_phone)">{{ delivery.c_shipper_phone }}</a>
                        </div>
                        <a class="purple" @click="showRideMap(delivery.sid)">查看位置</a>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-card>
        </div>
      </div>
      <!-- 订单详情 -->
      <div v-if="tabs === '2'">
        <el-collapse v-model="activeNames" class="paddingRight10 paddingLeft10 labelGray">
          <el-collapse-item title="订单货品" name="1">
            <div v-for="(product, index) in goods" :key="index" class="marginBottom10">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="bg-purple">
                    <el-image v-if="product.goodsId === undefined || product.n_total === undefined || product.n_total === '0'" :src="noGoodsUrl" :preview-src-list="[noGoodsUrl]" class="h100 w100 borderRed" fit="fit"></el-image>
                    <el-image v-else :src="product.c_master_url" :preview-src-list="[product.c_master_url]" class="h100 w100" fit="fit"></el-image>
                  </div>
                </el-col>
                <el-col :span="16">
                  <div class="bg-purple-light h100 flex directionColumn" style="justify-content: space-between">
                    <a v-if="product.goodsId !== undefined" class="blue" @click="handleViewGoods(product.goodsId, product.r_sort, null)">{{ product.c_name }}</a>
                    <div v-else class="elRed">{{ product.c_name }}</div>
                    <div>货号：{{ product.c_barcode }}</div>
                    <div class="textRight" :style="{ color: product.num > 1 ? 'red' : 'black' }">x{{ product.num }}</div>
                    <div v-show="product.c_remake" style="position: absolute; bottom: 0">货品备注: {{ product.c_remake }}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="bcGrey h100 padding10 borderRadius5">
              <div class="grey">用户备注</div>
              <div>{{ orderInfo.corderRemark }}</div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template slot="title">
              <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%">
                <div>顾客信息</div>
                <div @click="openIm">
                  <i class="marginLeft5 marginRight5 header-icon el-icon-chat-dot-round"> </i>
                  <span v-show="msgUnReadCount > 0" class="el-badge__content">{{ msgUnReadCount }} </span>
                </div>
              </div>
            </template>
            <el-descriptions :column="1">
              <el-descriptions-item label="预定人">{{ orderInfo.ccustomerName }}</el-descriptions-item>
              <el-descriptions-item label="手机号">
                <a class="blue" @click="callPhone(orderInfo.ccustomerPhone)">{{ orderInfo.ccustomerPhone }}</a>
              </el-descriptions-item>
              <el-descriptions-item label="隐私号">{{ orderInfo.ccustomerPhonePrivate }}</el-descriptions-item>
            </el-descriptions>
          </el-collapse-item>
          <el-collapse-item title="订单金额" name="3">
            <el-descriptions :column="1">
              <el-descriptions-item key="配送費" label="配送費">{{ orderInfo.ndeliveryPrice | parsePrice }} 元</el-descriptions-item>
              <!--              <el-descriptions-item key="包装费" label="包装费">{{ orderInfo.npackagePrice | parsePrice }}</el-descriptions-item>-->
              <el-descriptions-item key="顾客实付" label="顾客实付">{{ orderInfo.ncustomerPrice | parsePrice }} 元</el-descriptions-item>
              <el-descriptions-item key="商家实收" label="商家实收">{{ orderInfo.ndiscountPrice | parsePrice }} 元</el-descriptions-item>
            </el-descriptions>
          </el-collapse-item>
          <el-collapse-item title="订单信息" name="4">
            <el-descriptions :column="1">
              <el-descriptions-item label="订单编号">{{ orderInfo.ccode }}</el-descriptions-item>
              <el-descriptions-item label="下单时间">{{ orderInfo.tcreate | time }}</el-descriptions-item>
              <el-descriptions-item v-if="orderInfo.zdelivery === 0" label="要求送达">{{ orderInfo.tpick }}</el-descriptions-item>
              <el-descriptions-item v-if="orderInfo.zdelivery === 1" label="自提时间">{{ orderInfo.tpick }}</el-descriptions-item>
              <el-descriptions-item v-if="orderInfo.tbusinessReceipt" label="完成时间">{{ orderInfo.tbusinessReceipt }}</el-descriptions-item>
              <el-descriptions-item label="配送门店">
                <a class="blue" @click="clickStoreLocation">{{ orderInfo.sshop }}</a>
              </el-descriptions-item>
            </el-descriptions>
          </el-collapse-item>
          <el-collapse-item v-if="!(orderInfo.zflow === 1 || orderInfo.zflow === 2)" title="配送信息" name="5">
            <el-descriptions :column="1">
              <el-descriptions-item label="配送方式">{{ orderInfo.sdelivery }}</el-descriptions-item>
              <el-descriptions-item label="配送费用">{{ orderInfo.ndeliveryPrice }}</el-descriptions-item>
              <el-descriptions-item label="骑手姓名">{{ orderInfo.cdeliveryName }}</el-descriptions-item>
              <el-descriptions-item label="骑手电话">
                <a class="blue" @click="callPhone(orderInfo.ccustomerPhone)">{{ orderInfo.cdeliveryPhone }}</a>
              </el-descriptions-item>
            </el-descriptions>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- 门店智控 -->
      <div v-if="tabs === '3'" class="hHP flex directionColumn marginLeft10 marginRight10">
        <div class="padding10 border borderRadius10 marginBottom10">
          <div class="fs13">{{ storeName }}</div>
          <a class="purple fs13">{{ storeAddr }}</a>
        </div>
        <div class="padding10 border borderRadius10 marginBottom10 hHP">
          <div class="borderBottom" style="padding-bottom: 5px; margin-bottom: 5px">
            <span class="fs20">门店监控</span>
          </div>
          <div class="flex directionColumn">
            <div v-for="(item, index) in videoList" :key="index" class="flex alignItemsCenter directionColumn">
              <a class="el-icon-video-play fs150 ft-color" @click="openVideo(item)" />
              <p>{{ item.cname }}</p>
            </div>
          </div>
        </div>
        <div class="padding10 border borderRadius10">
          <div class="borderBottom" style="padding-bottom: 5px; margin-bottom: 5px">
            <span class="fs20"> 门店智控</span>
          </div>
          <el-row class="padding10">
            <el-col :span="12">
              <el-switch v-model="AccessControl" inactive-text="远程门禁" @change="lampOrDoorSwitch(0)" />
              <el-switch v-model="lighting" inactive-text="远程灯光" @change="lampOrDoorSwitch(1)" />
            </el-col>
            <el-col :span="12">
              <el-button type="primary" class="h60 w150" @click="activateInStore">到店模式</el-button>
            </el-col>
          </el-row>
          <a href="#" class="adding10" style="color: #b5b4bf">更多控制</a>
        </div>
      </div>
    </div>
    <el-row class="grey paddingTop10" style="text-align: center; border-top: 1px solid rgb(235, 238, 245)">
      <div v-if="tabs === '0'" class="padding10 borderRadius5">
        <el-button v-if="orderInfo.zrefund === 0" type="danger" @click="cancerDialog = true">拒绝</el-button>
        <el-button v-if="orderInfo.zrefund === 0" :loading="cancelLoading" type="primary" @click="cancel">同意</el-button>
      </div>
      <div v-if="tabs === '1'" class="padding10 borderRadius5">
        <el-button v-if="orderInfo.zdelivery === 2" type="success" @click="selfPickupFinish">自提完成</el-button>
        <el-button v-if="orderInfo.zflow === 7 || orderInfo.zflow === 8 || orderInfo.zflow === 9 || (orderInfo.zflow === 5 && DeliveryData.length > 0)" type="danger" plain :loading="cancelMytLoading" @click="updateCancel">全部取消</el-button>
        <el-button v-if="orderInfo.zflow === 3" type="primary" @click="replyDialogVisible = true">回复用户</el-button>
        <el-button v-if="orderInfo.zflow === 4 || orderInfo.zflow === 7 || orderInfo.zflow === 8 || orderInfo.zflow === 9 || (orderInfo.zflow === 5 && DeliveryData.length > 0)" type="warning" @click="deliveryOrderVisible = true">再次配送</el-button>
      </div>
      <div v-if="tabs === '2'" class="padding10 borderRadius5">
        <el-button type="primary" plain @click="openPicking(orderInfo.rpick)">修改拣货单</el-button>
        <el-button v-if="orderInfo.zflow === 1 || orderInfo.zflow === 2" type="primary" @click="deliveryOrderVisible = true">人工配送下单</el-button>
        <!--        <el-button v-if="orderInfo.zflow === 3 || orderInfo.zflow === 4 || orderInfo.zflow === 5 || orderInfo.zflow === 6 || orderInfo.zflow === 7 || orderInfo.zflow === 8 || orderInfo.zflow === 9 || orderInfo.zflow === 0" type="primary" @click="goodsLocationDialogVisible = true">查看订单出库单</el-button>-->
      </div>
      <el-button @click="ordState">麦芽田查看状态</el-button>
      <el-button @click="testWebSocket">发送websocket</el-button>
      <!--        <el-button v-if="orderInfo.zflow === 9" type="danger" @click="selfDeliveryCompleted">配送完成</el-button>-->
    </el-row>

    <!--    <el-dialog class="elDialog" :close-on-click-modal="false" title="修改订单" :visible.sync="dialogVisible" width="800px" append-to-body>-->
    <!--      <OrderInfo v-if="dialogVisible" ref="orderInfo" :ord-id="orderId" @click-back="onCancel" />-->
    <!--    </el-dialog>-->
    <el-dialog class="elDialog" :close-on-click-modal="false" title="拣货单修改" :visible.sync="dialogVisible" width="950px" append-to-body>
      <addPicking v-if="dialogVisible" :sid="pickingSid"  @handleClose="onCancel" @handleShow="handleShow"/>
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="出库单" :visible.sync="goodsLocationDialogVisible" width="1000px" append-to-body>
      <goodsLocation v-if="goodsLocationDialogVisible" ref="goodsLocation" :goods-list="goods" :show-btn="true" :order="orderInfo" :inventory-order-out="inventoryOrderOut" @closeDialog="closeDialog"></goodsLocation>
    </el-dialog>
    <el-dialog class="elDialog" :close-on-click-modal="false" title="追加小费" :visible.sync="addTipDialogVisible" width="400px" append-to-body>
      <div class="flex marginBottom10">
        <div class="flex alignItemsCenter marginRight10">调度费用:</div>
        <el-select v-model="tip" placeholder="请选择追加金额">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <el-row type="flex" justify="end">
        <el-button type="primary" plain @click="addTipDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addTip">确 认</el-button>
      </el-row>
    </el-dialog>
    <DeliveryOrderDialog :order-id="orderId" :title="title" :delivery-order-visible="deliveryOrderVisible" @queryList="refreshPage" @closeHandle="deliveryOrderVisible = false" />
    <imDialog ref="imDialog" :order-id="orderId" @pushData="pushData" />

    <el-dialog class="videoDialog" :close-on-click-modal="false" title="监控视频" :visible.sync="videoDialog" width="50%" append-to-body>
      <i-mou-video v-if="videoDialog" :device-id="deviceId" channel-id="0" :pwd="pwd" />
    </el-dialog>

    <el-dialog v-if="cancerDialog" class="videoDialog" :close-on-click-modal="false" title="取消原因" :visible.sync="cancerDialog" width="350px" append-to-body>
      <div class="flex alignItemsCenter marginBottom10">
        <div class="marginRight10">取消原因:</div>
        <el-select v-model="reason" clearable placeholder="请选择">
          <el-option v-for="item in reasonOptions" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </div>
      <el-row type="flex" justify="end">
        <el-button type="primary" plain @click="cancerDia">取 消</el-button>
        <el-button type="primary" :loading="refuseLoading" @click="refuse">确 认</el-button>
      </el-row>
    </el-dialog>

    <el-dialog v-if="storeLocationDialogVisible" class="elDialog" :close-on-click-modal="false" title="门店位置" :visible.sync="storeLocationDialogVisible" width="800px" append-to-body>
      <getStoreLocation v-if="storeLocationDialogVisible" :store-id="orderInfo.rstore" />
    </el-dialog>
    <el-dialog v-if="rideLocationDialogVisible" class="elDialog" :close-on-click-modal="false" title="骑手位置" :visible.sync="rideLocationDialogVisible" width="800px" append-to-body>
      <getRideLocation v-if="rideLocationDialogVisible" :delivery-id="deliveryId" />
    </el-dialog>

    <el-dialog v-if="replyDialogVisible" class="videoDialog" :close-on-click-modal="false" title="催单回复" :visible.sync="replyDialogVisible" width="350px" append-to-body>
      <div class="flex alignItemsCenter marginBottom10">
        <div class="marginRight10">回复类型:</div>
        <el-select v-model="replyType" clearable placeholder="请选择">
          <el-option v-for="item in replyOptions" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="marginBottom10">
        <div class="marginRight10">回复内容:</div>
        <el-input v-model="replyContent" class="marginTop5" type="textarea"></el-input>
      </div>
      <el-row type="flex" justify="end">
        <el-button type="primary" plain>取 消</el-button>
        <el-button type="primary" @click="reply">确 认</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import OrderInfo from "./orderInfo.vue";
import DeliveryOrderDialog from "@/views/delivery/deliveryOrderDialog";
import goodsLocation from "@/views/store/goodsLocation.vue";
import imDialog from "./dialog/imDialog";
import iMouVideo from "@/components/ImouVideo/iMouVideo";
import addPicking from "@/views/order/dialog/addPicking.vue";
import baseUrls from "@/api/baseUrls";
import deliveryPlatform from "@/api/delivery.js";
import getStoreLocation from "@/views/order/getStoreLocation.vue";
import getRideLocation from "@/views/order/getRideLocation.vue";

export default {
  name: "OrderListInfo",
  components: {
    getStoreLocation,
    getRideLocation,
    addPicking,
    // NoData,
    DeliveryOrderDialog,
    goodsLocation,
    imDialog,
    iMouVideo,
  },
  filters: {
    unescapeIcon(icon) {
      return unescape(icon.replace(/&#x/g, "%u").replace(/;/g, ""));
    },
    deliveryPlatform(value) {
      let str = value.split(",")
      let result = ''
      for (let i = 0; i < str.length; i++) {
        if (result.length > 0) {
          result += ','
        }
        result += deliveryPlatform[str[i]]
      }
      return result;
    },
    deliveryPlatformOrder(value) {
      if (value) {
        return '[' + deliveryPlatform[value] + ']'
      }
      return ''
    },
    refundType(value) {
      // 0初始化，10申请，20拒绝，30仲裁，40关闭，50成功，60失败
      if (value === 0) {
        return "初始化";
      } else if (value === 10) {
        return "申请";
      } else if (value === 20) {
        return "拒绝";
      } else if (value === 30) {
        return "仲裁";
      } else if (value === 40) {
        return "关闭";
      } else if (value === 50) {
        return "成功";
      } else if (value === 60) {
        return "失败";
      }
    },
    time(value) {
      return moment(new Date(value)).format("YYYY-MM-DD HH:mm:ss");
    },
    requestDelivery(value) {
      return value.tarriveReq + "-" + moment(value.tarriveEnd).format("HH:mm");
    },
    Hour(value) {
      let date = new Date(value)
      let m = moment(date)
      return m.format("HH:mm");
    },
    parsePrice(price) {
      if (price === null || price === undefined || price === "null" || price === "undefined") {
        return 0;
      } else if (price === 0) {
        return 0;
      } else {
        return (price / 100).toFixed(2);
      }
    },
    deliveryType(type) {
      if (type === 60) {
        return "取消配送";
      } else if (type === 10) {
        return "下单成功";
      } else if (type === 20) {
        return "接单成功";
      } else if (type === 30) {
        return "骑手到店";
      } else if (type === 40) {
        return "骑手派送中";
      } else if (type === 50) {
        return "已送达";
      } else if (type === 70) {
        return "没有合适的配送";
      } else {
        return "";
      }
    },
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    orderId: {
      type: [String, Object, Number],
    },
  },
  data() {
    return {
      cancerDialog: false,
      btnLoading: false,
      videoDialog: false,
      imDialogVisible: false,
      dialogVisible: false,
      replyDialogVisible: false,
      deliveryOrderVisible: false,
      addTipDialogVisible: false,
      storeLocationDialogVisible: false,
      goodsLocationDialogVisible: false,
      rideLocationDialogVisible: false,
      noGoodsUrl: require("@/assets/noGoods.svg"),
      orderInfo: {},
      DeliveryData: [],
      tabs: "",
      inventoryOrderOut: "",
      msgUnReadCount: 0,
      activeNames: ["1", "2", "3", "4", "5"],
      goods: [],
      storeAddr: "",
      storeName: "",
      storeId: "",
      deviceList: [],
      pickingSid: "",
      hostList: [],
      videoList: [],
      refundList: [],
      deviceId: "",
      pwd: "",
      title: "人工配送下单",
      activeName: "配送中",
      reverse: true,
      activities: [
        {
          content: "下单成功",
          timestamp: "2018-04-15",
        },
        {
          content: "等待骑手接单",
          timestamp: "2018-04-13",
        },
        {
          content: "已到店",
          timestamp: "2018-04-11",
        },
        {
          content: "配送中",
          timestamp: "2018-04-11",
        },
      ],
      //门禁
      AccessControl: false,
      //灯光
      lighting: false,
      refuseLoading: false,
      cancelLoading: false,
      cancelMytLoading: false,
      options: [
        { value: 1, label: "加1元" },
        { value: 2, label: "加2元" },
        { value: 3, label: "加3元" },
        { value: 4, label: "加4元" },
        { value: 5, label: "加5元" },
      ],
      deliveryId: "",
      tip: "",
      inStoreMode: "info",
      reason: "", //取消理由
      reasonOptions: [
        { id: 1, label: "商品已送出" },
        // { id: 1, label: "商户取消" },
        // { id: 2, label: "配送商取消" },
        // { id: 3, label: "用户取消" },
        // { id: 4, label: "超时取消" },
        // { id: 5, label: "其他平台取消（商户主动取消）" },
        // { id: 9, label: "系统取消" },
        // { id: 10, label: "用户不接电话" },
        // { id: 11, label: "用户退单" },
        // { id: 12, label: "用户地址错误" },
        // { id: 13, label: "配送超出范围" },
        // { id: 14, label: "已被平台其他配送接单" },
        // { id: 99, label: "其他" },
      ],
      replyType: "",
      replyContent: "",
      replyOptions: [
        { id: 1, label: "备货中" },
        { id: 2, label: "已送出" },
        { id: 3, label: "天气原因" },
        { id: 4, label: "自定义" },
      ],
    };
  },
  watch: {
    orderId(id) {
      this.tabs = "2";
      if (!(id === "" && id === null)) {
        this.selectById(id);
        this.queryDelivery(id);
        this.storeDevice(id);
      }
    },
  },
  created() {
    this.selectById(this.orderId);
    this.queryDelivery(this.orderId);
  },
  methods: {
    showRideMap(deliveryId) {
      this.deliveryId = deliveryId
      this.rideLocationDialogVisible = true
    },
    //跳转
    handleViewGoods(sid, rsort, sortsData) {
      let routeData = this.$router.resolve({
        path: "/goods/goodsInfo", // path 要跳转的路由地址
        // query 要传递的参数
        query: {
          sid: sid,
          rsort: rsort,
          sortsData: sortsData,
        },
      });
      window.open(routeData.href, "_blank");
    },
    clickStoreLocation() {
      this.storeLocationDialogVisible = true;
    },
    formattedGoodsInfo(goodsName, goodsNum) {
      if (goodsName === undefined || goodsNum === undefined) return "";
      const names = goodsName.split(",");
      const nums = goodsNum.split(",");
      return names.map((name, index) => `${name}(${nums[index]})`).join(", ");
    },
    pushData(data) {
      this.msgUnReadCount = data.notReadCount;
    },
    openIm() {
      let url = `${this.baseUrls.mqorderIm}/updateMsgReadStatus/` + this.orderId;
      this.topsAjax.post(url).then((response) => {
        if (response > 0) {
          this.msgUnReadCount = 0;
        }
      });
      this.$refs.imDialog.open();
    },
    testWebSocket() {
      let url = `${this.baseUrls.order}/testWebSocket`;
      this.topsAjax.get(url).then((response) => {
        console.log("发送完成");
      });
    },
    ordState() {
      let url = `${this.baseUrls.order}/ordState`;
      let params = {
        ordId: this.orderId,
      };
      this.topsAjax.post(url, params).then((response) => {
        let jsonString = JSON.stringify(response).replace(/["{}]/g, "");
        this.$message.success(jsonString);
      });
    },
    cancerDia() {
      this.cancerDialog = false;
      this.reason = "";
    },
    openAddTip(del) {
      this.deliveryId = del.sid;
      this.addTipDialogVisible = true;
    },
    addTip() {
      let url = `${this.baseUrls.mqdelivery}/addTip`;
      let params = {
        sid: this.deliveryId,
        tip: this.tip,
      };
      this.topsAjax.post(url, params).then((response) => {
        this.$message.success("添加成功");
        this.$emit("refreshPage");
      });
    },
    onCancel() {
      this.dialogVisible = false;
      this.refreshPage();
    },
    handleShow() {
      this.refreshPage();
      this.dialogVisible = true
    },
    //催单回复
    reply() {
      if (this.replyType === "" || this.replyType === null) {
        this.$message({ message: "请选择回复类型", type: "warning" });
      } else {
        if (this.replyType === 4 && this.replyContent === "") {
          this.$message({ message: "请输入回复内容", type: "warning" });
        } else {
          //完成回復
        }
      }
    },
    //拒绝
    refuse() {
      if (this.reason === "" || this.reason === null) {
        this.$message.error("请选择拒绝理由！");
        return;
      }
      this.refuseLoading = true;
      let url = `${this.baseUrls.mqrefund}/refund`;
      let params = {
        orderId: this.orderId,
        actionType: 2,
        reason: this.reason,
      };
      this.topsAjax.post(url, params).then((response) => {
        this.$message.success("拒绝成功");
        this.$emit("refreshPage");
        this.refuseLoading = false;
      });
    },
    //取消订单
    cancel() {
      this.cancelLoading = true;
      let url = `${this.baseUrls.mqrefund}/refund`;
      let params = {
        orderId: this.orderId,
        actionType: 1,
      };
      this.topsAjax.post(url, params).then((response) => {
        this.cancelLoading = false;
        this.$message.success("取消完成");
        this.$emit("refreshPage");
      });
    },
    //麦芽田订单取消
    cancelMyt() {
      this.cancelMytLoading = true;
      let url = `${this.baseUrls.mqdelivery}/cancelMyt`;
      let params = {
        orderId: this.orderId,
        cancelCode: 99,
        cancelReason: "其它",
      };
      this.topsAjax.post(url, params).then((response) => {
        this.cancelMytLoading = false;
        this.$message.success("取消完成");
        this.$emit("refreshPage");
      });
    },
    openVideo(item) {
      this.deviceId = item.ccode;
      this.pwd = item.cpwd;
      this.videoDialog = true;
    },
    storeDevice(id) {
      let url = `${this.baseUrls.store}/getStoreDevice/` + id;
      this.topsAjax.get(url).then((response) => {
        if (response == undefined) {
          return
        }
 
        this.btnLoading = false;
        this.videoList = [];
        this.hostList = [];
        let tempHost = [];
        for (let i = 0; i < response.deviceList.length; i++) {
          let o = response.deviceList[i];
          if (o.btype === 4) {
            this.videoList.push(o);
          } else {
            tempHost.push(o);
          }
        }
        tempHost.forEach((d) => {
          d.terminalList.forEach((t) => {
            let o = {
              ccode: d.ccode,
              cname: t.cname,
              cterminalId: t.cterminalId,
              bstatus: t.bstatus,
            };
            this.hostList.push(o);
          });
        });
        this.storeName = response.cname;
        this.storeAddr = response.caddr;
        this.storeId = response.sid;
        //TODO 临时处理 再视频没调好之前不显示数据
        this.videoList = [];
      });
    },
    flash() {
      this.btnLoading = true;
      this.storeDevice(this.orderId);
    },
    // 刷新列表
    refreshPage() {
      this.$emit("refreshPage");
    },
    closeDialog() {
      this.goodsLocationDialogVisible = false;
    },
    /**
     * 根據id獲取取消記錄
     * @param id
     */
    selectRefund(id) {
      let url = `${this.baseUrls.mqrefund}/queryList`;
      this.topsAjax.get(url, { orderId: id }).then((response) => {
        this.refundList = response;
      });
    },
    /**
     * 根据id获取订单信息
     * @param id
     */
    selectById(id) {
      let url = `${this.baseUrls.third}/get/` + id;
      this.topsAjax.get(url).then((response) => {
        this.orderInfo = response.ordMain;
        this.msgUnReadCount = response.msgUnReadCount;
        if (this.orderInfo.zflow === 5) {
          this.tabs = "0";
          this.selectRefund(this.orderInfo.sid);
        } else if (this.orderInfo.zflow === 9 || this.orderInfo.zflow === 8 || this.orderInfo.zflow === 4 || this.orderInfo.zflow === 7) {
          this.tabs = "1";
        } else {
          this.tabs = "2";
        }
        this.queryOrdGoods(this.orderInfo.rpick);
      });
    },
    /**
     * 根据订单id获取订单商品
     * @param orderId
     * @returns {Promise<void>}
     */
    async queryOrdGoods(rpick) {
      let url = `${this.baseUrls.ordPicking}/selectById`;
      let params = { sid: rpick };
      this.topsAjax.get(url, params).then((res) => {
        this.goods = res.pickingDetailList;
      });
    },
    async queryDelivery(id) {
      this.DeliveryData = [];
      let url = `${this.baseUrls.mqdelivery}/queryList`;
      let params = {
        rOrder: id,
      };
      this.topsAjax.get(url, params).then((response) => {
        this.DeliveryData = response;
        // this.queryDeliveryInfo();
      });
    },
    async queryDeliveryInfo() {
      this.DeliveryData.forEach((p1) => {
        p1.detail = [];
        let url = `${this.baseUrls.deliveryInfo}/queryList`;
        let params = { rDelivery: p1.sid };
        this.topsAjax.get(url, params).then((res) => {
          p1.detail = res;
          p1.detail.forEach((p2) => {
            p2.tcreate = moment(p2.tcreate).format("YYYY-MM-DD hh:mm:ss");
          });
        });
      });
    },
    //配送完成
    selfDeliveryCompleted() {
      let url = `${this.baseUrls.order}/selfDeliveryCompleted`;
      this.topsAjax.post(url, { ordId: this.orderInfo.sid }).then((res) => {
        this.$message.success("订单完成");
        this.$emit("refreshPage");
      });
    },
    // 修改订单状态到取消
    updateCancel() {
      this.$confirm("将取消全部的运单，可能会造在订单无法按时送，确定要全部取消？", "系统警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.cancelMyt();
        })
        .catch(() => {});
    },
    //自提完成
    selfPickupFinish() {
      let url = `${this.baseUrls.order}/update`;
      let params = {
        sid: this.orderInfo.sid,
        zflow: 0,
        tbusinessReceipt: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      this.topsAjax.post(url, params).then((res) => {
        this.$message.success("订单完成");
        this.$emit("refreshPage");
      });
    },
    openPicking(rpick) {
      this.pickingSid = rpick;
      this.dialogVisible = true;
    },
    activateInStore() {
      let url = `${this.baseUrls.device}/activateInStore`;
      this.topsAjax.post(url, { sid: this.orderInfo.rstore, breachMode: true }).then((res) => {
        // this.inStoreMode = "success";
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    lampOrDoorSwitch(type) {
      let params = {
        storeId: this.orderInfo.rstore,
        type: type,
        control: type === 0 ? this.AccessControl : this.lighting,
      };
      let url = `${this.baseUrls.device}/lampOrDoorSwitch`;
      this.topsAjax.post(url, params).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    switchOperation(ccode, cterminalId, status, name) {
      let params = {
        terminalName: name,
        ccode: ccode,
        rstore: this.storeId,
        terminalId: cterminalId,
      };
      if (status) {
        params.busiType = 2001;
      } else {
        params.busiType = 3001;
      }
      this.topsAjax.post(`${this.baseUrls.device}/deviceOperation`, params).then((response) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    // 切換標簽
    handleTabs(arg) {
      if (arg.index === "0") {
      }
      if (arg.index === "1") {
        this.storeDevice(this.orderId);
      }
      if (arg.index === "2") {
      }
    },
    callPhone(toTel) {
      toTel = toTel.substr(0, 11);
      this.$confirm(`是否拨打[<span style="color: red;">${toTel}</span>]的电话？`, {
        dangerouslyUseHTMLString: true,
      })
        .then((_) => {
          // let agent = JSON.parse(sessionStorage.getItem("userInfo")).agent;
          // if (agent == null || agent == undefined) return this.$message.error("当前账号未绑定分机号，请联系管理员！");
          let url = `${baseUrls.call}/call?phone=${toTel}`;
          let params = {};
          this.topsAjax.get(url, params).then((res) => {
            let data = JSON.parse(res);
            if (data.status) {
              this.$message.success("拨号成功！");
            } else {
              this.$message.error(data.msg);
            }
          });
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__body {
    flex: 1;
  }
}
</style>

<style lang="scss">
.labelGray {
  .el-descriptions-item__label:not(.is-bordered-label) {
    color: #909399;
  }

  .el-descriptions-item__container .el-descriptions-item__content,
  .el-descriptions-item__container .el-descriptions-item__label {
    display: inline-flex;
    justify-content: flex-end;
    margin-right: 10px;
  }
}

.ordListInfo {
  .el-card__body,
  .el-main {
    padding: 0;
  }

  .el-collapse-item__header {
    height: auto;
    line-height: 20px;
    background-color: rgb(247, 248, 250) !important;
  }

  .el-collapse-item__wrap {
    background-color: rgb(247, 248, 250) !important;
  }
}

.el-collapse-item__content {
  padding-bottom: 0;
}

.el-descriptions--medium:not(.is-bordered) .el-descriptions-item__cell {
  padding-top: 0;
}

.green {
  background-color: #60e722;
}

.center {
  display: flex;
  justify-content: center;
}

.circle {
  width: 25px; /* 圆的直径 */
  height: 25px; /* 圆的直径 */
  border-radius: 50%; /* 使元素成为圆形的关键属性 */
}

.layout {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
</style>
<style lang="scss" scoped>
.red {
  background-color: gray;
}

.circular {
  border-radius: 50%;
}
</style>
