var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container paddingTop10 orderListInfo" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "marginLeft10",
          on: { "tab-click": _vm.handleTabs },
          model: {
            value: _vm.tabs,
            callback: function ($$v) {
              _vm.tabs = $$v
            },
            expression: "tabs",
          },
        },
        [
          _vm.orderInfo.zflow === 5
            ? _c("el-tab-pane", { attrs: { label: "申请动态", name: "0" } })
            : _vm.orderInfo.zflow !== 1
            ? _c("el-tab-pane", { attrs: { label: "配送跟踪", name: "1" } })
            : _vm._e(),
          _c("el-tab-pane", { attrs: { label: "订单详情", name: "2" } }),
          _vm.orderInfo.zflow === 8
            ? _c("el-tab-pane", { attrs: { label: "门店智控", name: "3" } })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "container__body scrollbar" }, [
        _vm.tabs === "0"
          ? _c(
              "div",
              [
                _c(
                  "el-timeline",
                  {
                    staticClass: "marginLeft10 marginTop10",
                    attrs: { reverse: true },
                  },
                  _vm._l(_vm.refundList, function (activity, index) {
                    return _c(
                      "el-timeline-item",
                      {
                        key: index,
                        attrs: { timestamp: _vm._f("time")(activity.tcreate) },
                      },
                      [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("refundType")(activity.ztype)) +
                              " "
                          ),
                          _c("span", { staticClass: "marginLeft5" }, [
                            _vm._v(_vm._s(_vm._f("Hour")(activity.tcreate))),
                          ]),
                          _vm._v(" "),
                          activity.ztype != 50
                            ? _c("span", { staticClass: "marginLeft5" }, [
                                _vm._v(_vm._s(activity.desc)),
                              ])
                            : _vm._e(),
                        ]),
                        activity.ztype === 10
                          ? _c(
                              "div",
                              [
                                _vm._v(" 退款商品: "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content: _vm.formattedGoodsInfo(
                                        activity.crefGoodsName,
                                        activity.crefGoodsNum
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-ellipsis" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formattedGoodsInfo(
                                              activity.crefGoodsName,
                                              activity.crefGoodsNum
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        activity.ztype === 10
                          ? _c(
                              "div",
                              [
                                _vm._v(" 退款原因: "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      placement: "top",
                                      content: activity.creason,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-ellipsis" },
                                      [_vm._v(_vm._s(activity.creason))]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.tabs === "1"
          ? _c(
              "div",
              { staticClass: "ordListInfo paddingLeft10 paddingRight10" },
              _vm._l(_vm.DeliveryData, function (delivery, index) {
                return _c(
                  "div",
                  { key: delivery.sid },
                  [
                    _c(
                      "el-card",
                      { staticClass: "box-card bcGrey marginBottom10" },
                      [
                        _c(
                          "el-collapse",
                          { attrs: { accordion: "" } },
                          [
                            _c(
                              "el-collapse-item",
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex directionColumn padding10",
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "[" +
                                            _vm._s(
                                              _vm._f("deliveryPlatform")(
                                                delivery.c_platform_name
                                              )
                                            ) +
                                            "]第" +
                                            _vm._s(index + 1) +
                                            "次下单"
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "fs11 C86909c" },
                                        [
                                          _vm._v(
                                            "状态: " +
                                              _vm._s(
                                                _vm._f("deliveryType")(
                                                  delivery.z_delivery_status
                                                )
                                              ) +
                                              " · " +
                                              _vm._s(
                                                _vm._f("Hour")(
                                                  delivery.t_create
                                                )
                                              ) +
                                              " · " +
                                              _vm._s(delivery.n_price) +
                                              "元"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "fs11 C86909c" },
                                        [
                                          _vm._v(
                                            "备注:" + _vm._s(delivery.c_remark)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "marginLeft10" },
                                  [
                                    _c(
                                      "el-timeline",
                                      {
                                        staticClass: "marginTop10",
                                        attrs: { reverse: _vm.reverse },
                                      },
                                      _vm._l(
                                        delivery.deliveryInfo,
                                        function (info) {
                                          return _c(
                                            "el-timeline-item",
                                            {
                                              key: info.sid,
                                              attrs: {
                                                "hide-timestamp": true,
                                                timestamp: _vm._f("Hour")(
                                                  info.tcreate
                                                ),
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("deliveryType")(
                                                      info.zstageStatus
                                                    )
                                                  ) +
                                                    " · " +
                                                    _vm._s(
                                                      _vm._f("Hour")(
                                                        info.tcreate
                                                      )
                                                    )
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "fs11 C86909c" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(info.cdescribe) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              info.zstageStatus === 20
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex marginTop5",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "deliveryPlatformOrder"
                                                            )(
                                                              delivery.s_logistic
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.orderInfo.zflow === 7
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex marginTop5",
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openAddTip(
                                                                delivery
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("加小费")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              info.zstageStatus === 20 ||
                                              info.zstageStatus === 30 ||
                                              info.zstageStatus === 40 ||
                                              info.zstageStatus === 50
                                                ? _c(
                                                    "div",
                                                    { staticClass: "C86909c" },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "fs11" },
                                                        [
                                                          _vm._v(
                                                            "配送员：" +
                                                              _vm._s(
                                                                delivery.c_shipper_name
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        { staticClass: "fs11" },
                                                        [
                                                          _vm._v(" 联系方式："),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "purple fs11",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.callPhone(
                                                                      delivery.c_shipper_phone
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  delivery.c_shipper_phone
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass: "purple",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showRideMap(
                                                                delivery.sid
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("查看位置")]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm.tabs === "2"
          ? _c(
              "div",
              [
                _c(
                  "el-collapse",
                  {
                    staticClass: "paddingRight10 paddingLeft10 labelGray",
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "订单货品", name: "1" } },
                      [
                        _vm._l(_vm.goods, function (product, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "marginBottom10" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "bg-purple" },
                                      [
                                        product.goodsId === undefined ||
                                        product.n_total === undefined ||
                                        product.n_total === "0"
                                          ? _c("el-image", {
                                              staticClass:
                                                "h100 w100 borderRed",
                                              attrs: {
                                                src: _vm.noGoodsUrl,
                                                "preview-src-list": [
                                                  _vm.noGoodsUrl,
                                                ],
                                                fit: "fit",
                                              },
                                            })
                                          : _c("el-image", {
                                              staticClass: "h100 w100",
                                              attrs: {
                                                src: product.c_master_url,
                                                "preview-src-list": [
                                                  product.c_master_url,
                                                ],
                                                fit: "fit",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("el-col", { attrs: { span: 16 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bg-purple-light h100 flex directionColumn",
                                        staticStyle: {
                                          "justify-content": "space-between",
                                        },
                                      },
                                      [
                                        product.goodsId !== undefined
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "blue",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleViewGoods(
                                                      product.goodsId,
                                                      product.r_sort,
                                                      null
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(product.c_name))]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "elRed" },
                                              [_vm._v(_vm._s(product.c_name))]
                                            ),
                                        _c("div", [
                                          _vm._v(
                                            "货号：" + _vm._s(product.c_barcode)
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "textRight",
                                            style: {
                                              color:
                                                product.num > 1
                                                  ? "red"
                                                  : "black",
                                            },
                                          },
                                          [_vm._v("x" + _vm._s(product.num))]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: product.c_remake,
                                                expression: "product.c_remake",
                                              },
                                            ],
                                            staticStyle: {
                                              position: "absolute",
                                              bottom: "0",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "货品备注: " +
                                                _vm._s(product.c_remake)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "bcGrey h100 padding10 borderRadius5",
                          },
                          [
                            _c("div", { staticClass: "grey" }, [
                              _vm._v("用户备注"),
                            ]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.orderInfo.corderRemark)),
                            ]),
                          ]
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-collapse-item",
                      { attrs: { name: "2" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "justify-content": "space-between",
                                width: "100%",
                              },
                            },
                            [
                              _c("div", [_vm._v("顾客信息")]),
                              _c("div", { on: { click: _vm.openIm } }, [
                                _c("i", {
                                  staticClass:
                                    "marginLeft5 marginRight5 header-icon el-icon-chat-dot-round",
                                }),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.msgUnReadCount > 0,
                                        expression: "msgUnReadCount > 0",
                                      },
                                    ],
                                    staticClass: "el-badge__content",
                                  },
                                  [_vm._v(_vm._s(_vm.msgUnReadCount) + " ")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "el-descriptions",
                          { attrs: { column: 1 } },
                          [
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "预定人" } },
                              [_vm._v(_vm._s(_vm.orderInfo.ccustomerName))]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "手机号" } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "blue",
                                    on: {
                                      click: function ($event) {
                                        return _vm.callPhone(
                                          _vm.orderInfo.ccustomerPhone
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.orderInfo.ccustomerPhone))]
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "隐私号" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.orderInfo.ccustomerPhonePrivate)
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "订单金额", name: "3" } },
                      [
                        _c(
                          "el-descriptions",
                          { attrs: { column: 1 } },
                          [
                            _c(
                              "el-descriptions-item",
                              { key: "配送費", attrs: { label: "配送費" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("parsePrice")(
                                      _vm.orderInfo.ndeliveryPrice
                                    )
                                  ) + " 元"
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { key: "顾客实付", attrs: { label: "顾客实付" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("parsePrice")(
                                      _vm.orderInfo.ncustomerPrice
                                    )
                                  ) + " 元"
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { key: "商家实收", attrs: { label: "商家实收" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("parsePrice")(
                                      _vm.orderInfo.ndiscountPrice
                                    )
                                  ) + " 元"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "订单信息", name: "4" } },
                      [
                        _c(
                          "el-descriptions",
                          { attrs: { column: 1 } },
                          [
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "订单编号" } },
                              [_vm._v(_vm._s(_vm.orderInfo.ccode))]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "下单时间" } },
                              [
                                _vm._v(
                                  _vm._s(_vm._f("time")(_vm.orderInfo.tcreate))
                                ),
                              ]
                            ),
                            _vm.orderInfo.zdelivery === 0
                              ? _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "要求送达" } },
                                  [_vm._v(_vm._s(_vm.orderInfo.tpick))]
                                )
                              : _vm._e(),
                            _vm.orderInfo.zdelivery === 1
                              ? _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "自提时间" } },
                                  [_vm._v(_vm._s(_vm.orderInfo.tpick))]
                                )
                              : _vm._e(),
                            _vm.orderInfo.tbusinessReceipt
                              ? _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "完成时间" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.orderInfo.tbusinessReceipt)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "配送门店" } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "blue",
                                    on: { click: _vm.clickStoreLocation },
                                  },
                                  [_vm._v(_vm._s(_vm.orderInfo.sshop))]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !(_vm.orderInfo.zflow === 1 || _vm.orderInfo.zflow === 2)
                      ? _c(
                          "el-collapse-item",
                          { attrs: { title: "配送信息", name: "5" } },
                          [
                            _c(
                              "el-descriptions",
                              { attrs: { column: 1 } },
                              [
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "配送方式" } },
                                  [_vm._v(_vm._s(_vm.orderInfo.sdelivery))]
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "配送费用" } },
                                  [_vm._v(_vm._s(_vm.orderInfo.ndeliveryPrice))]
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "骑手姓名" } },
                                  [_vm._v(_vm._s(_vm.orderInfo.cdeliveryName))]
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "骑手电话" } },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "blue",
                                        on: {
                                          click: function ($event) {
                                            return _vm.callPhone(
                                              _vm.orderInfo.ccustomerPhone
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.orderInfo.cdeliveryPhone)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.tabs === "3"
          ? _c(
              "div",
              {
                staticClass:
                  "hHP flex directionColumn marginLeft10 marginRight10",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "padding10 border borderRadius10 marginBottom10",
                  },
                  [
                    _c("div", { staticClass: "fs13" }, [
                      _vm._v(_vm._s(_vm.storeName)),
                    ]),
                    _c("a", { staticClass: "purple fs13" }, [
                      _vm._v(_vm._s(_vm.storeAddr)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "padding10 border borderRadius10 marginBottom10 hHP",
                  },
                  [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "flex directionColumn" },
                      _vm._l(_vm.videoList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "flex alignItemsCenter directionColumn",
                          },
                          [
                            _c("a", {
                              staticClass: "el-icon-video-play fs150 ft-color",
                              on: {
                                click: function ($event) {
                                  return _vm.openVideo(item)
                                },
                              },
                            }),
                            _c("p", [_vm._v(_vm._s(item.cname))]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "padding10 border borderRadius10" },
                  [
                    _vm._m(1),
                    _c(
                      "el-row",
                      { staticClass: "padding10" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c("el-switch", {
                              attrs: { "inactive-text": "远程门禁" },
                              on: {
                                change: function ($event) {
                                  return _vm.lampOrDoorSwitch(0)
                                },
                              },
                              model: {
                                value: _vm.AccessControl,
                                callback: function ($$v) {
                                  _vm.AccessControl = $$v
                                },
                                expression: "AccessControl",
                              },
                            }),
                            _c("el-switch", {
                              attrs: { "inactive-text": "远程灯光" },
                              on: {
                                change: function ($event) {
                                  return _vm.lampOrDoorSwitch(1)
                                },
                              },
                              model: {
                                value: _vm.lighting,
                                callback: function ($$v) {
                                  _vm.lighting = $$v
                                },
                                expression: "lighting",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "h60 w150",
                                attrs: { type: "primary" },
                                on: { click: _vm.activateInStore },
                              },
                              [_vm._v("到店模式")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "adding10",
                        staticStyle: { color: "#b5b4bf" },
                        attrs: { href: "#" },
                      },
                      [_vm._v("更多控制")]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "el-row",
        {
          staticClass: "grey paddingTop10",
          staticStyle: {
            "text-align": "center",
            "border-top": "1px solid rgb(235, 238, 245)",
          },
        },
        [
          _vm.tabs === "0"
            ? _c(
                "div",
                { staticClass: "padding10 borderRadius5" },
                [
                  _vm.orderInfo.zrefund === 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function ($event) {
                              _vm.cancerDialog = true
                            },
                          },
                        },
                        [_vm._v("拒绝")]
                      )
                    : _vm._e(),
                  _vm.orderInfo.zrefund === 0
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.cancelLoading,
                            type: "primary",
                          },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v("同意")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.tabs === "1"
            ? _c(
                "div",
                { staticClass: "padding10 borderRadius5" },
                [
                  _vm.orderInfo.zdelivery === 2
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.selfPickupFinish },
                        },
                        [_vm._v("自提完成")]
                      )
                    : _vm._e(),
                  _vm.orderInfo.zflow === 7 ||
                  _vm.orderInfo.zflow === 8 ||
                  _vm.orderInfo.zflow === 9 ||
                  (_vm.orderInfo.zflow === 5 && _vm.DeliveryData.length > 0)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            plain: "",
                            loading: _vm.cancelMytLoading,
                          },
                          on: { click: _vm.updateCancel },
                        },
                        [_vm._v("全部取消")]
                      )
                    : _vm._e(),
                  _vm.orderInfo.zflow === 3
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.replyDialogVisible = true
                            },
                          },
                        },
                        [_vm._v("回复用户")]
                      )
                    : _vm._e(),
                  _vm.orderInfo.zflow === 4 ||
                  _vm.orderInfo.zflow === 7 ||
                  _vm.orderInfo.zflow === 8 ||
                  _vm.orderInfo.zflow === 9 ||
                  (_vm.orderInfo.zflow === 5 && _vm.DeliveryData.length > 0)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: {
                            click: function ($event) {
                              _vm.deliveryOrderVisible = true
                            },
                          },
                        },
                        [_vm._v("再次配送")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.tabs === "2"
            ? _c(
                "div",
                { staticClass: "padding10 borderRadius5" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.openPicking(_vm.orderInfo.rpick)
                        },
                      },
                    },
                    [_vm._v("修改拣货单")]
                  ),
                  _vm.orderInfo.zflow === 1 || _vm.orderInfo.zflow === 2
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.deliveryOrderVisible = true
                            },
                          },
                        },
                        [_vm._v("人工配送下单")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.ordState } }, [
            _vm._v("麦芽田查看状态"),
          ]),
          _c("el-button", { on: { click: _vm.testWebSocket } }, [
            _vm._v("发送websocket"),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "拣货单修改",
            visible: _vm.dialogVisible,
            width: "950px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("addPicking", {
                attrs: { sid: _vm.pickingSid },
                on: { handleClose: _vm.onCancel, handleShow: _vm.handleShow },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "出库单",
            visible: _vm.goodsLocationDialogVisible,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.goodsLocationDialogVisible = $event
            },
          },
        },
        [
          _vm.goodsLocationDialogVisible
            ? _c("goodsLocation", {
                ref: "goodsLocation",
                attrs: {
                  "goods-list": _vm.goods,
                  "show-btn": true,
                  order: _vm.orderInfo,
                  "inventory-order-out": _vm.inventoryOrderOut,
                },
                on: { closeDialog: _vm.closeDialog },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "追加小费",
            visible: _vm.addTipDialogVisible,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addTipDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex marginBottom10" },
            [
              _c(
                "div",
                { staticClass: "flex alignItemsCenter marginRight10" },
                [_vm._v("调度费用:")]
              ),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择追加金额" },
                  model: {
                    value: _vm.tip,
                    callback: function ($$v) {
                      _vm.tip = $$v
                    },
                    expression: "tip",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "end" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.addTipDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addTip } },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeliveryOrderDialog", {
        attrs: {
          "order-id": _vm.orderId,
          title: _vm.title,
          "delivery-order-visible": _vm.deliveryOrderVisible,
        },
        on: {
          queryList: _vm.refreshPage,
          closeHandle: function ($event) {
            _vm.deliveryOrderVisible = false
          },
        },
      }),
      _c("imDialog", {
        ref: "imDialog",
        attrs: { "order-id": _vm.orderId },
        on: { pushData: _vm.pushData },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "监控视频",
            visible: _vm.videoDialog,
            width: "50%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoDialog = $event
            },
          },
        },
        [
          _vm.videoDialog
            ? _c("i-mou-video", {
                attrs: {
                  "device-id": _vm.deviceId,
                  "channel-id": "0",
                  pwd: _vm.pwd,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.cancerDialog
        ? _c(
            "el-dialog",
            {
              staticClass: "videoDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "取消原因",
                visible: _vm.cancerDialog,
                width: "350px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.cancerDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex alignItemsCenter marginBottom10" },
                [
                  _c("div", { staticClass: "marginRight10" }, [
                    _vm._v("取消原因:"),
                  ]),
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.reason,
                        callback: function ($$v) {
                          _vm.reason = $$v
                        },
                        expression: "reason",
                      },
                    },
                    _vm._l(_vm.reasonOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "end" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.cancerDia },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.refuseLoading },
                      on: { click: _vm.refuse },
                    },
                    [_vm._v("确 认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.storeLocationDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "elDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "门店位置",
                visible: _vm.storeLocationDialogVisible,
                width: "800px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.storeLocationDialogVisible = $event
                },
              },
            },
            [
              _vm.storeLocationDialogVisible
                ? _c("getStoreLocation", {
                    attrs: { "store-id": _vm.orderInfo.rstore },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.rideLocationDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "elDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "骑手位置",
                visible: _vm.rideLocationDialogVisible,
                width: "800px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.rideLocationDialogVisible = $event
                },
              },
            },
            [
              _vm.rideLocationDialogVisible
                ? _c("getRideLocation", {
                    attrs: { "delivery-id": _vm.deliveryId },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.replyDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "videoDialog",
              attrs: {
                "close-on-click-modal": false,
                title: "催单回复",
                visible: _vm.replyDialogVisible,
                width: "350px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.replyDialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex alignItemsCenter marginBottom10" },
                [
                  _c("div", { staticClass: "marginRight10" }, [
                    _vm._v("回复类型:"),
                  ]),
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.replyType,
                        callback: function ($$v) {
                          _vm.replyType = $$v
                        },
                        expression: "replyType",
                      },
                    },
                    _vm._l(_vm.replyOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "marginBottom10" },
                [
                  _c("div", { staticClass: "marginRight10" }, [
                    _vm._v("回复内容:"),
                  ]),
                  _c("el-input", {
                    staticClass: "marginTop5",
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.replyContent,
                      callback: function ($$v) {
                        _vm.replyContent = $$v
                      },
                      expression: "replyContent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "end" } },
                [
                  _c("el-button", { attrs: { type: "primary", plain: "" } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.reply } },
                    [_vm._v("确 认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "borderBottom",
        staticStyle: { "padding-bottom": "5px", "margin-bottom": "5px" },
      },
      [_c("span", { staticClass: "fs20" }, [_vm._v("门店监控")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "borderBottom",
        staticStyle: { "padding-bottom": "5px", "margin-bottom": "5px" },
      },
      [_c("span", { staticClass: "fs20" }, [_vm._v(" 门店智控")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }