<template>
  <!--  查看路线-->
  <div class="getDeliveryRoutes">
    <div id="container" class="hHP wHP" />
    <!--    <el-amap :center="center" :zoom="zoom">-->
    <!--      <el-amap-marker v-for="(marker, index) in markerList" :key="index" :label="marker.label" :position="marker.position" />-->
    <!--    </el-amap>-->
  </div>
</template>

<script>
export default {
  name: "GetDeliveryRoutes",
  props: {
    ord: {
      type: [String, Object, Array],
      default: "",
    },
  },
  data() {
    return {
      orderInfo: {},
      center: [116.397455, 39.909192],
      zoom: 12,
      markers: [],
      markerList: [],
      merchantOptions: [],
      distance: "",
      storeLocation: [],
      customerLocation: [],
    };
  },
  mounted() {
    this.orderInfo = this.ord;
    this.storeLocation = [this.orderInfo.caddrLongitude, this.orderInfo.caddrLatitude];
    this.selectOrderById(this.orderInfo.rstore);
    // if (this.orderInfo.zflow !== 1) {
    //   this.getRiderLocation(this.orderInfo.sid);
    // }
    // this.getRoute();
  },
  methods: {
    getRoute(center, storeLocation, customerLocation) {
      var map = new AMap.Map("container", {
        center: center,
        zoom: 14,
      });
      var ridingOption = {
        policy: 1,
      };
      var riding = new AMap.Riding(ridingOption);
      riding.search(
        customerLocation,
        storeLocation,
        function (status, result) {
          if (status === "complete") {
            if (result.routes && result.routes.length) {
              this.drawRoute(result.routes[0], map);
            }
          } else {
          }
        }.bind(this)
      );
    },
    drawRoute(route, map) {
      var path = this.parseRouteToPath(route);
      var startMarker = new AMap.Marker({
        position: path[0],
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/start.png",
        anchor: "bottom-center",
        map: map,
      });
      var endMarker = new AMap.Marker({
        position: path[path.length - 1],
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/end.png",
        anchor: "bottom-center",
        map: map,
      });
      var routeLine = new AMap.Polyline({
        path: path,
        isOutline: true,
        outlineColor: "#ffeeee",
        borderWeight: 2,
        strokeWeight: 5,
        strokeColor: "#0091ff",
        strokeOpacity: 0.9,
        lineJoin: "round",
      });
      map.add(routeLine);
      // 调整视野达到最佳显示区域
      map.setFitView([startMarker, endMarker, routeLine]);
    },
    parseRouteToPath(route) {
      var path = [];
      for (var i = 0, l = route.rides.length; i < l; i++) {
        var step = route.rides[i];

        for (var j = 0, n = step.path.length; j < n; j++) {
          path.push(step.path[j]);
        }
      }
      return path;
    },
    /**
     * 获取门店信息
     * @param id
     */
    async selectOrderById(id) {
      let url = `${this.baseUrls.store}/get/` + id;
      try {
        const response = await this.topsAjax.get(url);
        this.customerLocation = [response.clongitude, response.clatitude];
        this.getCenter();
        // this.addStoreMarker();
      } catch (error) {
        // 可以向用户显示错误信息
      }
    },
    // /**
    //  * 获取骑手经纬度
    //  */
    // getRiderLocation(orderId) {
    //   let url = `${this.baseUrls.delivery}/getRiderLocation`;
    //   let params = {
    //     orderId: orderId,
    //   };
    //   this.topsAjax.get(url, params).then((res) => {
    //     if (res.code === 1) {
    //       let lng = res.data.rider_longitude;
    //       let lat = res.data.rider_latitude;
    //       this.markers.push({ position: [lng, lat], label: { content: "骑手位置", offset: [-75, -30] } });
    //     } else {
    //       this.$message({ message: res.message, type: "error" });
    //     }
    //   });
    // },
    /**
     * 获取中心点
     */
    getCenter() {
      let totalLat = parseFloat(this.storeLocation[0]);
      let totalLon = parseFloat(this.storeLocation[1]);
      totalLat += parseFloat(this.customerLocation[0]);
      totalLon += parseFloat(this.customerLocation[1]);
      const avgLon = totalLon / 2;
      const avgLat = totalLat / 2;
      this.getRoute([avgLat, avgLon], this.storeLocation, this.customerLocation);
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    // /**
    //  * 计算距离
    //  */
    // calculateDistance() {
    //   if (this.markers.length >= 2) {
    //     const earthRadius = 6371;
    //     const lat1 = parseFloat(this.markers[0].position[1]);
    //     const lon1 = parseFloat(this.markers[0].position[0]);
    //     const lat2 = parseFloat(this.markers[1].position[1]);
    //     const lon2 = parseFloat(this.markers[1].position[0]);
    //     const dLat = this.deg2rad(lat2 - lat1);
    //     const dLon = this.deg2rad(lon2 - lon1);
    //     const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    //     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    //     const distance = earthRadius * c;
    //     this.distance = distance.toFixed(2) + "公里";
    //     this.$set(this.markers[0].label, "content", "距离门店地址" + this.distance); // 更新标记内容为距离
    //     this.getCenter(); // 更新中心点
    //     this.markerList = this.markers;
    //   }
    // },
    // /**
    //  * 添加门店标记
    //  * @param longitude
    //  * @param latitude
    //  */
    // addStoreMarker(longitude, latitude) {
    //   this.markers.push({ position: [longitude, latitude], label: {} });
    //   this.calculateDistance();
    // },
  },
};
</script>

<style lang="scss">
.getDeliveryRoutes {
  height: 400px;
}
</style>
