var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "noData" },
    [
      _c("el-empty", {
        staticClass: "bcWhite",
        attrs: { description: "暂无数据" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }