<template>
  <!-- 订单处理-->
  <div class="orderProcessCol">
    <div class="orderProcessCol__top">
      <div class="left">
        <div class="bcWhite box-shadow">
          <el-tabs v-model="queryParams.zFlow" class="bcWhite" type="card" @tab-click="handleTabClick">
            <el-tab-pane label="新订单" name="1">
              <span slot="label">
                新订单<span v-show="countOrderMap[1].n_count && countOrderMap[1].n_count > 0" class="el-badge__content">{{ countOrderMap[1].n_count | dot }} </span>
              </span>
            </el-tab-pane>
            <el-tab-pane label="异常订单" name="2">
              <span slot="label">
                异常订单<span v-show="countOrderMap[2].n_count && countOrderMap[2].n_count > 0" class="el-badge__content">{{ countOrderMap[2].n_count | dot }} </span>
              </span>
            </el-tab-pane>
            <el-tab-pane label="催单" name="3">
              <span slot="label">
                催单<span v-show="countOrderMap[3].n_count && countOrderMap[3].n_count > 0" class="el-badge__content">{{ countOrderMap[3].n_count | dot }} </span>
              </span>
            </el-tab-pane>
            <el-tab-pane label="配送异常" name="4">
              <span slot="label">
                配送异常<span v-show="countOrderMap[4].n_count && countOrderMap[4].n_count > 0" class="el-badge__content">{{ countOrderMap[4].n_count | dot }} </span>
              </span>
            </el-tab-pane>
            <el-tab-pane label="取消/退款" name="5">
              <span slot="label">
                取消/ 退款<span v-show="countOrderMap[5].n_count && countOrderMap[5].n_count > 0" class="el-badge__content">{{ countOrderMap[5].n_count | dot }} </span>
              </span>
            </el-tab-pane>
            <el-tab-pane label="预订单" name="10">
              <span slot="label">
                预订单<span v-show="countOrderMap[10].n_count && countOrderMap[10].n_count > 0" class="el-badge__content">{{ countOrderMap[10].n_count | dot }} </span>
              </span>
            </el-tab-pane>
            <el-tab-pane label="待抢单" name="7">
              <span slot="label">
                待抢单<span v-show="countOrderMap[7].n_count && countOrderMap[7].n_count > 0" class="el-badge__content">{{ countOrderMap[7].n_count | dot }} </span>
              </span>
            </el-tab-pane>
            <el-tab-pane label="待取货" name="8">
              <span slot="label">
                待取货<span v-show="countOrderMap[8].n_count && countOrderMap[8].n_count > 0" class="el-badge__content">{{ countOrderMap[8].n_count | dot }} </span>
              </span>
            </el-tab-pane>
            <el-tab-pane label="配送中" name="9">
              <span slot="label">
                配送中<span v-show="countOrderMap[9].n_count && countOrderMap[9].n_count > 0" class="el-badge__content">{{ countOrderMap[9].n_count | dot }} </span>
              </span>
            </el-tab-pane>
            <el-tab-pane label="待处理" name="11">
              <span slot="label">
                待处理<span v-show="countOrderMap[11].n_count && countOrderMap[11].n_count > 0" class="el-badge__content">{{ countOrderMap[11].n_count | dot }} </span>
              </span>
            </el-tab-pane>
            <el-tab-pane label="已完成" name="0">
              <span slot="label">
                已完成<span class="el-badge__content">{{ countOrderMap[0].n_count | dot }} </span>
              </span>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="bcWhite box-shadow">
          <el-select v-model="shopValue" class="marginRight10" filterable placeholder="全部门店" @change="handleSelectStore">
            <el-option v-for="item in storeOptions" :key="item.sid" :label="item.cname" :value="item.sid"></el-option>
          </el-select>
          <el-select v-model="deliveryValue" class="marginRight10" placeholder="" @change="handleSelectDelivery">
            <el-option v-for="item in deliveryOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select ref="sortSelect" v-model="sortingValue" class="marginRight10" placeholder="排序方式" @change="handleSelectSort">
            <el-option v-for="item in sortingOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
      <div class="right bcWhite box-shadow h100" style="min-width: 27%">
        <div v-if="queryParams.zFlow === '0'" style="background-color: rgb(235, 244, 246)" class="hHP flex directionColumn contentCenter">
          <div class="marginLeft20 marginBottom5">已完成</div>
          <div class="marginLeft20 fs20 fWB" style="color: rgb(0, 170, 48)">{{ countOrderMap[0].n_count }}</div>
        </div>
        <div v-if="!(queryParams.zFlow === '0')" class="hHP marginTop10 marginLeft20">
          <el-row class="marginBottom5" style="height: 23px">
            <el-col :span="4">
              <div class="fs13 pointer" @click="handleTabClick({ name: '1' })">新订单</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13">{{ countOrderMap[1].n_count }}</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13">预订单</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13">{{ countOrderMap[10].n_count }}</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13 pointer" @click="handleTabClick({ name: '3' })">催单</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13">{{ countOrderMap[3].n_count }}</div>
            </el-col>
          </el-row>
          <el-row class="marginBottom5 h20" style="height: 23px">
            <el-col :span="4">
              <div class="fs13 pointer" @click="handleTabClick({ name: '4' })">配送异常</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13">{{ countOrderMap[4].n_count }}</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13 pointer" @click="handleTabClick({ name: '5' })">取消退款</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13">{{ countOrderMap[5].n_count }}</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13 pointer" @click="handleTabClick({ name: '6' })">待配送</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13">{{ countOrderMap[6].n_count }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <div class="fs13 pointer" @click="handleTabClick({ name: '7' })">待配送</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13">{{ countOrderMap[7].n_count }}</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13 pointer" @click="handleTabClick({ name: '8' })">待配送</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13">{{ countOrderMap[8].n_count }}</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13 pointer" @click="handleTabClick({ name: '9' })">配送中</div>
            </el-col>
            <el-col :span="4">
              <div class="fs13">{{ countOrderMap[9].n_count }}</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="orderProcessCol__body">
      <div class="body">
        <div class="body__left flex directionColumn">
          <div v-if="queryParams.zFlow === '1' || queryParams.zFlow === '6'" class="h50 marginBottom10 box-shadow bcWhite wHP flex alignItemsCenter paddingLeft20">
            <div>
              自动发单
              <el-switch v-model="autoSend" class="marginLeft20" @change="handleAutoSend" />
            </div>
            <div class="marginLeft20">
              <el-select v-model="OrderFrequency" class="marginRight10" clearable filterable placeholder="请选择发单频次">
                <el-option v-for="item in frequencyOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="flex1 scrollAuto scrollbar">
            <div class="hHP">
              <no-data v-if="orderData.length === 0" />
              <OrderList v-if="orderData.length > 0" class="hHP" :order-list-data="orderData" @selectOrderId="selectOrderId" />
            </div>
          </div>
        </div>
        <div class="body__right">
          <no-data v-if="orderId == null || orderId === ''" class="box-shadow" />
          <OrderListInfo v-if="orderId != null && orderId !== ''" :order-id="orderId" class="bcWhite box-shadow" @refreshPage="refreshPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderList from "./orderList.vue";
import OrderListInfo from "./orderListInfo.vue";
import OrderConstant from "@/views/order/constants/orderConstant";
import orderConstant from "@/views/order/constants/orderConstant";
import lodash from "lodash";
import NoData from "@/components/noData.vue";

export default {
  name: "OrderProcessing",
  components: {
    NoData,
    OrderListInfo,
    OrderList,
  },
  filters: {
    dot(num) {
      if (num < 100) {
        return num;
      } else {
        return "99+";
      }
    },
  },
  props: {
    search: {
      type: [String, Number], // 根据需要指定数据类型
      default: "", // 设置一个默认值，如空字符串
    },
  },
  data() {
    return {
      //发单频次
      OrderFrequency: "",
      frequencyOptions: [
        { value: "20", label: "每次20单" },
        { value: "30", label: "每次30单" },
        { value: "50", label: "每次50单" },
        { value: "100", label: "每次100单" },
      ],
      autoSend: false,
      deliverySetId: "",
      orderId: "",
      queryParams: { zFlow: "1" },
      currentFlow: "1",
      storeOptions: [],
      shopValue: null,
      deliveryOptions: [
        { value: "-1", label: "全部渠道" },
        { value: "0", label: "美团闪购" },
        { value: "1", label: "京东到家" },
        { value: "2", label: "饿了么" },
        { value: "3", label: "米度优选" },
        { value: "6", label: "TOPS订单" },
      ],
      deliveryValue: "-1",
      sortingOptions: [
        { value: "0", label: "下单时间倒序", field: "t_order", indexType: 0 },
        { value: "1", label: "下单时间正序", field: "t_order", indexType: 1 },
        { value: "2", label: "抢单时间倒序", field: "t_order_grabbing", indexType: 0 },
        { value: "3", label: "抢单时间正序", field: "t_order_grabbing", indexType: 1 },
        { value: "4", label: "商家实收倒序", field: "t_business_receipt", indexType: 0 },
        { value: "5", label: "商家实收正序", field: "t_business_receipt", indexType: 1 },
        // { value: "6", label: "发货时间倒序", field: "t_send", indexType: 0 },
      ],
      sortingValue: "0",
      orderData: [],
      //弹窗
      countOrderMap: orderConstant.orderCountMap,
    };
  },
  watch: {
    search(val) {
      this.queryParams.search = val;
    },
  },
  created() {
    this.queryStore();
    this.getDeliverySettings();
    this.refreshPage();
  },
  methods: {
    refreshPage() {
      this.orderId = "";
      this.resetParams();
      this.countOrder();
      this.orderList();
    },
    selectOrderId(id) {
      this.orderId = id;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    /**
     * 修改配送设置信息
     */
    async handleAutoSend() {
      let params = {
        sid: this.deliverySetId,
        btimelyAutoSend: this.autoSend,
      };
      let url = `${this.baseUrls.deliverySettings}/updateDelSet`;
      this.topsAjax.post(url, params).then((res) => {
        if (res === 1) {
          this.$message.success("操作成功！");
        } else {
          this.$message.error("操作失败！");
        }
      });
    },
    /**
     * 获取配送设置信息
     */
    async getDeliverySettings() {
      let url = `${this.baseUrls.deliverySettings}/remoteQueryList`;
      this.topsAjax.get(url, {}).then((res) => {
        this.autoSend = res[0].btimelyAutoSend;
        this.deliverySetId = res[0].sid;
      });
    },
    /**
     * 获取门店列表
     */
    async queryStore() {
      this.storeOptions = [{ sid: null, cname: "全部" }];
      this.shopValue = this.storeOptions[0].sid;
      let url = `${this.baseUrls.shop}/queryList`;
      let params = {};
      this.topsAjax.get(url, params).then((res) => {
        this.storeOptions.push(...res);
      });
    },
    /**
     * 获取订单列表
     */
    async orderList() {
      this.orderData = [];
      let url = `${this.baseUrls.third}/queryList`;
      let params = this.queryParams;
      this.topsAjax.get(url, params).then((res) => {
        this.orderData = res;
        if (this.orderData.length > 0) {
          this.orderId = this.orderData[0].sid;
        }
      });
    },
    /**
     * 统计订单数
     */
    async countOrder() {
      let url = `${this.baseUrls.third}/countOrder`;
      let params = {};
      this.topsAjax.get(url, params).then((res) => {
        let map = lodash.keyBy(lodash.cloneDeep(OrderConstant.orderCountMap), "z_flow");
        res.forEach((p) => {
          map[p.z_flow].n_count = p.n_count;
        });
        this.countOrderMap = [];
        this.countOrderMap = map;
      });
    },
    resetParams() {
      this.queryParams = {
        search: "",
        field: "t_create",
        indexType: 0,
        zFlow: this.currentFlow,
        zPlatOrder: null,
        rStore: null,
      };
    },
    handleTabClick(tab) {
      this.orderId = "";
      this.queryParams.zFlow = tab.name;
      this.currentFlow = tab.name;
      this.orderList();
    },
    handleSelectDelivery(val) {
      this.queryParams.zPlatOrder = val === "-1" ? null : val;
      this.orderList();
    },
    handleSelectSort(val) {
      let o = this.sortingOptions[val];
      this.queryParams.field = o.field;
      this.queryParams.indexType = o.indexType;
      this.orderList();
    },
    handleSelectStore() {
      this.queryParams.rShop = this.shopValue;
      this.orderList();
    },
  },
};
</script>

<style lang="scss" scoped>
.orderProcessCol {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__top {
    padding: 3px;
    height: 100px;
    display: flex;
    margin-bottom: 10px;

    .left {
      height: 100%;
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      margin-right: 10px;
    }

    .right {
      height: 100%;
      min-width: 30%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }
  }

  &__body {
    width: 100%;
    flex: 1;
    overflow: hidden;

    .body {
      padding: 3px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;

      &__left {
        //overflow: auto;
        flex: 1;
        margin-right: 10px;
      }

      &__right {
        width: 30%;
        height: 100%;

        & > div {
          height: 100%;
          flex: 1;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.orderProcessCol {
  .el-tabs__item.is-active {
    text-decoration: underline;
  }

  .el-tabs__item {
    padding: 0 10px;
  }

  .el-input__inner {
    border: 0;
  }
}
</style>
