<template>
  <!-- 拣货单详情-->
  <div class="PickingInfo">
    <div class="PickingInfo__top">
      <el-descriptions :column="3">
        <el-descriptions-item label="系统单号">
          {{ pickingInfo.rsys }}
        </el-descriptions-item>
        <el-descriptions-item label="检索单号">
          {{ pickingInfo.sretrieval }}
        </el-descriptions-item>
        <el-descriptions-item label="订单号">
          {{ pickingInfo.rorder }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="2">
        <el-descriptions-item label="出货门店">
          {{ pickingInfo.sstore }}
        </el-descriptions-item>
        <el-descriptions-item label="门店地址">
          {{ pickingInfo.sstoreAddr }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions :column="1">
        <el-descriptions-item label="顾客地址">
          {{ pickingInfo.ccustomerAddr }}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div class="flex">
        <el-descriptions :column="2">
          <el-descriptions-item label="单据状态">
            {{ pickingInfo.zstate | orderPickingState }}
          </el-descriptions-item>
          <el-descriptions-item label="单据备注">
            {{ pickingInfo.cremark }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="w200 flex" style="justify-content: flex-end">
          <el-button type="primary" size="mini" plain @click="deviceDialogVisible = true">门店智控</el-button>
        </div>
      </div>
      <el-divider></el-divider>
      <el-descriptions :column="4">
        <el-descriptions-item label="制单人">
          {{ pickingInfo.screate }}
        </el-descriptions-item>
        <el-descriptions-item label="制单时间">
          {{ pickingInfo.tcreate | time }}
        </el-descriptions-item>
        <el-descriptions-item label="审核人">
          {{ pickingInfo.saudit }}
        </el-descriptions-item>
        <el-descriptions-item label="审核时间">
          {{ pickingInfo.taudit | time }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="h450">
      <el-table ref="multipleTable" :data="tableData"  @selection-change="handleSelectionChange" height="450" :row-class-name="formatRow">
        <el-table-column type="selection" align="center" width="50" :selectable="checkSelectable"/>
        <el-table-column label="序号" align="center" width="50" type="index" />
        <el-table-column label="SKU" align="center" width="70" prop="c_barcode" />
        <el-table-column label="图片" align="center" width="70">
          <template #default="scope">
            <el-image v-if="scope.row.c_master_url != null" :preview-src-list="[scope.row.c_master_url]" class="w50 h50" :src="scope.row.c_master_url"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="货品编码" align="center" width="130" prop="c_sku" />
        <el-table-column label="货品名称" align="center" prop="c_name" />
        <el-table-column label="单位" align="center" width="70" prop="s_unit" />
        <el-table-column label="单价" align="center" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.n_price">￥ {{ scope.row.n_price.toFixed(2) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="库位" align="center" width="70" prop="c_num" />
        <el-table-column label="可用库存" align="center" width="70" prop="n_old_surplus" />
        <el-table-column label="数量" align="center" width="70">
          <template #default="scope">
            <span v-if="scope.row.num > 1" style="color: red">{{ scope.row.num }}</span>
            <span v-else>{{ scope.row.num }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex alignItemsCenter marginTop10" style="justify-content: space-between; flex: 1">
      <div>
        共计 <span class="blue">{{ tableData.length }}种</span> 货品
      </div>
      <div>
        <el-button v-if="pickingInfo.zstate === 2" type="danger" plain @click="handleCancel">取消</el-button>
        <el-button v-if="pickingInfo.zstate === 2" type="success" plain @click="update">改单</el-button>
        <el-button v-if="pickingInfo.zstate === 2" type="primary" @click="shipment">出货</el-button>
        <el-button v-if="pickingInfo.zstate === 3 || pickingInfo.zstate === 5" type="danger" @click="checkDestroy">核销</el-button>
        <el-button v-if="pickingInfo.zstate !== 2 " @click="cancel">取消</el-button>
        
      </div>
    </div>
    <el-dialog v-if="deviceDialogVisible" class="elDialog" :close-on-click-modal="false" title="查看门店智控" :visible.sync="deviceDialogVisible" width="360px" append-to-body>
      <div class="hHP flex directionColumn marginLeft10 marginRight10">
        <div class="padding10 border borderRadius10 marginBottom10">
          <div class="fs13">{{ pickingInfo.sstore }}</div>
          <a class="purple fs13">{{ pickingInfo.sstoreAddr }}</a>
        </div>
        <div class="padding10 border borderRadius10 marginBottom10 hHP">
          <div class="borderBottom" style="padding-bottom: 5px; margin-bottom: 5px">
            <span class="fs20">门店监控</span>
          </div>
          <div class="flex directionColumn">
            <div v-for="(item, index) in videoList" :key="index" class="flex alignItemsCenter directionColumn">
              <a class="el-icon-video-play fs150 ft-color" @click="openVideo(item)" />
              <p>{{ item.cname }}</p>
            </div>
          </div>
        </div>
        <div class="padding10 border borderRadius10">
          <div class="borderBottom" style="padding-bottom: 5px; margin-bottom: 5px">
            <span class="fs20"> 门店智控</span>
          </div>
          <el-row class="padding10">
            <el-col :span="12">
              <el-switch v-model="AccessControl" inactive-text="远程门禁" @change="lampOrDoorSwitch(0)" />
              <el-switch v-model="lighting" inactive-text="远程灯光" @change="lampOrDoorSwitch(1)" />
            </el-col>
            <el-col :span="12">
              <el-button type="primary" class="h60 w150" @click="activateInStore">到店模式</el-button>
            </el-col>
          </el-row>
          <a href="#" class="adding10" style="color: #b5b4bf">更多控制</a>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="videoDialog" :close-on-click-modal="false" title="监控视频" :visible.sync="videoDialog" width="50%" append-to-body>
      <i-mou-video v-if="videoDialog" :device-id="deviceId" channel-id="0" :pwd="pwd" />
    </el-dialog>
  </div>
</template>
<script>
import { formatTime } from "@/utils/utils";
import { orderPickingState } from "@/utils/order";
import iMouVideo from "@/components/ImouVideo/iMouVideo.vue";

export default {
  name: "PickingInfo",
  components: { iMouVideo },
  filters: {
    orderPickingState(value) {
      return orderPickingState(value);
    },
    time(value) {
      if (value === null || value === "" || value === undefined) {
        return "";
      } else {
        return formatTime(value);
      }
    },
  },
  props: {
    sid: {
      type: [String, Object, Array],
      default: "",
    },
  },
  data() {
    return {
      pickingId: "",
      pickingInfo: {},
      tableData: [],
      // 智控弹窗
      deviceDialogVisible: false,
      videoList: [],
      hostList: [],
      deviceId: "",
      pwd: "",
      videoDialog: false,
      btnLoading: false,
      AccessControl: false,
      lighting: false,
      selectedRows: []
    };
  },
  created() {
    this.pickingId = this.sid;
    this.selectById();
  },
  methods: {
    checkSelectable(row) {
      if (row.b_valid === 0) {
        return false
      }
      return true
    },
    formatRow({row}) {
      console.log(row.b_valid)
      if (row.b_valid === 0) {
        return 'discard-row4'
      }
    },
    handleSelectionChange(selection) {
      this.selectedRows = selection;
    },
    activateInStore() {
      let url = `${this.baseUrls.device}/activateInStore`;
      this.topsAjax.post(url, { sid: this.pickingInfo.rstore, breachMode: true }).then((res) => {
        // this.inStoreMode = "success";
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    lampOrDoorSwitch(type) {
      let params = {
        storeId: this.pickingInfo.rstore,
        type: type,
        control: type === 0 ? this.AccessControl : this.lighting,
      };
      let url = `${this.baseUrls.device}/lampOrDoorSwitch`;
      this.topsAjax.post(url, params).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    storeDevice(id) {
      let url = `${this.baseUrls.store}/getStoreDevice/` + id;
      this.topsAjax.get(url).then((response) => {
        this.btnLoading = false;
        this.videoList = [];
        this.hostList = [];
        let tempHost = [];
        for (let i = 0; i < response.deviceList.length; i++) {
          let o = response.deviceList[i];
          if (o.btype === 4) {
            this.videoList.push(o);
          } else {
            tempHost.push(o);
          }
        }
        tempHost.forEach((d) => {
          d.terminalList.forEach((t) => {
            let o = {
              ccode: d.ccode,
              cname: t.cname,
              cterminalId: t.cterminalId,
              bstatus: t.bstatus,
            };
            this.hostList.push(o);
          });
        });
        //TODO 临时处理 再视频没调好之前不显示数据
        // this.videoList = [];
      });
    },
    openVideo(item) {
      this.deviceId = item.ccode;
      this.pwd = item.cpwd;
      this.videoDialog = true;
    },
    // --------------------------------------------
    selectById() {
      let url = `${this.baseUrls.ordPicking}/selectById`;
      let params = { sid: this.pickingId };
      this.topsAjax.get(url, params).then((res) => {
        this.pickingInfo = res.picking;
        this.tableData = res.pickingDetailList;
        this.storeDevice(this.pickingInfo.rstore);
      });
    },
    //取消
    handleCancel() {
      this.$confirm("是否取消当前记录？")
        .then((_) => {
          this.topsAjax.post(`${this.baseUrls.ordPicking}/cancelById`, { sid: this.pickingId }).then((res) => {
            this.$message({
              message: "取消成功",
              type: "success",
            });
            this.cancel();
          });
        })
        .catch((_) => {});
    },
    /**
     * 出货
     */
    shipment() {
      let url = `${this.baseUrls.ordPicking}/shipment`;
      let params = { sid: this.pickingId };
      this.topsAjax.post(url, params).then((res) => {
        this.$message({
          message: "出货成功",
          type: "success",
        });
        this.cancel();
      });
    },
    /**
     * 改单
     */
    update() {
      let url = `${this.baseUrls.ordPicking}/change`;
      let params = { sid: this.pickingId };
      this.topsAjax.post(url, params).then((res) => {
        this.$emit("handleUpdate");
      });
    },
    /**
     * 取消
     */
    cancel() {
      this.$emit("handleClose");
    },
    checkDestroy() {
      if (this.selectedRows.length == 0) {
        this.$message({
              message: "请勾选需要核销的记录",
              type: "info",
            });
            return
      }
      let url = `${this.baseUrls.ordPicking}/checkDestroy`;
      let parameters = {
        pickingId: this.pickingId,
        goodsList: this.selectedRows
      };
      this.topsAjax.post(url, parameters).then((res) => {
        this.$message({
          message: "核销成功",
          type: "success",
        });
        this.cancel();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
:deep .discard-row4 {
  text-decoration: line-through;
}
.PickingInfo {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__top {
    .el-divider--horizontal {
      margin: 7px 0;
    }

    .el-descriptions--medium:not(.is-bordered) .el-descriptions-item__cell {
      padding-bottom: 5px;
    }

    .el-input--medium .el-input__inner {
      height: 20px;
      line-height: 20px;
    }

    .el-input__inner {
      border: 0px;
    }

    //隐藏图标
    .el-input__icon {
      display: none;
    }
  }
}
</style>
