var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderInfoAD" },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        attrs: { id: "container" },
      }),
      _c(
        "div",
        {
          staticClass:
            "h60 bcGrey wHP flex paddingLeft20 alignItemsCenter marginBottom20",
        },
        [_vm._v("订单类型")]
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "bg-purple bcGrey" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "grey padding10",
                    attrs: {
                      "label-position": "left",
                      rules: _vm.rules,
                      model: _vm.ordMain,
                      "label-width": "auto",
                    },
                  },
                  [
                    _c(
                      "el-tabs",
                      {
                        staticClass: "marginBottom10 marginLeft10",
                        model: {
                          value: _vm.ordMain.zdelivery,
                          callback: function ($$v) {
                            _vm.$set(_vm.ordMain, "zdelivery", $$v)
                          },
                          expression: "ordMain.zdelivery",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "同城配送", name: "1" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "顾客自提", name: "2" },
                        }),
                      ],
                      1
                    ),
                    _vm.ordMain.zdelivery === "1"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "选择城市" } },
                          [
                            _c("el-cascader", {
                              ref: "cascader",
                              attrs: {
                                placeholder: "选择城市",
                                options: _vm.options,
                                props: _vm.defaultProps,
                                clearable: "",
                                filterable: "",
                              },
                              on: { change: _vm.selectCity },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "线上店铺" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "线上店铺",
                            },
                            on: { change: _vm.handleSelectShop },
                            model: {
                              value: _vm.shop,
                              callback: function ($$v) {
                                _vm.shop = $$v
                              },
                              expression: "shop",
                            },
                          },
                          _vm._l(_vm.shopList, function (item) {
                            return _c("el-option", {
                              key: item.sid,
                              attrs: { label: item.cname, value: item.sid },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.ordMain.zdelivery === "1"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: { label: "收货地址", prop: "ccustomerAddr" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  "filter-method": _vm.getReceivingAddress,
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.changeInfo },
                                model: {
                                  value: _vm.ordMain.ccustomerAddr,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ordMain, "ccustomerAddr", $$v)
                                  },
                                  expression: "ordMain.ccustomerAddr",
                                },
                              },
                              _vm._l(_vm.infoOptions, function (item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.id,
                                    attrs: { label: item.name, value: item },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex directionColumn" },
                                      [
                                        _c("div", [_vm._v(_vm._s(item.name))]),
                                        _c("div", { staticClass: "grey" }, [
                                          _vm._v(_vm._s(item.address)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ordMain.zdelivery === "1"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: { label: "详细地址", prop: "creceiveAddr" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ordMain.creceiveAddr,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ordMain, "creceiveAddr", $$v)
                                },
                                expression: "ordMain.creceiveAddr",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "收货人", prop: "ccustomerName" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.ordMain.ccustomerName,
                            callback: function ($$v) {
                              _vm.$set(_vm.ordMain, "ccustomerName", $$v)
                            },
                            expression: "ordMain.ccustomerName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号", prop: "ccustomerPhone" } },
                      [
                        _c("el-input", {
                          staticClass: "w150",
                          model: {
                            value: _vm.ordMain.ccustomerPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.ordMain, "ccustomerPhone", $$v)
                            },
                            expression: "ordMain.ccustomerPhone",
                          },
                        }),
                        _vm._v(" - "),
                        _c("el-input", {
                          staticClass: "w100",
                          on: { blur: _vm.onInteger },
                          model: {
                            value: _vm.extensionNumber,
                            callback: function ($$v) {
                              _vm.extensionNumber = $$v
                            },
                            expression: "extensionNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.ordMain.zdelivery === "1"
                      ? _c("el-form-item", { attrs: { label: "送达时间" } }, [
                          _c(
                            "div",
                            { staticClass: "wHP" },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                },
                                on: { change: _vm.getArrivePre },
                                model: {
                                  value: _vm.value1,
                                  callback: function ($$v) {
                                    _vm.value1 = $$v
                                  },
                                  expression: "value1",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "flex marginTop5" },
                                [
                                  _c("el-time-select", {
                                    staticClass: "marginRight10",
                                    staticStyle: { width: "120px" },
                                    attrs: {
                                      placeholder: "起始时间",
                                      "picker-options": {
                                        start: "00:00",
                                        step: "00:30",
                                        end: "24:00",
                                      },
                                    },
                                    on: { change: _vm.getArrivePre },
                                    model: {
                                      value: _vm.startTime,
                                      callback: function ($$v) {
                                        _vm.startTime = $$v
                                      },
                                      expression: "startTime",
                                    },
                                  }),
                                  _vm._v(" - "),
                                  _c("el-time-select", {
                                    staticClass: "marginLeft10",
                                    staticStyle: { width: "120px" },
                                    attrs: {
                                      placeholder: "结束时间",
                                      "picker-options": {
                                        start: "00:00",
                                        step: "00:30",
                                        end: "24:00",
                                        minTime: _vm.startTime,
                                      },
                                    },
                                    on: { change: _vm.getArrivePre },
                                    model: {
                                      value: _vm.endTime,
                                      callback: function ($$v) {
                                        _vm.endTime = $$v
                                      },
                                      expression: "endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.ordMain.zdelivery === "2"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "自提时间", prop: "tpick" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "datetime",
                                placeholder: "请选择自提时间",
                              },
                              model: {
                                value: _vm.ordMain.tpick,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ordMain, "tpick", $$v)
                                },
                                expression: "ordMain.tpick",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ordMain.zdelivery === "1"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "配送门店" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择配送门店" },
                                on: { change: _vm.handleSelectStore },
                                model: {
                                  value: _vm.store,
                                  callback: function ($$v) {
                                    _vm.store = $$v
                                  },
                                  expression: "store",
                                },
                              },
                              _vm._l(_vm.storeList, function (item) {
                                return _c("el-option", {
                                  key: item.sid,
                                  attrs: { label: item.cname, value: item.sid },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ordMain.zdelivery === "2"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "自提门店" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择配送门店" },
                                on: { change: _vm.handleSelectStore },
                                model: {
                                  value: _vm.store,
                                  callback: function ($$v) {
                                    _vm.store = $$v
                                  },
                                  expression: "store",
                                },
                              },
                              _vm._l(_vm.storeList, function (item) {
                                return _c("el-option", {
                                  key: item.sid,
                                  attrs: { label: item.cname, value: item.sid },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "支付方式", prop: "pay" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "支付方式" },
                            on: { change: _vm.handleSelectPay },
                            model: {
                              value: _vm.pay,
                              callback: function ($$v) {
                                _vm.pay = $$v
                              },
                              expression: "pay",
                            },
                          },
                          _vm._l(_vm.payOption, function (item) {
                            return _c("el-option", {
                              key: item.z_pay,
                              attrs: { label: item.s_pay, value: item.z_pay },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "bg-purple-light bcGrey hHP" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form-goods",
                    staticClass: "grey padding10",
                    attrs: { model: _vm.ordMain, "label-width": "auto" },
                  },
                  [
                    _c("el-form-item", { attrs: { label: "货品信息" } }, [
                      _c("div", { staticStyle: { float: "right" } }, [
                        _vm._v("合计: " + _vm._s(_vm.totalAmount)),
                      ]),
                    ]),
                    _vm.goodsList.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "bcGrey padding10 marginBottom10 scrollbar",
                            staticStyle: { height: "290px" },
                          },
                          _vm._l(_vm.goodsList, function (goods, index) {
                            return _c(
                              "div",
                              { key: goods.sid, staticClass: "marginBottom10" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "wHP bcWhite flex padding5" },
                                  [
                                    _c("el-image", {
                                      staticClass: "w60 h60 marginRight5",
                                      attrs: {
                                        "preview-src-list": [
                                          goods.c_master_url,
                                        ],
                                        fit: "fit",
                                        src: goods.c_master_url,
                                      },
                                    }),
                                    _c("div", { staticClass: "flex1" }, [
                                      _c("div", { staticClass: "fs20 blue" }, [
                                        _vm._v(_vm._s(goods.c_name)),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex marginTopAuto alignItemsCenter",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "35%" } },
                                            [
                                              _vm._v(
                                                "单价: " + _vm._s(goods.n_price)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flexGrow" },
                                            [
                                              _vm._v(
                                                "数量: " + _vm._s(goods.num)
                                              ),
                                            ]
                                          ),
                                          _c("el-button", {
                                            staticStyle: {
                                              "margin-left": "auto",
                                            },
                                            attrs: {
                                              type: "primary",
                                              icon: "el-icon-delete",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteGoods(index)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticClass: "wHP block",
                        attrs: { type: "primary", plain: "" },
                        on: { click: _vm.addGoodsShow },
                      },
                      [_vm._v("+ 添加货品")]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "marginTop10",
                        attrs: { label: "用户备注" },
                      },
                      [
                        _c("el-input", {
                          attrs: { autosize: "", type: "textarea" },
                          model: {
                            value: _vm.ordMain.corderRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.ordMain, "corderRemark", $$v)
                            },
                            expression: "ordMain.corderRemark",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "marginTop10",
                        attrs: { label: "内部备注" },
                      },
                      [
                        _c("el-input", {
                          attrs: { autosize: "", type: "textarea" },
                          model: {
                            value: _vm.ordMain.cremark,
                            callback: function ($$v) {
                              _vm.$set(_vm.ordMain, "cremark", $$v)
                            },
                            expression: "ordMain.cremark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "marginTop20 paddingLeft20 bcGrey h60 wHP marginBottom20 flex alignItemsCenter",
        },
        [
          _vm._v(" 配送通道 "),
          _c(
            "div",
            { staticClass: "marginLeft20" },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.deliveryChannel,
                    callback: function ($$v) {
                      _vm.deliveryChannel = $$v
                    },
                    expression: "deliveryChannel",
                  },
                },
                [_vm._v("松鼠屋")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.deliveryChannel,
                    callback: function ($$v) {
                      _vm.deliveryChannel = $$v
                    },
                    expression: "deliveryChannel",
                  },
                },
                [_vm._v("快服务")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ordMain.zdelivery === "1",
                  expression: "ordMain.zdelivery === '1'",
                },
              ],
              attrs: { type: "primary", plain: "" },
              on: {
                click: function ($event) {
                  return _vm.saveAndDelivery(6)
                },
              },
            },
            [_vm._v("保存并发单")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveAndDelivery(0)
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "elDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "添加商品",
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("OrderGoodsAdd", {
                ref: "OrderGoodsAdd",
                attrs: {
                  "select-store": _vm.ordMain,
                  "max-num": true,
                  "order-goods": _vm.goodsList,
                },
                on: { OrderGoodsAdd: _vm.orderGoodsAdd },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }